import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { SummaryTrendRecordType } from '@lib/web/apis';
import { useInView } from '@react-spring/web';
import { format, getWeeksInMonth, isSameMonth } from 'date-fns';

import StreakData from './StreakData';

const styles = {
  root: {
    display: 'grid',
    gap: 2,
  },
  title: {
    fontSize: 20, // cannot be changed to use design system because design system uses RWD size
    fontWeight: 'bold',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    columnGap: 0.5,
    rowGap: 2,
  },
};

export type CalendarProps = {
  date: Date;
  type: 'streak' | 'longestStreak' | 'selectedStreak';
  selectedDate?: Date;
  onDateClick?: (date: Date) => void;
  subType?: SummaryTrendRecordType;
};
export default function Calendar({ date, type, selectedDate }: CalendarProps) {
  const [ref, onScreen] = useInView(); // reduce dom rendering

  const weeks = getWeeksInMonth(date, { weekStartsOn: 1 });
  const contentHeight = weeks * 48 + (weeks - 1) * 16;
  const sameMonth = selectedDate && isSameMonth(selectedDate, date);

  return (
    <Box ref={ref} sx={styles.root}>
      <Typography sx={styles.title}>{format(date, 'LLLL yyyy')}</Typography>

      <Box
        sx={[styles.content, { height: contentHeight }]}
        className={sameMonth ? 'rhs-cal-selected-month' : undefined}
      >
        {type === 'streak' && onScreen && (
          <StreakData date={date} selectedDate={new Date()} />
        )}
        {type === 'longestStreak' && onScreen && (
          <StreakData
            date={date}
            selectedDate={selectedDate}
            selectedStyle="todayAndSelected"
          />
        )}
        {type === 'selectedStreak' && onScreen && (
          <StreakData
            date={date}
            selectedDate={selectedDate}
            selectedStyle="onlySelected"
          />
        )}
      </Box>
    </Box>
  );
}
