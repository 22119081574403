import { ThreadFilterMap, ThreadSortMap } from '../types/threadFilterSort';

export const CHANNEL_PANEL_SORT_FILTER_SCOPE = 'thread-channel-panel'; // thread channel panel
export const MESSAGE_SORT_FILTER_SCOPE = 'thread-message'; // thread message

// Thread Channel
const CHANNEL_CONFIG_MAP = {
  creator: 'created_by_id',
  createdTime: 'created_at',
  visibility: 'type',
  lastActivityTime: 'last_updated',
  memberCount: 'member_count',
  members: 'members',
  customActiveMembers: 'customActiveMembers',
  customReplyCount: 'customReplyCount',
};
export const THREAD_CHANNEL_SORT_MAP: ThreadSortMap = {
  creator: { queryName: CHANNEL_CONFIG_MAP.creator },
  createdTime: { queryName: CHANNEL_CONFIG_MAP.createdTime },
  visibility: { queryName: CHANNEL_CONFIG_MAP.visibility },
  lastActivityTime: { queryName: CHANNEL_CONFIG_MAP.lastActivityTime },
  memberCount: { queryName: CHANNEL_CONFIG_MAP.memberCount },
  customReplyCount: { queryName: CHANNEL_CONFIG_MAP.customReplyCount },
};
export const THREAD_CHANNEL_FILTER_MAP: ThreadFilterMap = {
  creator: {
    queryName: CHANNEL_CONFIG_MAP.creator,
    operators: { eq: '$eq' },
    type: 'string',
  },
  createdTime: {
    queryName: CHANNEL_CONFIG_MAP.createdTime,
    operators: { gt: '$gt', gte: '$gte', lt: '$lt', lte: '$lte' },
    type: 'date',
  },
  visibility: {
    queryName: CHANNEL_CONFIG_MAP.visibility,
    operators: { in: '$eq' },
    type: 'string',
  },
  members: {
    queryName: CHANNEL_CONFIG_MAP.members,
    operators: { in: '$in' },
    isArray: true,
    type: 'string',
  },
  customActiveMembers: {
    queryName: CHANNEL_CONFIG_MAP.customActiveMembers,
    operators: { in: '$eq' },
    isArray: true,
    type: 'string',
  },
  memberCount: {
    queryName: CHANNEL_CONFIG_MAP.memberCount,
    operators: { eq: '$eq', gt: '$gt', gte: '$gte', lt: '$lt', lte: '$lte' },
    type: 'number',
  },
  customReplyCount: {
    queryName: CHANNEL_CONFIG_MAP.customReplyCount,
    operators: { eq: '$eq' },
    type: 'number',
  },
  lastActivityTime: {
    queryName: CHANNEL_CONFIG_MAP.lastActivityTime,
    operators: { gt: '$gt', gte: '$gte', lt: '$lt', lte: '$lte' },
    type: 'date',
  },
};

// Thread Message
const MESSAGE_CONFIG_MAP = {
  messageText: 'text',
  replyCount: 'reply_count',
  userId: 'user.id',
  createdAt: 'created_at',
  updatedAt: 'updated_at',
};
export const THREAD_MESSAGE_SORT_MAP: ThreadSortMap = {
  messageText: { queryName: MESSAGE_CONFIG_MAP.messageText },
  replyCount: { queryName: MESSAGE_CONFIG_MAP.replyCount },
  userId: { queryName: MESSAGE_CONFIG_MAP.userId },
  createdAt: { queryName: MESSAGE_CONFIG_MAP.createdAt },
  updatedAt: { queryName: MESSAGE_CONFIG_MAP.updatedAt },
};
export const THREAD_MESSAGE_FILTER_MAP: ThreadFilterMap = {
  messageText: {
    queryName: MESSAGE_CONFIG_MAP.messageText,
    operators: { eq: '$q' },
    type: 'string',
  },
  replyCount: {
    queryName: MESSAGE_CONFIG_MAP.replyCount,
    operators: { eq: '$eq', gt: '$gt', gte: '$gte', lt: '$lt', lte: '$lte' },
    type: 'number',
  },
  userId: {
    queryName: MESSAGE_CONFIG_MAP.userId,
    operators: { eq: '$eq' },
    type: 'string',
  },
  createdAt: {
    queryName: MESSAGE_CONFIG_MAP.createdAt,
    operators: { gt: '$gt', gte: '$gte', lt: '$lt', lte: '$lte' },
    type: 'date',
  },
  updatedAt: {
    queryName: MESSAGE_CONFIG_MAP.updatedAt,
    operators: { gt: '$gt', gte: '$gte', lt: '$lt', lte: '$lte' },
    type: 'date',
  },
};
