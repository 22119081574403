import React, { useMemo } from 'react';
import { nonNullable } from '@front/helper';
import { IaRichText } from '@lib/ia/src/core/types';
import ChannelLayout from '@lib/ia/src/layouts/ChannelLayout';
import { useAuth } from '@lib/web/apis';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useMessageInformation } from '@lib/web/thread/hooks/message/useMessageInformation';

import { SystemMessageProps } from './SystemMessage';

export type MemberInvitedMessageProps = SystemMessageProps;

const useInvitedMemberInfos = (invitedMemberIds: string[]) => {
  const { getThreadUser } = useThread();

  return useMemo(
    () =>
      invitedMemberIds
        .map((id) => {
          const user = getThreadUser(id);

          if (user?.type === 'user') {
            return {
              id: user.userId || '',
              name: user.distinctName ? `@${user.distinctName}` : '',
              src: user.image || '',
              action: {
                type: 'event' as const,
                value: 'viewUser',
              },
            };
          }
          if (user?.type === 'agent') {
            return {
              id: user.agentId || '',
              name: user.distinctName ? `@${user.distinctName}` : '',
              src: user.image || '',
              action: {
                type: 'event' as const,
                value: 'viewAgent',
              },
            };
          }
          return null;
        })
        .filter(nonNullable),
    [getThreadUser, invitedMemberIds]
  );
};

const getInvitedMessageContent = (
  invitedMemberInfos: ReturnType<typeof useInvitedMemberInfos>
): IaRichText => {
  if (invitedMemberInfos.length === 1) {
    return [
      {
        type: 'text',
        value: 'OK! I’ve invited',
      },
      {
        type: 'user',
        ...invitedMemberInfos[0],
      },
      {
        type: 'text',
        value: 'to this thread.',
      },
    ];
  }

  if (invitedMemberInfos.length === 2) {
    return [
      {
        type: 'text',
        value: 'OK! I’ve invited',
      },
      {
        type: 'user',
        ...invitedMemberInfos[0],
      },
      {
        type: 'text',
        value: 'and',
      },
      {
        type: 'user',
        ...invitedMemberInfos[1],
      },
      {
        type: 'text',
        value: 'to this thread.',
      },
    ];
  }

  if (invitedMemberInfos.length > 2) {
    return [
      {
        type: 'text',
        value: 'OK! I’ve invited',
      },
      {
        type: 'user',
        ...invitedMemberInfos[0],
      },
      {
        type: 'text',
        value: 'and',
      },
      {
        type: 'highlight',
        text: `+${invitedMemberInfos.length - 1} others`,
        tooltip: invitedMemberInfos
          .slice(1)
          .map((info) => info.name)
          .join(', '),
      },
      {
        type: 'text',
        value: 'to this thread.',
      },
    ];
  }

  return '';
};
export default function MemberInvitedMessage({
  message,
}: MemberInvitedMessageProps) {
  const { member } = useAuth();
  const { getMessageInformation } = useMessageInformation();

  const invitedMemberIds: string[] = Array.isArray(
    message?.customInvitedMemberIds
  )
    ? message.customInvitedMemberIds
    : [];
  const invitedMemberInfos = useInvitedMemberInfos(invitedMemberIds);
  const content = useMemo(
    () => getInvitedMessageContent(invitedMemberInfos),
    [invitedMemberInfos]
  );

  const myMemberId = member?.memberId;
  const isSender = !!myMemberId && myMemberId === message.customUserId;

  if (!isSender) return null;

  const messageInformation = getMessageInformation(message);

  return (
    <ChannelLayout.MessageItem
      type="message"
      id={message.id}
      sender={{
        type: 'icon',
        name: message.user?.name ?? '',
        icon: 'CryptoCurrencyAha',
        bgColor: '#080808',
      }}
      content={content}
      contentVariant="chatBody"
      sentTime={messageInformation.messageCreatedAt}
      lastActivityTime={messageInformation.messageCreatedAt}
      helperText="This message is only visible to you"
    />
  );
}
