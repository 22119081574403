import React from 'react';
import { Theme } from '@mui/material';
import { isDate } from '@front/helper';
import ChannelLayout from '@lib/ia/src/layouts/ChannelLayout';
import { useAuth } from '@lib/web/apis';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { getTrackElementAttributes } from '@lib/web/utils';
import { StreamMessage } from 'stream-chat-react';

const styles = {
  button: {
    background:
      'linear-gradient(92.61deg, #00D1FF 0%, #0051F9 48.44%, #7000FF 100%)',
    borderRadius: 1,
    border: 'none',
    color: (theme: Theme) => theme.palette.text.primary,
  },
};

type UpgradeCtaMessageProps = {
  message: StreamMessage<StreamChatGenerics>;
};

export default function UpgradeCtaMessage({ message }: UpgradeCtaMessageProps) {
  const { member } = useAuth();
  const { customUserId: messageUserId, customCtaStatus: status } = message;

  if (
    !messageUserId ||
    !member?.memberId ||
    messageUserId !== member.memberId
  ) {
    return null;
  }

  const senderName = message.user?.name || 'Aha Bot';

  return (
    <ChannelLayout.NotificationItem
      type="notification"
      id={message.id}
      name={senderName}
      icon={{
        name: 'CryptoCurrencyAha',
        bgColor: '#080808',
      }}
      content={message.text || ''}
      sentTime={
        isDate(message.created_at)
          ? message.created_at.toISOString()
          : message.created_at ?? ''
      }
      cta={
        status === 'pending'
          ? [
              {
                type: 'button',
                label: 'Upgrade',
                action: {
                  type: 'link',
                  value: '/extend-trial',
                },
                buttonSx: styles.button,
                HTMLAttributes: getTrackElementAttributes({
                  elementName: 'upgrade-button',
                  fromLocation: 'run-out-of-token',
                }),
              },
            ]
          : []
      }
      helperText="This message is only visible to you"
    />
  );
}
