import { MouseEvent } from 'react';
import {
  alpha,
  Box,
  ButtonBase,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ChallengeButton,
  FollowButton,
} from '@app/web/src/components/FriendButtons';
import useCurrentUserId from '@app/web/src/hooks/utils/useCurrentUserId';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { LeagueBadge, useBaseRightPanel, UserAvatar } from '@front/ui';
import { LeagueTier, LeagueZone } from '@lib/web/apis';
import { getIndicators } from '@lib/web/utils';

import useRankingPlacement from '../hooks/useRankingPlacement';
import { getLeagueName } from '../utils';

const getRank = (rank?: number) => {
  if (rank === undefined) return '-';
  if (rank > 999) return '+999';
  return rank;
};
const getScore = (score?: number) => {
  if (score === undefined) return '-';
  return score.toFixed(0);
};

const getZoneStyle = (zone?: LeagueZone) => {
  switch (zone) {
    case LeagueZone.Promotion:
      return { borderColor: 'success.light' };
    case LeagueZone.Stay:
      return { borderColor: 'text.primary' };
    case LeagueZone.Demotion:
      return { borderColor: 'error.dark' };
    default:
      return { borderColor: 'alpha.lightA64' };
  }
};

const getBgcolor = (zone?: LeagueZone) => {
  switch (zone) {
    case LeagueZone.Promotion:
      return {
        bgcolor: (theme: Theme) => alpha(theme.palette.success.dark, 0.3),
        '@media (hover:hover)': {
          '&:hover': {
            bgcolor: (theme: Theme) => alpha(theme.palette.success.dark, 0.5),
          },
        },
      };
    case LeagueZone.Demotion:
      return {
        bgcolor: (theme: Theme) => alpha(theme.palette.error.dark, 0.3),
        '@media (hover:hover)': {
          '&:hover': {
            bgcolor: (theme: Theme) => alpha(theme.palette.error.dark, 0.5),
          },
        },
      };
    default:
      return {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
        '@media (hover:hover)': {
          '&:hover': {
            bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
          },
        },
      };
  }
};

const getRankStyle = (rank?: number) => {
  if (rank !== undefined && rank < 1000)
    return {
      fontSize: 20,
    };

  return {
    fontSize: 16,
  };
};

const getGridTemplateColumns = ({
  hideLeague = false,
  hideStreak = false,
  hideCta = false,
  ctaShowIconOnly = false,
}: {
  hideLeague?: boolean;
  hideStreak?: boolean;
  hideCta?: boolean;
  ctaShowIconOnly?: boolean;
}) => {
  const leagueCol = !hideLeague ? '24px' : '';
  const streakCol = !hideStreak ? '42px' : '';
  const ctaCol = !hideCta ? (ctaShowIconOnly ? '36px' : '163px') : '';
  return `1fr ${leagueCol} 32px 42px ${streakCol} ${ctaCol}`;
};

const styles = {
  root: {
    display: 'grid',
    gap: 1,
    pl: {
      xs: '16px',
      md: 1,
    },
    pr: {
      xs: '20px',
      md: 1.5,
    },
    height: 61,
    width: '100%',
    textAlign: 'left',
    borderLeft: '4px solid',
    '@media (hover:hover)': {
      '&:hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
  },

  avatar: {
    '& .MuiTypography-h6': {
      fontSize: 14,
      fontWeight: 500,
    },
    '& .MuiTypography-caption': {
      fontSize: 14,
      lineHeight: 1.5,
    },
  },

  me: {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 1,
    p: 1,
    ml: 1,
    height: 26,
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },

  rank: {
    typography: 'body1',
    fontWeight: 700,
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    pl: 1,
  },
  rankHashtag: {
    fontSize: 12,
    opacity: 0.5,
  },
  score: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontWeight: 400,
    fontSize: 20,
    opacity: 0.64,
    pl: 1,
  },
  streak: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontWeight: 400,
    fontSize: 20,
    opacity: 0.64,
    pl: 1,
  },
  hidden: {
    display: 'none',
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    pl: 1,
  },

  iconCtaButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

type CtaProps = {
  userId: string;
  isFollowing?: boolean;
  isFollower?: boolean;
  isMe?: boolean;
  showIconOnly?: boolean;
};

function Cta({
  userId,
  isFollowing,
  isFollower,
  isMe,
  showIconOnly = false,
}: CtaProps) {
  const handleChallengeMouseDown = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const handleChallengeClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Box sx={styles.action}>
      {!isMe && (
        <>
          {isFollowing && (
            <ChallengeButton
              onMouseDown={handleChallengeMouseDown}
              onClick={handleChallengeClick}
              userId={userId}
              showIconOnly={showIconOnly}
              customSize={32}
              sx={[showIconOnly && styles.iconCtaButton]}
            />
          )}
          {!isFollowing && (
            <FollowButton
              onMouseDown={handleChallengeMouseDown}
              onClick={handleChallengeClick}
              userId={userId}
              isFollowing={isFollowing}
              isFollower={isFollower}
              showIconOnly={showIconOnly}
              sx={[showIconOnly && styles.iconCtaButton]}
              customSize={32}
            />
          )}
        </>
      )}
    </Box>
  );
}

export type RankingItemProps = {
  data: {
    leagueTier?: LeagueTier;
    rank?: number;
    score?: number;
    longestStreaks?: number;
    zone?: LeagueZone;
  };
  userInfo: {
    userId: string;
    displayName: string;
    distinctName: string;
    avatarUrl: string;
    indicator?: BaseMemberInfo['indicator'];
    isFollowing?: boolean;
    isFollower: boolean;
  };
  settings?: {
    hideStreak?: boolean;
    hideLeague?: boolean;
  };
};

export default function RankingItem({
  data,
  userInfo,
  settings,
}: RankingItemProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const placement = useRankingPlacement();
  const ctaShowIconOnly = !mdUp;
  const hideCta = placement === 'rhs';

  const currentUserId = useCurrentUserId();
  const isMe = currentUserId === userInfo.userId;
  const gridTemplateColumns = getGridTemplateColumns({
    ...settings,
    ctaShowIconOnly,
    hideCta,
  });
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();

  const handleClick = () => {
    openRightPanel(GlobalPanelKeys.GlobalProfile, {
      userId: userInfo.userId,
    });
  };

  return (
    <ButtonBase
      sx={[
        styles.root,
        { gridTemplateColumns },
        getZoneStyle(data.zone),
        isMe && getBgcolor(data.zone),
      ]}
      onClick={handleClick}
    >
      <UserAvatar
        sx={styles.avatar}
        src={userInfo.avatarUrl}
        title={userInfo.displayName || userInfo.distinctName}
        titleSuffix={
          isMe && (
            <Typography sx={styles.me} variant="body1">
              Me
            </Typography>
          )
        }
        subTitle={`@${userInfo.distinctName}`}
        size="md"
        indicators={getIndicators(userInfo.indicator)}
      />
      {!settings?.hideLeague && (
        <Box>
          <LeagueBadge
            name={data.leagueTier ? getLeagueName(data.leagueTier) : 'Locked'}
            size={24}
          />
        </Box>
      )}
      <Box sx={[styles.rank, getRankStyle(data.rank)]}>
        <Typography sx={styles.rankHashtag}>#</Typography>
        {getRank(data.rank)}
      </Box>
      <Typography variant="h6" sx={styles.score}>
        {getScore(data.score)}
      </Typography>
      {!settings?.hideStreak && (
        <Typography variant="h6" sx={styles.streak}>
          {data.longestStreaks}
        </Typography>
      )}
      {!hideCta && (
        <Cta
          userId={userInfo.userId}
          isFollower={userInfo.isFollower}
          isFollowing={userInfo.isFollowing}
          isMe={isMe}
          showIconOnly={ctaShowIconOnly}
        />
      )}
    </ButtonBase>
  );
}
