import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthGuard from '@app/web/src/components/AuthGuard';
import {
  OtherAddFriend as OtherAddFriendIcon,
  OtherFollowing as OtherFollowingIcon,
} from '@front/icon';
import { Button } from '@front/ui';
import { apis, useUserProfileSocial } from '@lib/web/apis';
import { call } from '@lib/web/utils';

type FriendFollowButtonProps = {
  userId: string;
};
export default function FriendFollowButton({
  userId,
}: FriendFollowButtonProps) {
  const { t } = useTranslation('notification');
  const [loading, setLoading] = useState(false);

  const { data, mutate: socialMutate } = useUserProfileSocial(userId);

  const prefixIcon = data?.data.isFollowing ? (
    <OtherFollowingIcon />
  ) : (
    <OtherAddFriendIcon />
  );

  if (!data) return <Button prefixIcon={prefixIcon} loading />;

  const buttonColor = data.data.isFollowing ? 'success' : 'default';

  const toggleFollow = async () => {
    setLoading(true);
    const api = data.data.isFollowing
      ? () => apis.member.unfollowUser(userId)
      : () => apis.member.followUser(userId);

    await call(api);
    await socialMutate();
    setLoading(false);
  };

  return (
    <AuthGuard>
      {({ disabled }) => (
        <>
          {data.data.isFollower && (
            <Button
              prefixIcon={prefixIcon}
              color={buttonColor}
              loading={loading}
              onClick={toggleFollow}
              variant="filled"
              disabled={disabled}
            >
              {data.data.isFollowing
                ? t('button.Following')
                : t('button.Follow Back')}
            </Button>
          )}
          {!data.data.isFollower && (
            <Button
              prefixIcon={prefixIcon}
              color={buttonColor}
              loading={loading}
              onClick={toggleFollow}
              disabled={disabled}
            >
              {data.data.isFollowing
                ? t('button.Following')
                : t('button.Follow')}
            </Button>
          )}
        </>
      )}
    </AuthGuard>
  );
}
