import React, { useCallback, useContext, useState } from 'react';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { BaseLayoutRightPanel, useBaseRightPanel } from '@front/ui';
import { ThreadViewType, useAuth } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import { ThreadCurrentLocationContext } from '@lib/web/thread';
import {
  ThreadComposerContextValue,
  ThreadComposerProvider,
} from '@lib/web/thread/contexts/threadComposerContext';
import { useCreateNewChannelWithMessage } from '@lib/web/thread/hooks/channel/useCreateNewChannelWithMessage';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useMaybeRedirectUserToNewDmView } from '@lib/web/thread/hooks/view/useMaybeRedirectUserToNewDmView';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import ThreadComposer from '@lib/web/thread/ThreadComposer';
import { ThreadViewProperties } from '@lib/web/thread/ThreadProperties';

const styles = {
  panel: {
    '& .bottom-action': {
      p: 0,
    },
  },
};

export default function ThreadCreatorFromViewPanel() {
  const { resetThreadChannelSortFilter } = useThread();
  const {
    getRightParams,
    openRightPanel,
    rightPanelHistory,
    rightPanelOpened,
  } = useBaseRightPanel<GlobalPanelParams>();
  const { view } = getRightParams(
    GlobalPanelKeys.GlobalThreadCreatorFromViewPanel
  );
  const { channelData, viewMemberIds } = useThreadViewDetail(view);
  const [text, setText] = useState('');
  const { createNewChannelWithMessage } = useCreateNewChannelWithMessage();
  const { maybeRedirectUserToNewDmView } = useMaybeRedirectUserToNewDmView();

  const { isBlocking, blockerTip } = useAuth();
  const { defaultSendPublicly } = useContext(ThreadCurrentLocationContext);
  const handleSubmit = useCallback<
    (props: {
      contextValue: ThreadComposerContextValue;
      blocks: ComposerBlock[];
    }) => Promise<void>
  >(
    async ({ contextValue: { sendPublicly, aiActionState }, blocks }) => {
      setText('');

      const newChannelResult = await createNewChannelWithMessage({
        message: text,
        blocks,
        sendPublicly,
        channelData,
        agentId: aiActionState.selectedAgentId,
      });

      if (!newChannelResult) return;

      resetThreadChannelSortFilter.current();

      rightPanelHistory.back();
      openRightPanel(GlobalPanelKeys.GlobalThreadChat, {
        channelCid: newChannelResult.newChannel.cid,
      });

      if (newChannelResult.viewAfterId) {
        await maybeRedirectUserToNewDmView({
          channel: newChannelResult.newChannel,
          newViewId: newChannelResult.viewAfterId,
        });
      }
    },
    [
      channelData,
      createNewChannelWithMessage,
      maybeRedirectUserToNewDmView,
      openRightPanel,
      resetThreadChannelSortFilter,
      rightPanelHistory,
      text,
    ]
  );

  return (
    <ThreadComposerProvider
      text={text}
      setText={setText}
      sendPubliclyCheckboxEnable={
        view.type === ThreadViewType.ClubAllThread ||
        view.type === ThreadViewType.Club ||
        view.type === ThreadViewType.DmEveryone ||
        view.type === ThreadViewType.Location
      }
      sendPubliclyDefault={
        view.type === ThreadViewType.DmEveryone || defaultSendPublicly
      }
      sendPubliclyDisabled={view.type === ThreadViewType.DmEveryone}
      threadMemberIds={viewMemberIds}
    >
      {(contextValue) => (
        <BaseLayoutRightPanel
          resizable
          sx={styles.panel}
          actionComponent={
            rightPanelOpened && (
              <ThreadComposer
                autoFocus
                disabled={isBlocking}
                disabledTip={blockerTip}
                onChange={setText}
                onSubmit={({ blocks }) =>
                  handleSubmit({
                    contextValue,
                    blocks,
                  })
                }
              />
            )
          }
        >
          <ThreadViewProperties view={view} mode="expandableBrief" />
        </BaseLayoutRightPanel>
      )}
    </ThreadComposerProvider>
  );
}
