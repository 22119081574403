import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ThreadSort } from '@app/web/src/types/threadFilterSort';
import { nonNullable } from '@front/helper';
import {
  ChannelLayoutConfig,
  ChannelLayoutLoadingConfig,
  MessageItemConfig,
} from '@lib/ia/src/layouts/ChannelLayout/types';
import { useChannelInformation } from '@lib/web/thread/hooks/channel/useChannelInformation';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useReadState } from '@lib/web/thread/hooks/message/useReadState';
import { streamDateToString } from '@lib/web/thread/utils/streamUtils';
import { Channel } from 'stream-chat';

export const useThreadViewChannelLayoutConfig = ({
  channels,
  isLoadingInitialData,
  sort,
}: {
  channels: Channel[];
  isLoadingInitialData: boolean;
  sort: ThreadSort;
}) => {
  const { t } = useTranslation('thread');
  const { getThreadUser } = useThread();
  const { getChannelInformation } = useChannelInformation();
  const { getChannelReadState } = useReadState();

  return useMemo(() => {
    if (isLoadingInitialData) {
      return [
        {
          layout: 'channel-layout-skeleton',
        } as ChannelLayoutLoadingConfig,
      ];
    }

    let sortedChannels = channels;
    const initStatus = Object.keys(sort).length === 1 && sort.created_at === 1; // Channel Layout init sort condition
    if (initStatus) {
      sortedChannels = channels.sort((a, b) => {
        const aDate = streamDateToString(a.data?.created_at as string);
        const bDate = streamDateToString(b.data?.created_at as string);
        return aDate > bDate ? 1 : -1;
      });
    }

    return [
      {
        layout: 'channel-layout',
        items: sortedChannels.map((channel, index): MessageItemConfig => {
          const {
            channelCreator,
            firstMessageText,
            firstMessageCreatedAt,
            lastMessageCreatedAt,
            replies,
            channelEngagedMemberIds,
            dateDividerTime,
          } = getChannelInformation(channel);
          const previousChannel: Channel = sortedChannels[index - 1];
          const channelReadState = getChannelReadState(channel);
          const previousChannelInformation =
            previousChannel && getChannelInformation(previousChannel);
          const previousChannelReadState =
            previousChannel && getChannelReadState(previousChannel);
          const channelCreatorUser = getThreadUser(channelCreator);

          const messageKey = [
            channel.cid,
            channel.state.last_message_at?.toString(),
            firstMessageText, // message could be dynamically rendered
          ]
            .filter(nonNullable)
            .join('-');

          return {
            id: channel.cid,
            key: messageKey,
            type: 'message',
            sender: {
              type: 'avatar',
              name: channelCreatorUser?.name ?? '',
              avatarUrl: channelCreatorUser?.image || '',
              badge: channel.type === 'team' ? 'private' : undefined,
            },
            content: firstMessageText,
            sentTime: firstMessageCreatedAt,
            lastActivityTime: lastMessageCreatedAt,
            dateDividerTime,
            readState: channelReadState,
            replies,
            showReplyThread: true,
            members: channelEngagedMemberIds.map((memberId) => {
              const threadUser = getThreadUser(memberId);
              return {
                name: threadUser?.name || '',
                avatarUrl: threadUser?.image || '',
              };
            }),
            previousMessageProps: {
              dateDividerTime: previousChannelInformation?.dateDividerTime,
              readState: previousChannelReadState,
            },
            clickItemAction: {
              type: 'event',
              value: 'viewThread',
            },
            clickSenderAction: {
              type: 'event',
              value: 'openUserOrAgentProfile',
              metadata: { user: channelCreatorUser },
            },
            viewThreadAction: {
              type: 'event',
              value: 'viewThread',
            },
            eventMenuClassName: 'right-panel-floating-menu',
            events: [
              {
                type: 'event',
                value: 'openThread',
                text: t('message.menu.replyInThread'),
                icon: 'ActionArrowRightUp',
              },
            ],
          };
        }),
      } as ChannelLayoutConfig,
    ];
  }, [
    channels,
    getChannelInformation,
    getChannelReadState,
    getThreadUser,
    isLoadingInitialData,
    sort,
    t,
  ]);
};
