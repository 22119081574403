import { GlobalPanelKeys } from '@app/web/src/types/panel';
import AgentProfilePanel from '@app/web/src/widgets/CommonPanels/AgentProfilePanel';
import ClubMembersPanel from '@app/web/src/widgets/CommonPanels/ClubMembersPanel';
import EmojiDetailPanel from '@app/web/src/widgets/CommonPanels/EmojiDetailPanel';
import FilterPanel from '@app/web/src/widgets/CommonPanels/FilterPanel';
import FindUserPanel from '@app/web/src/widgets/CommonPanels/FindUserPanel';
import GoalRingPanel from '@app/web/src/widgets/CommonPanels/GoalRingPanel';
import HashtagDetailPanel from '@app/web/src/widgets/CommonPanels/HashtagDetailPanel';
import LayoutSettingPanel from '@app/web/src/widgets/CommonPanels/LayoutSettingPanel';
import ManageAgentsPanel from '@app/web/src/widgets/CommonPanels/ManageAgentsPanel';
import ManageAgentsPanelSearch from '@app/web/src/widgets/CommonPanels/ManageAgentsPanelSearch';
import ProfilePanels from '@app/web/src/widgets/CommonPanels/ProfilePanels';
import RankingsPanel from '@app/web/src/widgets/CommonPanels/RankingsPanel';
import SortPanel from '@app/web/src/widgets/CommonPanels/SortPanel/SortPanel';
import ThreadActiveMembersPanel from '@app/web/src/widgets/CommonPanels/ThreadPanels/ThreadActiveMembersPanel';
import ThreadAddMemberPanel from '@app/web/src/widgets/CommonPanels/ThreadPanels/ThreadAddMemberPanel';
import ThreadChatPanel from '@app/web/src/widgets/CommonPanels/ThreadPanels/ThreadChatPanel';
import ThreadCreatorFromViewPanel from '@app/web/src/widgets/CommonPanels/ThreadPanels/ThreadCreatorFromViewPanel';
import ThreadMembersPanel from '@app/web/src/widgets/CommonPanels/ThreadPanels/ThreadMembersPanel';
import ThreadPropertiesPanel from '@app/web/src/widgets/CommonPanels/ThreadPanels/ThreadPropertiesPanel';
import ThreadViewPanel from '@app/web/src/widgets/CommonPanels/ThreadViewPanel';
import ClubInvitationPanel from '@app/web/src/widgets/PracticePage/CreateQuizForm/components/ClubInvitationPanel';
import { BaseLayoutRightPanelPortal, useBaseLayout } from '@front/ui';

import AwardDetailsPanel from '../../widgets/CommonPanels/AwardDetailsPanel';
import ClubsPanel from '../../widgets/CommonPanels/ClubsPanel';
import FollowersPanel from '../../widgets/CommonPanels/FollowersPanel';
import FollowingPanel from '../../widgets/CommonPanels/FollowingPanel';
import SetGoalPanel from '../../widgets/CommonPanels/SetGoalPanel';
import SharePanel from '../../widgets/CommonPanels/SharePanel';
import StartQuizChallengersPanel from '../../widgets/CommonPanels/StartQuizPanels/StartQuizChallengersPanel';
import StartQuizHashtagsPanel from '../../widgets/CommonPanels/StartQuizPanels/StartQuizHashtagsPanel';
import StartQuizPanel from '../../widgets/CommonPanels/StartQuizPanels/StartQuizPanel';
import StreaksPanel from '../../widgets/CommonPanels/StreaksPanel';
import ThreadCreatorFromMessagePanel from '../../widgets/CommonPanels/ThreadPanels/ThreadCreatorFromMessagePanel';

export default function GlobalRightPanels({ missingClubQuery = false }) {
  const { rightPanelTarget } = useBaseLayout();

  return (
    <BaseLayoutRightPanelPortal>
      {rightPanelTarget === GlobalPanelKeys.GlobalProfile && <ProfilePanels />}
      {rightPanelTarget === GlobalPanelKeys.GlobalFollowers && (
        <FollowersPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalFollowing && (
        <FollowingPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalClubs && <ClubsPanel />}

      {/* "GlobalClubInvitation" does not apply to pages unrelated to clubs, such as
      on profiles where the functionality of "GlobalClubInvitation" cannot be
      used. */}
      {(rightPanelTarget === GlobalPanelKeys.GlobalClubInvitation ||
        rightPanelTarget === GlobalPanelKeys.GlobalProfile) &&
        !missingClubQuery && <ClubInvitationPanel />}
      {rightPanelTarget === GlobalPanelKeys.GlobalStartQuiz && (
        <StartQuizPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalStartQuizHashtags && (
        <StartQuizHashtagsPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalStartQuizChallengers && (
        <StartQuizChallengersPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalHashtagsDetail && (
        <HashtagDetailPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalEmojiDetail && (
        <EmojiDetailPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalFindUser && <FindUserPanel />}
      {rightPanelTarget === GlobalPanelKeys.GlobalGoalRing && <GoalRingPanel />}
      {rightPanelTarget === GlobalPanelKeys.GlobalLayout && (
        <LayoutSettingPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalFilter && <FilterPanel />}
      {rightPanelTarget === GlobalPanelKeys.GlobalSort && <SortPanel />}
      {rightPanelTarget === GlobalPanelKeys.GlobalRankings && <RankingsPanel />}
      {rightPanelTarget === GlobalPanelKeys.GlobalManageAgents && (
        <ManageAgentsPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalManageAgentsSearch && (
        <ManageAgentsPanelSearch />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalAgentProfile && (
        <AgentProfilePanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalClubMembers && (
        <ClubMembersPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalThreadChat && (
        <ThreadChatPanel />
      )}
      {rightPanelTarget ===
        GlobalPanelKeys.GlobalThreadCreatorFromViewPanel && (
        <ThreadCreatorFromViewPanel />
      )}
      {rightPanelTarget ===
        GlobalPanelKeys.GlobalThreadCreatorFromMessagePanel && (
        <ThreadCreatorFromMessagePanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalThreadView && (
        <ThreadViewPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalThreadProperties && (
        <ThreadPropertiesPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalThreadMembers && (
        <ThreadMembersPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalThreadActiveMembers && (
        <ThreadActiveMembersPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalThreadAddMember && (
        <ThreadAddMemberPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalStreaks && <StreaksPanel />}
      {rightPanelTarget === GlobalPanelKeys.GlobalAwardDetail && (
        <AwardDetailsPanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalDirectToShare && (
        <SharePanel />
      )}
      {rightPanelTarget === GlobalPanelKeys.GlobalSetGoal && <SetGoalPanel />}
    </BaseLayoutRightPanelPortal>
  );
}
