import { useMemo, useState } from 'react';
import { useIaAgentActions } from '@app/web/src/ia/agent';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';
import { buildInfiniteHookMutate } from '@lib/web/apis';

export const useAgentProfileLayoutActions = ({
  agentId,
  reloadAgents,
}: {
  agentId: string;
  reloadAgents: ReturnType<
    typeof buildInfiniteHookMutate<GetIaAgentProfileRhsAgentDefaultRes>
  >;
}) => {
  const [buttonInProgress, setButtonInProgress] = useState(false);
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const iaAgentActions = useIaAgentActions();

  return useMemo(
    () => ({
      addMyAgent: {
        action: async () => {
          setButtonInProgress(true);
          await iaAgentActions.addMyAgent({ id: agentId });
          await reloadAgents();
          setButtonInProgress(false);
        },
        inProgress: buttonInProgress,
      },
      removeMyAgent: {
        action: async () => {
          setButtonInProgress(true);
          await iaAgentActions.removeMyAgent({ id: agentId });
          await reloadAgents();
          setButtonInProgress(false);
        },
        inProgress: buttonInProgress,
      },
      openUserProfile: {
        action: ({ userId }: { userId: string }) => {
          openRightPanel(GlobalPanelKeys.GlobalProfile, {
            userId,
          });
        },
      },
    }),
    [agentId, buttonInProgress, iaAgentActions, openRightPanel, reloadAgents]
  );
};
