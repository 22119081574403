import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Typography } from '@mui/material';
import useDebounce from '@app/web/src/hooks/utils/useDebounce';
import { SearchBar, useBaseRightPanel } from '@front/ui';
import { useInfiniteScroll } from '@lib/web/hooks';
import { Emoji } from '@lib/web/ui';

import useFriendRankingList from '../hooks/useFriendRankingList';

import FriendRankingList from './FriendRankingList';
import RankingListHeader from './RankingListHeader';
import TabArea from './RankingTabArea';

const styles = {
  searchBar: {
    px: '20px',
  },
  searchResult: {
    opacity: 0.75,
    px: '20px',
  },
  empty: {
    px: '12px',
    display: 'flex',
    flexDirection: 'column',
    '& svg': {
      width: { xs: 88, md: 110 },
      height: { xs: 160, md: 200 },
    },
  },
  emptyContent: {
    opacity: 0.5,
  },
};

type EmptyStateProps = {
  variant: 'following' | 'follower';
};

function EmptyState({ variant }: EmptyStateProps) {
  return (
    <Box sx={styles.empty}>
      <Emoji variant="alien" />
      <Typography sx={styles.emptyContent} variant="body1">
        You have no {variant === 'follower' ? 'followers' : 'following'} yet.
      </Typography>
    </Box>
  );
}

type FriendRankingTabContentProps = {
  variant: 'following' | 'follower';
};

export default function FriendRankingTabContent({
  variant,
}: FriendRankingTabContentProps) {
  const { t } = useTranslation('club');
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search.trim());
  const { rightPanelOpened } = useBaseRightPanel();

  const data = useFriendRankingList({
    type: variant,
    keyword: debouncedSearch,
  });

  const {
    dataset,
    isEmpty,
    totalCount,
    isLoadingInitialData,
    error,
    isLoadingMore,
  } = data;

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse: data,
  });

  useEffect(() => {
    if (!rightPanelOpened) {
      setSearch('');
    }
  }, [rightPanelOpened]);

  const isSearching = !!search && isLoadingMore;

  return (
    <TabArea>
      <TabArea.Header>
        <Box sx={styles.searchBar}>
          <SearchBar
            placeholder="Type to Search..."
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
            loading={isSearching}
          />
        </Box>
        <Box sx={styles.searchResult}>
          {isLoadingInitialData && <Skeleton width={100} />}
          {!isLoadingInitialData && (
            <Typography variant="body2">
              {variant === 'follower'
                ? t('## Followers', { count: totalCount || 0 })
                : t('Following ##', { count: totalCount || 0 })}
            </Typography>
          )}
        </Box>
        {!isLoadingInitialData && !error && !isEmpty && (
          <RankingListHeader hideStreak />
        )}
      </TabArea.Header>

      <TabArea.EmptyState
        visible={!isLoadingInitialData && (!!error || isEmpty)}
      >
        <EmptyState variant={variant} />
      </TabArea.EmptyState>

      <TabArea.Scrollbar
        visible={!isLoadingInitialData && !error && !isEmpty}
        scrollableNodeProps={{ ref: scrollRef }}
      >
        <FriendRankingList items={dataset || []} />
      </TabArea.Scrollbar>
    </TabArea>
  );
}
