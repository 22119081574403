import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { MainChallenge as MainChallengeIcon } from '@front/icon';
import {
  EmphasizeButton,
  EmphasizeButtonProps,
  useBaseRightPanel,
} from '@front/ui';

type CreateNewChallengesButtonProps = Omit<EmphasizeButtonProps, 'children'>;

export default function CreateNewChallengesButton({
  onClick,
  ...rest
}: CreateNewChallengesButtonProps) {
  const { t } = useTranslation();
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();

  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);
    openRightPanel(GlobalPanelKeys.GlobalStartQuiz, { isChallengeMode: true });
  };

  return (
    <EmphasizeButton
      prefixIcon={<MainChallengeIcon />}
      onClick={handleClick}
      {...rest}
    >
      {t('button.Create New Challenges')}
    </EmphasizeButton>
  );
}
