import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { Box, Switch, Typography } from '@mui/material';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import {
  ActionRandomize as ActionRandomizeIcon,
  OtherFastForward as OtherFastForwardIcon,
  OtherPlayStroke as OtherPlayStrokeIcon,
  TestClock as TestClockIcon,
} from '@front/icon';
import {
  CardBorder,
  NumberCard,
  SquareAvatar,
  toast,
  useBaseLayout,
} from '@front/ui';
import {
  ExamMode,
  QuizType,
  useAuth,
  useQuizResultSummary,
} from '@lib/web/apis';
import { useIaClubStatus } from '@lib/web/hooks';

import DifficultyOptions from './components/DifficultyOptions';
import ExamModeButton from './components/ExamModeButton';
import IncognitoModeSwitch from './components/IncognitoModeSwitch';
import NumberOfQuestions from './components/NumberOfQuestions';
import StartQuizPaidFutureBlocker from './components/StartQuizPaidFutureBlocker';
import useCreateQuizAction from './hooks/useCreateQuizAction';
import useCreateQuizState from './hooks/useCreateQuizState';

export type Placement = 'center' | 'rhs';
const CLUB_START_REGEX = /^\/club\/([a-zA-Z0-9-]{1,})\/start/;

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  cards: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    flexWrap: 'wrap',
    gap: 2,
    '& > button': {
      flex: 1,
      minWidth: 240,
    },
  },
  avatarWrap: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 54,
  },
  avatars: {
    display: 'flex',
    '& > div:not(:first-of-type)': {
      ml: -1.5,
    },
    '& > div': {
      mask: `url(/mask-avatar.svg) no-repeat center center`,
      maskSize: 'contain',
      backgroundColor: '#131D35',
      p: '2px',
    },
  },
  lightAvatars: {
    '& > div': {
      backgroundColor: 'text.primary',
    },
  },
  lightAvatar: {
    bgcolor: 'text.primary',
  },
  emptyAvatar: {
    opacity: 0.1,
  },
  slider: {
    mx: -2,
    px: 2,
    overflowX: 'hidden',
  },

  durationCard: {
    pb: '20px',
    px: '20px',
    minHeight: 259,
    minWidth: 0,
  },
  examMode: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 140px)',
    gap: 2,
  },

  showTimer: (placement: Placement) => ({
    mt: {
      xs: 2,
      md: placement === 'center' ? 1 : 2,
    },
    display: 'flex',
    alignItems: 'center',
    flexDirection: {
      md: placement === 'center' ? 'row-reverse' : 'row',
    },
    justifyContent: {
      xs: 'space-between',
      md: placement === 'center' ? 'flex-end' : 'space-between',
    },
    gap: 2,
  }),
  showTimerTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },

  difficultyCard: {
    pt: '7px',
    pb: '20px',
    px: '20px',
  },

  randomChallengersIcon: {
    position: 'relative',
  },
  randomIndicator: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 22,
    height: 22,
    borderRadius: '50%',
    bottom: '-2px',
    right: '-2px',
    bgcolor: 'background.darker',
    color: 'text.primary',
    p: '1.57px',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
};

export default function CreateQuizForm({
  hideChallenge = false,
}: {
  hideChallenge?: boolean;
}) {
  const { t } = useTranslation('quiz');
  const router = useRouter();
  const { toggleRightPanel, isTargetPanelOpened } =
    useBaseLayout<GlobalPanelParams>();
  const { member } = useAuth();
  const { beta } = useIaClubStatus(router.query.slug as string);

  const {
    values,
    quizId,
    modeDisabled,
    timerDisabled,
    difficultyDisabled,
    isRandomChallengers,
  } = useCreateQuizState();
  const { updateQuizValues } = useCreateQuizAction();
  const tagsCount = values.tags.length + values.marks.length;
  const randomChallengerCount = values.randomChallengerCount || 0;
  const challengers = values.challengers;

  const { data } = useQuizResultSummary({ quizId });

  const topicOpened = isTargetPanelOpened(
    GlobalPanelKeys.GlobalStartQuizHashtags
  );
  const challengerOpened = isTargetPanelOpened(
    GlobalPanelKeys.GlobalStartQuizChallengers
  );

  useEffect(() => {
    toast.dismiss();
  }, [router]);

  const toggleShowTimer = () => {
    if (timerDisabled) return;
    updateQuizValues({
      isOpenTimer: !values.isOpenTimer,
    });
  };

  const placement = CLUB_START_REGEX.test(router.asPath) ? 'center' : 'rhs';
  const challengerCount = isRandomChallengers
    ? randomChallengerCount
    : challengers.length;

  const challengeCardVisible =
    // because creator question should not have challenge
    data?.data.quiz.type !== QuizType.CreatorUserQuiz && !hideChallenge;

  return (
    <Box sx={styles.root}>
      <Box sx={styles.examMode}>
        <ExamModeButton
          icon={<OtherFastForwardIcon />}
          title={t('Turbo')}
          active={values.mode === ExamMode.Practice}
          onClick={() =>
            updateQuizValues({
              mode: ExamMode.Practice,
            })
          }
          disabled={modeDisabled}
        />
        <ExamModeButton
          icon={<OtherPlayStrokeIcon />}
          title={t('Mock')}
          active={values.mode === ExamMode.MockExam}
          onClick={() =>
            updateQuizValues({
              mode: ExamMode.MockExam,
            })
          }
          disabled={modeDisabled}
        />
      </Box>

      <CardBorder sx={styles.durationCard}>
        <CardBorder.Title>Duration</CardBorder.Title>
        <Box>
          <NumberOfQuestions
            officialDisabled={beta}
            showHint
            sx={styles.slider}
            placement={placement}
          />
          <Box sx={styles.showTimer(placement)}>
            <Box
              sx={[styles.showTimerTitle, timerDisabled && { opacity: 0.5 }]}
            >
              <TestClockIcon width={16} height={16} />
              <Typography variant="body2">
                {t('Show timer in practice')}
              </Typography>
            </Box>
            <Switch
              checked={values.isOpenTimer}
              onClick={toggleShowTimer}
              disabled={timerDisabled}
            />
          </Box>
        </Box>
      </CardBorder>

      <CardBorder sx={styles.difficultyCard}>
        <CardBorder.Title>Difficulty</CardBorder.Title>
        <DifficultyOptions
          disabled={difficultyDisabled}
          placement={placement}
        />
      </CardBorder>

      <IncognitoModeSwitch placement={placement} />

      <Box sx={styles.cards}>
        <NumberCard
          title={t('Hashtags & Reactions')}
          titleIcon="ChatHashtag"
          selected={topicOpened}
          onClick={() =>
            toggleRightPanel(GlobalPanelKeys.GlobalStartQuizHashtags)
          }
        >
          <NumberCard.Value>{tagsCount}</NumberCard.Value>
          <NumberCard.Description>{t('Selected')}</NumberCard.Description>
        </NumberCard>
        {challengeCardVisible && (
          <NumberCard
            title={t('Challenge')}
            titleIcon="MainChallenge"
            selected={challengerOpened}
            onClick={() =>
              toggleRightPanel(GlobalPanelKeys.GlobalStartQuizChallengers)
            }
          >
            <NumberCard.Value>
              <Box sx={styles.avatarWrap}>
                <Box
                  sx={[styles.avatars, challengerOpened && styles.lightAvatars]}
                >
                  {challengers.length === 0 && !isRandomChallengers && (
                    <SquareAvatar blackAndWhite sx={styles.emptyAvatar} />
                  )}
                  {!isRandomChallengers && (
                    <>
                      {challengers.slice(0, 3).map((challenger) => (
                        <Box key={challenger.id}>
                          <SquareAvatar src={challenger.avatarUrl}>
                            {challenger.title}
                          </SquareAvatar>
                        </Box>
                      ))}
                      {challengers.length > 3 && (
                        <Box>
                          <SquareAvatar size={40} blackAndWhite>
                            {`+ ${challengers.length - 3}`}
                          </SquareAvatar>
                        </Box>
                      )}
                    </>
                  )}
                  {isRandomChallengers && (
                    <>
                      <SquareAvatar src={member?.avatarUrl || member?.photoUrl}>
                        {member?.displayName || member?.distinctName}
                      </SquareAvatar>
                      <Box sx={styles.randomChallengersIcon}>
                        <SquareAvatar blackAndWhite>
                          {randomChallengerCount}
                        </SquareAvatar>
                        <Box sx={styles.randomIndicator}>
                          <ActionRandomizeIcon />
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </NumberCard.Value>
            <NumberCard.Description>
              {t('## Challenger', {
                count: challengerCount,
              })}
            </NumberCard.Description>
          </NumberCard>
        )}
      </Box>
      <StartQuizPaidFutureBlocker placement={placement} />
    </Box>
  );
}
