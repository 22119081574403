import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Switch, Typography } from '@mui/material';
import { useLatestValueRef } from '@front/helper';
import {
  ActionRandomize as ActionRandomizeIcon,
  OtherError as OtherErrorIcon,
} from '@front/icon';
import { NumberCard, Slider } from '@front/ui';
import { useActiveUserCount } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';

const styles = {
  root: {
    px: { xs: '16px', md: '12px' },
    display: 'grid',
    gap: 2,
    my: 2,
  },
  valueCard: {
    width: '140px',
  },
  sliderWrapper: {
    px: '10px',
  },
  rightMostSlider: {
    '& .MuiSlider-markLabel[data-index="0"]': {
      display: 'none',
    },
  },
  randomChallengers: {
    display: 'grid',
    gap: 2,
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2,
  },
  switchTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  empty: {
    width: '100%',
    display: 'flex',
    gap: 1,
    color: 'error.dark',
  },
};

type ChallengeMaxAttemptSliderProps = {
  enabled: boolean;
  value?: number;
  onChange?: (value?: number) => void;
};

export default function RandomChallengersOption({
  enabled,
  value,
  onChange,
}: ChallengeMaxAttemptSliderProps) {
  const { t } = useTranslation('quiz');
  const clubSlug = useClubSlug();
  const { data } = useActiveUserCount(clubSlug);
  const maxValue = data ? Math.min(data.data.count, 10) : 0;
  const currentValueRef = useLatestValueRef(value);

  const displayOptions = [
    { value: 1, label: 1 },
    { value: maxValue, label: maxValue },
  ];

  const handleChange = (val: number) => {
    if (val > 0) {
      onChange?.(val);
    }
  };

  const toggleRandomChallengers = (checked: boolean) => {
    if (checked) {
      if (!value || value > maxValue) {
        onChange?.(Math.round(maxValue / 2));
      } else {
        // reset to the previous value
        onChange?.(value);
      }
      return;
    }
    onChange?.(undefined);
  };

  useEffect(() => {
    if (!enabled) return;
    if (!currentValueRef.current || currentValueRef.current > maxValue) {
      onChange?.(Math.round(maxValue / 2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxValue, enabled]);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.switch}>
        <Box sx={styles.switchTitle}>
          <ActionRandomizeIcon width={16} height={16} />
          <Typography variant="body2">
            {t(
              'challenge.settings.randomizeChallengers.label',
              'Match with random challengers'
            )}
          </Typography>
        </Box>
        <Switch
          checked={enabled}
          onChange={(_, checked) => toggleRandomChallengers(checked)}
          disabled={!data}
        />
      </Box>
      {enabled && (
        <>
          {maxValue > 0 && (
            <Box sx={styles.randomChallengers}>
              <NumberCard
                title={t(
                  'challenge.settings.randomizeChallengers.value.label',
                  'Random Challengers'
                )}
                titleIcon="MainChallenge"
                sx={styles.valueCard}
                singleValue
              >
                <NumberCard.Value>{value}</NumberCard.Value>
              </NumberCard>
              <Box sx={styles.sliderWrapper}>
                <Slider
                  min={maxValue === 1 ? 0 : 1}
                  max={maxValue}
                  valueLabelDisplay="on"
                  marks={displayOptions}
                  sx={[maxValue === 1 && styles.rightMostSlider]}
                  value={value}
                  onChange={(_, val) => handleChange(val as number)}
                />
              </Box>
            </Box>
          )}
          {maxValue === 0 && (
            <Box sx={styles.empty}>
              <OtherErrorIcon />
              <Typography variant="body2">
                {t(
                  'challenge.settings.randomizeChallengers.emptyText',
                  'Unable to match with random challengers because there are no club members'
                )}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
