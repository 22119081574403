import { useMemo } from 'react';
import { ThreadSearchUserIconListLayoutItemObj } from '@app/web/src/widgets/ThreadPage/components/ThreadSearchUsers';
import { AgentUserViewSlug, useAgentUsers, useAuth } from '@lib/web/apis';
import { getAgentIconPath } from '@lib/web/utils';

const apiDataToIconListLayoutItemObj = (
  data: GetIaAgentUserSearchAgentUserDefaultRes,
  preselectedMemberIds: string[] = [],
  preselectedDescription?: string
): ThreadSearchUserIconListLayoutItemObj => {
  const agent = data.agent;
  const threadMemberId = `agent_${agent.agentId}`;

  return {
    id: agent.agentId,
    title: agent.agentName,
    titleAction: { type: 'event' as const, value: 'agentTitleClick' },
    description: preselectedMemberIds.includes(threadMemberId)
      ? preselectedDescription
      : undefined,
    avatarUrl: agent.agentPhoto || getAgentIconPath(agent.agentName),
    titleSuffixIcon: 'ProfileClubAgent',
    titleSuffixTooltipTitle: 'Bot',
    actionMap: {
      select: {
        value: 'itemSelected',
      },
      hover: {
        value: 'itemHovered',
      },
    },
    metadata: {
      category: 'agent',
      threadMemberIds: [threadMemberId],
    },
  };
};

export const useSearchAgents = ({
  isSearching,
  debouncedSearch,
  preselectedMemberIds,
  preselectedDescription,
}: {
  isSearching: boolean;
  debouncedSearch: string;
  preselectedMemberIds?: string[];
  preselectedDescription?: string;
}) => {
  const { userId } = useAuth();

  const { dataset: initialApiDataset, isLoading: initialApiLoading } =
    useAgentUsers({
      viewSlug: AgentUserViewSlug.SearchAgentUserDefault,
      search: `userId:${userId}`,
      searchFields: 'userId:eq',
      orderBy: 'order',
      sortBy: 'asc',
    });

  const { dataset: searchingApiDataset, isLoading: searchingApiLoading } =
    useAgentUsers(
      {
        viewSlug: AgentUserViewSlug.SearchAgentUserDefault,
        search: `userId:${userId}`,
        searchFields: 'userId:eq',
        expandSearch: `agent:agentName:${debouncedSearch}`,
        expandSearchFields: 'agent:agentName:contain',
        orderBy: 'order',
        sortBy: 'asc',
        limit: 50,
      },
      {
        enable: isSearching,
      }
    );

  const initialDataset = useMemo(
    () =>
      initialApiDataset.map((data) =>
        apiDataToIconListLayoutItemObj(
          data,
          preselectedMemberIds,
          preselectedDescription
        )
      ),
    [initialApiDataset, preselectedDescription, preselectedMemberIds]
  );
  const searchingDataset = useMemo(
    () =>
      searchingApiDataset.map((data) =>
        apiDataToIconListLayoutItemObj(
          data,
          preselectedMemberIds,
          preselectedDescription
        )
      ),
    [preselectedDescription, preselectedMemberIds, searchingApiDataset]
  );

  return useMemo(
    () => ({
      initialDataset,
      searchingDataset,
      initialApiLoading,
      searchingApiLoading,
    }),
    [initialApiLoading, initialDataset, searchingApiLoading, searchingDataset]
  );
};
