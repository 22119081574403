import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import PanelContent from '@app/web/src/components/PanelContent';
import {
  ActionClear as ActionClearIcon,
  ChatHashtag as ChatHashtagIcon,
} from '@front/icon';
import {
  Accordion,
  BaseLayoutRightPanel,
  IconButton,
  Tag,
  TagProps,
  TipButton,
  toast,
} from '@front/ui';
import { MarkViewSlug, useExamSectionTags, useIaMarkList } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';
import { trackEvent } from '@lib/web/utils';

import StartQuizButton from '../../../PracticePage/CreateQuizForm/components/StartQuizButton';
import { MarkItem } from '../../../PracticePage/CreateQuizForm/contexts/type';
import useCreateQuizAction from '../../../PracticePage/CreateQuizForm/hooks/useCreateQuizAction';
import useCreateQuizState from '../../../PracticePage/CreateQuizForm/hooks/useCreateQuizState';
import getActiveQuizButtonEl from '../../../PracticePage/CreateQuizForm/utils/getActiveQuizButtonEl';

const styles = {
  container: {
    pt: 1,
    pb: '12px',
  },
  tagsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  tags: {
    pt: 0.5,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
  },
  hideTag: {
    display: 'none',
  },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  resetButton: {
    marginLeft: 'auto',
  },
  emojis: {
    pt: 0.5,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
  },
  iconButton: {
    width: '26px',
    height: '26px',
  },
};

function LoadingSkeleton() {
  return (
    <>
      <Skeleton height={60} />
      <Skeleton />
      <Skeleton />
    </>
  );
}

type TagsProps = {
  title: string;
  tags: GetExamSectionTagsRes[];
  color: TagProps['color'];
  selectedTags: GetExamSectionTagsRes[];
  onClick: (tag: GetExamSectionTagsRes) => void;
  icon?: ReactNode;
  disabled?: boolean;
};

function Tags({
  title,
  tags,
  color,
  disabled,
  selectedTags,
  icon,
  onClick,
}: TagsProps) {
  const { t } = useTranslation('quiz');
  return (
    <div>
      <Accordion title={`${title} (${selectedTags.length})`} titleIcon={icon}>
        <Box sx={styles.tags}>
          {tags.map((tag) => {
            const selected = selectedTags.some((tv) => tv.code === tag.code);
            const tagDisabled =
              !tag.questionCount || tag.questionCount === 0 || disabled;
            return (
              <Tag
                key={tag.id}
                label={tag.name}
                color={color}
                onClick={() => onClick(tag)}
                selected={selected}
                disabled={tagDisabled}
              />
            );
          })}
          {tags.length === 0 && (
            <Typography variant="body2" color="grey.500">
              {t('Practice more problems to see past ## topic', {
                title: title.toLowerCase(),
              })}
            </Typography>
          )}
        </Box>
      </Accordion>
    </div>
  );
}

function Emojis({ disabled }: { disabled?: boolean }) {
  const { t } = useTranslation('quiz');

  const { values } = useCreateQuizState();
  const selectedEmojis = values.marks || [];

  const { sectionId } = useCurrentIaClub();
  const { dataset, isEmpty } = useIaMarkList({
    viewSlug: MarkViewSlug.IconRhsIAMarkDefault,
    search: `emojiMarkedStatus:1;sectionId:${sectionId}`,
    searchFields: 'emojiMarkedStatus:eq;sectionId:eq',
  });

  const { updateQuizValues } = useCreateQuizAction();

  const handleSelect = (item: GetIaMarkRhsDefaultViewRes) => {
    const existing = selectedEmojis.some((e) => e.id === item.id);

    let items: MarkItem[];
    if (existing) {
      items = selectedEmojis.filter((e) => e.id !== item.id);
    } else {
      items = [...selectedEmojis, { id: item.id, code: item.code }];
    }
    updateQuizValues({ marks: items });
  };

  return (
    <div>
      <Accordion title={`Reactions (${selectedEmojis.length})`}>
        <Box sx={styles.emojis}>
          {dataset.map((item) => {
            const selected = selectedEmojis.some((e) => e.id === item.id);
            return (
              <IconButton
                key={item.id}
                selected={selected}
                onClick={() => handleSelect(item)}
                sx={styles.iconButton}
                disabled={disabled}
              >
                <Typography>{item.code}</Typography>
              </IconButton>
            );
          })}
          {isEmpty && (
            <Typography variant="body2" color="grey.500">
              {t('No reactions')}
            </Typography>
          )}
        </Box>
      </Accordion>
    </div>
  );
}

export default function StartQuizHashtagsPanel() {
  const { t } = useTranslation(['quiz', 'common']);
  const { sectionId } = useCurrentIaClub();
  const { values, isTagsDirty, isMarksDirty, hashtagDisabled } =
    useCreateQuizState();
  const { updateQuizValues, resetTags, resetMarks } = useCreateQuizAction();
  const tagsValue = values.tags;

  const { data } = useExamSectionTags(sectionId);

  const tagsMap = useMemo(() => {
    if (!data)
      return {
        mistakes: [] as GetExamSectionTagsRes[],
        overtime: [] as GetExamSectionTagsRes[],
        outOfPractices: [] as GetExamSectionTagsRes[],
      };

    const mistakes: GetExamSectionTagsRes[] = [];
    const overtime: GetExamSectionTagsRes[] = [];
    const outOfPractices: GetExamSectionTagsRes[] = [];

    data.data.items.forEach((item) => {
      if (item.isMistake) mistakes.push(item);
      if (item.isOvertime) overtime.push(item);
      if (item.isOutOfPractice) outOfPractices.push(item);
    });

    return {
      mistakes,
      overtime,
      outOfPractices,
    };
  }, [data]);

  const selectedTagsInCategory = useMemo(() => {
    const mistakes: GetExamSectionTagsRes[] = [];
    const overtime: GetExamSectionTagsRes[] = [];
    const ourOfPractices: GetExamSectionTagsRes[] = [];
    if (tagsValue.length > 0) {
      tagsValue.forEach((tag) => {
        if (tag.isMistake) mistakes.push(tag);
        if (tag.isOvertime) overtime.push(tag);
        if (tag.isOutOfPractice) ourOfPractices.push(tag);
      });
    }
    return {
      mistakes,
      overtime,
      ourOfPractices,
    };
  }, [tagsValue]);

  const handleTagClick = (tag: GetExamSectionTagsRes) => {
    if (tagsValue.some((tv) => tv.code === tag.code)) {
      updateQuizValues({
        tags: tagsValue.filter((tv) => tv.code !== tag.code),
      });

      toast.warning(t('toast.Hashtag Removed'), {
        anchorEl: getActiveQuizButtonEl(),
      });

      trackEvent('form', {
        elementName: 'hashtag',
        action: 'remove',
      });
    } else {
      updateQuizValues({
        tags: [...tagsValue, tag],
      });
      toast.success(t('toast.Hashtag Added'), {
        anchorEl: getActiveQuizButtonEl(),
      });

      trackEvent('form', {
        elementName: 'hashtag',
        action: 'add',
      });
    }
  };

  const clearHashtagsAndEmojis = () => {
    resetTags();
    resetMarks();

    toast.success(t('Hashtags & reactions has been reset successfully'), {
      anchorEl: getActiveQuizButtonEl(),
    });
  };

  return (
    <BaseLayoutRightPanel
      titleIcon={<ChatHashtagIcon width="16" height="16" />}
      title={t('panel.Hashtags & Reactions')}
      toolComponent={
        isTagsDirty || isMarksDirty ? (
          <TipButton
            title={t('toolbar.Reset')}
            onClick={clearHashtagsAndEmojis}
            sx={styles.resetButton}
          >
            <ActionClearIcon />
          </TipButton>
        ) : undefined
      }
    >
      <PanelContent actionComponent={<StartQuizButton />}>
        <Box sx={styles.container}>
          {!data && <LoadingSkeleton />}
          {!!data && (
            <Box sx={styles.tagsContainer}>
              <Tags
                title={t('Mistakes')}
                tags={tagsMap.mistakes}
                color="error"
                selectedTags={selectedTagsInCategory.mistakes}
                onClick={handleTagClick}
                disabled={hashtagDisabled}
              />
              <Tags
                title={t('Overtime')}
                tags={tagsMap.overtime}
                color="warning"
                selectedTags={selectedTagsInCategory.overtime}
                onClick={handleTagClick}
                disabled={hashtagDisabled}
              />
              <Tags
                title={t('Have Not Practiced Yet', 'Haven’t Practiced Yet')}
                tags={tagsMap.outOfPractices}
                color="primary"
                selectedTags={selectedTagsInCategory.ourOfPractices}
                onClick={handleTagClick}
                disabled={hashtagDisabled}
              />
              <Emojis disabled={hashtagDisabled} />
            </Box>
          )}
        </Box>
      </PanelContent>
    </BaseLayoutRightPanel>
  );
}
