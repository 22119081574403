import { useMemo } from 'react';
import useNotificationCustomizeCtas from '@app/web/src/widgets/NotificationPage/NotificationList/hooks/useNotificationCustomizeCtas';
import ThreadMessage from '@lib/web/thread/ThreadChat/components/ThreadMessage/ThreadMessage';
import FileBlockRender, {
  FileBlockRendererProps,
} from '@lib/web/thread/ThreadTextComposer/components/blocks/FileBlock/FileBlockRender';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';

import ThreadMentionUser from '../components/ThreadMentionUser';

export default function useThreadRender() {
  const notificationCtas = useNotificationCustomizeCtas();

  return useMemo(
    () => ({
      ...notificationCtas,
      mentionTag: (ev: {
        memberId: string;
        userId?: string;
        agentId?: string;
      }) => <ThreadMentionUser {...ev} />,
      fileBlock: (ev: FileBlockRendererProps) => <FileBlockRender {...ev} />,
      messageItem: (ev: {
        channel: Channel;
        message: StreamMessage<StreamChatGenerics>;
        messages: StreamMessage<StreamChatGenerics>[];
        childChannel?: Channel;
      }) => <ThreadMessage {...ev} />,
    }),
    [notificationCtas]
  );
}
