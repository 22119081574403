import { useMemo } from 'react';
import { useAuth, useSearchMemberList, useUserFollow } from '@lib/web/apis';
import { emailRegex } from '@lib/web/utils';

export default function useSearchUserDataset(keyword: string) {
  const { member } = useAuth();
  const userId = member?.userId || '';

  // initial search results
  const friendSearchData = useUserFollow({
    type: 'following',
    userId,
    isGetStatus: true,
    pageSize: 50,
  });

  // search results
  const memberSearchData = useSearchMemberList(keyword, 50);

  const { dataset: memberSearchDataset, isLoading: memberSearchLoading } =
    memberSearchData;

  const newUserEmail =
    !!keyword &&
    !memberSearchDataset.length &&
    !memberSearchLoading &&
    emailRegex.test(keyword)
      ? keyword
      : '';

  return useMemo(() => {
    const { isLoading: friendSearchLoading } = friendSearchData;

    return {
      friendSearchData,
      memberSearchData,
      newUserEmail,
      isLoading: memberSearchLoading || friendSearchLoading,
    };
  }, [friendSearchData, memberSearchData, memberSearchLoading, newUserEmail]);
}
