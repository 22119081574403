import { useTranslation } from 'react-i18next';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { MainProfileSolid as MainProfileSolidIcon } from '@front/icon';
import { BaseLayoutRightPanel, useBaseLayout } from '@front/ui';

import Profile from './components/Profile';

export default function ProfilePanels() {
  const { t } = useTranslation('profile');
  const { getRightParams } = useBaseLayout<GlobalPanelParams>();

  const { userId, memberId } = getRightParams(GlobalPanelKeys.GlobalProfile);

  return (
    <BaseLayoutRightPanel
      titleIcon={<MainProfileSolidIcon width="16" height="16" />}
      title={t('Profile')}
    >
      <Profile userId={userId} memberId={memberId} />
    </BaseLayoutRightPanel>
  );
}
