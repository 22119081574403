import { useCallback } from 'react';
import { ClubMemberRole } from '@lib/web/apis';

import useMemberClubRole from './useMemberClubRole';

export default function useMemberClubRoleMethods() {
  const { isAdmin, isEditor, creatorId } = useMemberClubRole();

  const canUpdateOrDelete = useCallback(
    ({
      userId,
      role,
      isMe,
    }: {
      userId: string;
      role: ClubMemberRole;
      isMe?: boolean;
    }) => {
      const userIsCreator = creatorId === userId;
      if ((!isAdmin && !isEditor) || userIsCreator)
        return { delete: false, update: false };

      // Editor cannot remove/ edit Admin
      // Editor can edit roles for Editors and Members only
      if (role === ClubMemberRole.Admin && isEditor)
        return { delete: false, update: false };

      if (isMe) {
        return { delete: false, update: true };
      }

      return { delete: true, update: true };
    },
    [creatorId, isAdmin, isEditor]
  );

  return {
    canUpdateOrDelete,
  };
}
