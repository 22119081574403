import { useMemo } from 'react';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';
import { ThreadUser } from '@lib/web/thread/types';

export const useThreadViewChannelAvailableAction = () => {
  const { openRightPanel, getRightParams, isTargetPanelOpened } =
    useBaseRightPanel<GlobalPanelParams>();

  return useMemo(
    () => ({
      viewThread: {
        action: (id: string) => {
          const { channelCid } = getRightParams(
            GlobalPanelKeys.GlobalThreadChat
          );
          if (
            isTargetPanelOpened(GlobalPanelKeys.GlobalThreadChat) &&
            channelCid === id
          ) {
            // already opened
            return;
          }
          openRightPanel(GlobalPanelKeys.GlobalThreadChat, {
            channelCid: id,
          });
        },
      },
      openThread: {
        action: (id: string) => {
          const { channelCid } = getRightParams(
            GlobalPanelKeys.GlobalThreadChat
          );
          if (
            isTargetPanelOpened(GlobalPanelKeys.GlobalThreadChat) &&
            channelCid === id
          ) {
            // already opened
            return;
          }
          openRightPanel(GlobalPanelKeys.GlobalThreadChat, {
            channelCid: id,
          });
        },
      },
      openUserOrAgentProfile: {
        action: ({ user }: { user?: ThreadUser }): void => {
          if (user?.type === 'agent') {
            openRightPanel(GlobalPanelKeys.GlobalAgentProfile, {
              agentId: user.agentId ?? '',
            });
          }
          if (user?.type === 'user') {
            openRightPanel(GlobalPanelKeys.GlobalProfile, {
              userId: user.userId,
            });
          }
        },
      },
    }),
    [getRightParams, isTargetPanelOpened, openRightPanel]
  );
};
