import { useMemo } from 'react';
import { IaRenderContextValue } from '@lib/ia/src/core/IaRender/IaRenderContext';

import AcceptClubInvite from '../components/CtaActionButtons/AcceptClubInvite';
import ChallengeInvitationCard from '../components/CtaActionButtons/ChallengeInvitationCard';
import FriendFollowButton from '../components/CtaActionButtons/FriendFollowButton';
import GoalRingCard from '../components/CtaActionButtons/GoalRingCard';
import LeagueCard from '../components/CtaActionButtons/LeagueCard';
import PlanCard from '../components/CtaActionButtons/PlanCard';
import ResendVerifyEmailButton from '../components/CtaActionButtons/ResendVerifyEmailButton';
import SharedChallengeCard from '../components/CtaActionButtons/SharedChallengeCard';
import SharedPlaylistCard from '../components/CtaActionButtons/SharedPlaylistCard';
import SharedSummaryCard from '../components/CtaActionButtons/SharedSummaryCard';
import StreakCard from '../components/CtaActionButtons/StreakCard';

export default function useNotificationCustomizeCtas() {
  const renders: IaRenderContextValue = useMemo(() => {
    return {
      'cta.league.current': LeagueCard,
      'cta.goal.ring.*': GoalRingCard,
      'cta.streak.*': StreakCard,
      'cta.shared.summary': SharedSummaryCard,
      'cta.shared.challenge': SharedChallengeCard,
      'cta.email.verification.remind': ResendVerifyEmailButton,
      'cta.shared.*.playlist': SharedPlaylistCard,
      'cta.membership.plan.info.*': PlanCard,
      'cta.challenge.invitation': ChallengeInvitationCard,
      'cta.club.invitation': AcceptClubInvite,
      'cta.follow.followback': FriendFollowButton,
    };
  }, []);

  return renders;
}
