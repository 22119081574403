import { useCallback, useMemo, useState } from 'react';
import { appConfig } from '@front/config';
import {
  apis,
  buildHookMutate,
  ReferInvitationStatus,
  useReferInvitation,
} from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';
import { callWithToast, CallWithToastParams } from '@lib/web/utils';
import { differenceInDays } from 'date-fns';

export type InvitedHistoryItem = GetReferInvitationItemRes & {
  resendAble: boolean;
};

export type InvitationNewUserItem = {
  email: string;
  isAccepted: boolean;
  isPending: boolean;
  resendAble: boolean;
  actionText: string;
};

const getActionStatusText = (
  user: Omit<InvitationNewUserItem, 'actionText'>
) => {
  if (user.isAccepted) {
    return 'accepted';
  }
  if (user.resendAble) {
    return 'resend';
  }
  if (user.isPending) {
    return 'pending';
  }
  return 'default';
};

export default function useAhaInvitation() {
  const clubSlug = useClubSlug();
  const [pendingEmails, setPendingEmails] = useState<string[]>([]);
  const { data, mutate } = useReferInvitation();

  const reloadInvitation = useMemo(() => buildHookMutate(mutate), [mutate]);

  const invitedHistory: InvitedHistoryItem[] = useMemo(() => {
    if (!data) return [];

    return data.data.items
      .filter((item) =>
        [
          ReferInvitationStatus.Accepted,
          ReferInvitationStatus.Invited,
          ReferInvitationStatus.Canceled,
          ReferInvitationStatus.Archived,
        ].includes(item.status)
      )
      .map((item) => {
        return {
          ...item,
          resendAble: item.lastReSentAt
            ? (item.status === ReferInvitationStatus.Invited ||
                item.status === ReferInvitationStatus.Canceled) &&
              differenceInDays(new Date(), new Date(item.lastReSentAt)) >
                appConfig.resendInvitationIntervalDays
            : false,
        };
      });
  }, [data]);

  return {
    isEmpty: !!data && !invitedHistory.length,
    invitedHistory,
    pendingEmails,
    reloadInvitation,
    inviteToAha: useCallback(
      async (
        emails: string[],
        {
          onSuccess,
          toastOptions,
        }: { onSuccess?: () => void; toastOptions?: CallWithToastParams } = {}
      ) => {
        setPendingEmails((st) => st.concat(emails));

        const today = new Date().toISOString();

        const items = [...(data?.data.items || [])];
        await reloadInvitation(
          callWithToast(
            apis.refer.sentInvitation({
              clubSlug,
              emails,
              status: ReferInvitationStatus.Invited,
            }),
            {
              successMsg: 'Invitation sent',
              ...toastOptions,
            }
          ),
          {
            optimisticData: {
              items: [
                ...items.map((item) =>
                  emails.includes(item.receiver.email)
                    ? {
                        ...item,
                        lastReSentAt: today,
                        updatedAt: today,
                      }
                    : item
                ),
                ...emails
                  .filter(
                    (email) =>
                      !items.some((item) => item.receiver.email === email)
                  )
                  .map((email) => ({
                    id: email,
                    receiver: { email },
                    userId: '',
                    clubId: '',
                    status: ReferInvitationStatus.Invited,
                    remark: '',
                    lastReSentAt: today,
                    joinedAt: null,
                    createdAt: today,
                    updatedAt: today,
                  })),
              ],
            },
          }
        );

        if (onSuccess) onSuccess();

        setPendingEmails((st) => st.filter((email) => !emails.includes(email)));
      },
      [clubSlug, data?.data.items, reloadInvitation]
    ),
    newUserFormatter: useCallback(
      (email: string) => {
        if (!email) return null;

        const historyData = invitedHistory.find(
          (item) => item.receiver.email === email
        );
        const newUser = {
          email,
          isAccepted: historyData?.status === ReferInvitationStatus.Accepted,
          isPending: historyData?.status === ReferInvitationStatus.Invited,
          resendAble: historyData?.resendAble === true,
        };
        return {
          ...newUser,
          actionText: getActionStatusText(newUser),
        } as InvitationNewUserItem;
      },
      [invitedHistory]
    ),
  };
}
