import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import {
  FormProvider,
  useController,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { alpha, Box, MenuItem, Theme } from '@mui/material';
import { ActionChevronDown as ActionChevronDownIcon } from '@front/icon';
import { getPropertyIcon, PropertyType, Select, TextField } from '@front/ui';

import Icon from '../../components/Icon';
import IaActionContextProvider from '../../core/IaAction/IaActionProvider';
import {
  EmojiOption,
  FilterConditionChangedEvent,
  FilterConditionConfig,
  FilterField,
  FilterOperator,
  FilterValues,
  Option,
} from '../types';
import { getOperatorConfig, getPropertyOperatorConfig } from '../utils';

import DateFilter from './components/DateFilter/DateFilter';
import IconFilter from './components/IconFilter';
import NumberFilter from './components/NumberFilter';
import OptionSelectFilter from './components/OptionSelectFilter';
import PersonSelectFilter from './components/PersonSelectFilter';
import StatusSelectFilter from './components/StatusSelectFilter';
import TextFilter from './components/TextFilter';

const styles = {
  root: {
    px: '12px',
    pt: 1,
    '& .textfield-suffix': { pr: '10px' },
    '& .MuiAutocomplete-endAdornment': { right: '10px' },
  },
  labelField: {
    '& .textfield-main': {
      border: (theme: Theme) =>
        `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
    },
  },
  arrowIcon: {
    pr: '10px',
    pl: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
  },
};

function getAvailableOperators(
  allOperators: FilterOperator[],
  field: FilterField
) {
  const fieldOperators = [
    ...allOperators,
    ...(field?.extensionOperators || []),
  ];
  return field?.excludeOperators
    ? fieldOperators.filter((op) => !field.excludeOperators?.includes(op))
    : fieldOperators;
}

function getDefaultOperator(
  availableOperators: FilterOperator[],
  defaultOperator?: FilterOperator
) {
  if (availableOperators.length === 0) return null;
  if (defaultOperator && availableOperators.includes(defaultOperator))
    return defaultOperator;
  return availableOperators[0];
}

type FilterConditionFieldProps = {
  field: FilterField;
  operator?: FilterOperator | null;
};
export function FilterConditionField({
  field,
  operator,
}: FilterConditionFieldProps) {
  if (!operator) return null;
  const operatorConfig = getOperatorConfig(operator);

  if (operatorConfig.operandCount === 1) {
    return null;
  }

  switch (field.type) {
    case PropertyType.ShortText:
    case PropertyType.Url:
    case PropertyType.Email:
    case PropertyType.UUID:
    case PropertyType.ShortId:
      return <TextFilter item={field} />;
    case PropertyType.LongText:
      return <TextFilter item={field} multiline />;
    case PropertyType.Number:
      return <NumberFilter item={field} />;
    case PropertyType.Select:
    case PropertyType.MultiSelect: {
      const multiple = operator !== 'Is'; // operators you need to make it to multiple choices
      return (
        <OptionSelectFilter
          item={field}
          multiple={multiple}
          showCheckbox={multiple}
        />
      );
    }
    case PropertyType.Status:
      return <StatusSelectFilter item={field} multiple showCheckbox />;
    case PropertyType.Person: {
      const multiple = operator !== 'Is'; // operators you need to make it to multiple choices
      return (
        <PersonSelectFilter
          item={field}
          multiple={multiple}
          showCheckbox={multiple}
        />
      );
    }
    case PropertyType.Icon:
      return <IconFilter item={field} />;
    case PropertyType.DateOrTime:
      return <DateFilter field={field} operator={operator} />;
    // TODO: Role, Relation, Hashtag,...
  }

  return null;
}

type FilterConditionOperatorProps = {
  field: FilterField;
};
export function FilterConditionOperator({
  field,
}: FilterConditionOperatorProps) {
  const propertyOperatorConfig = getPropertyOperatorConfig(field.type);
  const filteredOperators = getAvailableOperators(
    propertyOperatorConfig.operators,
    field
  );
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    control,
    name: 'operator',
    rules: { required: true },
  });

  const operatorConfig = useCallback((op: FilterOperator) => {
    return getOperatorConfig(op);
  }, []);

  return (
    <Select
      placeholder="Select Operator"
      labelIcon={null}
      value={value}
      onChange={(ev) => onChange(ev.target.value)}
      size="rwd"
      endAdornment={
        <Box sx={styles.arrowIcon} component="span">
          <ActionChevronDownIcon width={16} height={16} />
        </Box>
      }
    >
      {filteredOperators.map((option, index) => (
        <MenuItem key={index} value={option}>
          {operatorConfig(option).label}
        </MenuItem>
      ))}
    </Select>
  );
}

export type FilterConditionHandler = {
  addEmoji: (emoji: EmojiOption) => void;
};

type FilterConditionProps = FilterConditionConfig & {
  onChange?: (event: FilterConditionChangedEvent) => void;
  onOpenEmojiPicker?: () => void;
  onEnter?: () => void;
  searchUsers?: (props: { keyword: string }) => Promise<Option[]>;
};

const FilterCondition = forwardRef<
  FilterConditionHandler,
  FilterConditionProps
>(
  (
    {
      id,
      field,
      operator,
      values,
      onChange,
      onOpenEmojiPicker,
      onEnter,
      searchUsers,
    },
    ref
  ) => {
    const propertyOperatorConfig = getPropertyOperatorConfig(field.type);
    const filteredOperators = getAvailableOperators(
      propertyOperatorConfig.operators,
      field
    );
    const defaultOperator = getDefaultOperator(
      filteredOperators,
      propertyOperatorConfig.defaultOperator
    );

    const methods = useForm<any>({
      mode: 'onChange',
      defaultValues: {
        operator: operator || defaultOperator,
        [field.name]: values,
      },
    });

    const conditionValues = methods.watch(field.name);
    const selectedOperator = methods.watch('operator');
    const operatorConfig = getOperatorConfig(selectedOperator);
    const { isValid, isDirty } = methods.formState;
    const resetField = methods.resetField;
    const setValue = methods.setValue;

    useEffect(() => {
      let newValues: FilterValues = null;
      if (
        conditionValues &&
        typeof conditionValues === 'object' &&
        'type' in conditionValues
      ) {
        // complex type => do nothing
        newValues = conditionValues;
      } else if (conditionValues) {
        // converse to array to easy handle
        newValues = Array.isArray(conditionValues)
          ? conditionValues
          : [conditionValues];
      }

      onChange?.({
        id,
        field,
        values: newValues,
        operator: selectedOperator,
        isValid,
        isDirty,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conditionValues, isValid, isDirty, selectedOperator]);

    useEffect(() => {
      // reset the filter value when the operator's operandCount changed.
      // e.g: change from the operator Is (operandCount = 2) to IsEmpty (operandCount = 1)
      resetField(field.name);
    }, [field.name, operatorConfig.operandCount, resetField]);

    const emojis = useMemo(() => {
      if (field.type !== PropertyType.Icon || !conditionValues) {
        return [];
      }
      return Array.isArray(conditionValues)
        ? conditionValues
        : [conditionValues];
    }, [conditionValues, field.type]);

    const availableActions = {
      openEmojiPicker: {
        action: () => {
          onOpenEmojiPicker?.();
        },
      },
      removeEmoji: {
        action: (emojiIndex: number) => {
          const allEmojis = [...emojis];
          allEmojis.splice(emojiIndex, 1);
          setValue(field.name, allEmojis, {
            shouldDirty: true,
            shouldValidate: true,
          });
        },
      },
      onEnter: {
        action: () => {
          onEnter?.();
        },
      },
      searchUsers: {
        action: ({ keyword }: { keyword: string }) => {
          return searchUsers?.({ keyword });
        },
      },
    };

    const addEmoji = useCallback(
      (selectedEmoji: EmojiOption) => {
        const selectedEmojis = [...emojis, selectedEmoji];
        setValue(field.name, selectedEmojis, {
          shouldDirty: true,
          shouldValidate: true,
        });
      },
      [emojis, field.name, setValue]
    );

    useImperativeHandle(
      ref,
      () => ({
        addEmoji,
      }),
      [addEmoji]
    );

    return (
      <IaActionContextProvider availableActions={availableActions}>
        <FormProvider {...methods}>
          <Box sx={styles.root} component="form">
            <Box sx={styles.labelField}>
              <TextField
                labelIcon={null}
                value={field.label}
                fullWidth
                size="rwd"
                prefix={
                  field.customIcon ? (
                    <Icon name={field.customIcon} width={16} height={16} />
                  ) : (
                    getPropertyIcon(field.type, 16)
                  )
                }
                suffix={<ActionChevronDownIcon width={16} height={16} />}
                disabled
              />
            </Box>
            <FilterConditionOperator field={field} />
            <FilterConditionField field={field} operator={selectedOperator} />
          </Box>
        </FormProvider>
      </IaActionContextProvider>
    );
  }
);

FilterCondition.displayName = 'FilterCondition';
export default FilterCondition;
