import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useDateFormat } from '@front/helper';
import { MainPractice as MainPracticeIcon } from '@front/icon';
import { Button, NumberCard, useBaseRightPanel } from '@front/ui';
import { useClubSlug } from '@lib/web/hooks';
import { subHours } from 'date-fns';

const styles = {
  card: { flex: 1, maxWidth: 255 },
  streakBadge: {
    display: 'flex',
    alignItems: 'center',
  },
  streakRank: {
    pt: 1,
    fontWeight: 350,
    fontSize: '48px',
  },
};

type StreakCardProps = {
  tag: GetNotificationRes['tag'];
  type: string;
  streakCount: number;
  streakDateStart: string | null;
  streakDateEnd: string | null;
};

function patchStreakDateEnd(date: string | null) {
  /**
   * since we use utc to store streak period in backend, which doesn't consider user timezone
   * for example, when user achieve streak at 1/16 15:00 the streak will look like
   * 1/16 00:00 ~ 1/16 23:59
   *
   * we ignore the timezone of streak date, because it's hard to predict
   * but we assume most of us now is based on tw time, so we do some trick for the streak end date, make it a little resonable by shift it to the middle of day instead of end of day
   */
  if (!date) return;
  return subHours(new Date(date), 12);
}

export default function StreakCard({
  tag,
  type,
  streakDateStart,
  streakDateEnd,
  streakCount,
}: StreakCardProps) {
  const { t } = useTranslation('notification');
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const clubSlug = useClubSlug();

  const { displayDateFormat } = useDateFormat();
  if (
    tag === 'streak.reminder.broken.member.club' &&
    type === 'cta.streak.reminder'
  ) {
    return (
      <NumberCard
        sx={styles.card}
        title={t('Last Streak')}
        titleIcon="EmojiFire"
        onClick={() => {
          openRightPanel(GlobalPanelKeys.GlobalStreaks, {
            type: 'selectedStreak',
            selectedStreakDate: patchStreakDateEnd(streakDateEnd),
          });
        }}
      >
        <NumberCard.Value>{streakCount}</NumberCard.Value>
        <NumberCard.Description>
          {t('Days ended', {
            date: displayDateFormat(patchStreakDateEnd(streakDateEnd)),
            count: streakCount,
          })}
        </NumberCard.Description>
      </NumberCard>
    );
  }

  if (tag === 'streak.reminder.daily.member.club') {
    return (
      <Button
        prefixIcon={<MainPracticeIcon />}
        component={Link}
        href={`/club/${clubSlug}/start`}
      >
        {t('button.Start Practice')}
      </Button>
    );
  }

  if (
    tag === 'streak.achieved.single.day.member.club' &&
    type === 'cta.streak.achieved'
  ) {
    return (
      <NumberCard
        sx={styles.card}
        title={t('Current Streak')}
        titleIcon="EmojiFire"
        onClick={() => {
          openRightPanel(GlobalPanelKeys.GlobalStreaks, {
            type: 'selectedStreak',
            selectedStreakDate: patchStreakDateEnd(streakDateEnd),
          });
        }}
      >
        <NumberCard.Value>{streakCount}</NumberCard.Value>
        <NumberCard.Description>
          {!!streakDateStart &&
            t('Days since', {
              date: displayDateFormat(streakDateStart, {
                addPrefix: false,
                showToday: true,
              }).toLowerCase(),
              count: streakCount,
            })}
        </NumberCard.Description>
      </NumberCard>
    );
  }

  if (
    tag === 'streak.achieved.multiple.day.member.club' &&
    type === 'cta.streak.reminder'
  ) {
    return (
      <NumberCard
        sx={styles.card}
        title={t('Current Streak')}
        titleIcon="EmojiFire"
        onClick={() => {
          openRightPanel(GlobalPanelKeys.GlobalStreaks, {
            type: 'selectedStreak',
            selectedStreakDate: patchStreakDateEnd(streakDateEnd),
          });
        }}
      >
        <NumberCard.Value>{streakCount}</NumberCard.Value>
        <NumberCard.Description>
          {!!streakDateStart &&
            t('Days since', {
              date: displayDateFormat(streakDateStart, {
                addPrefix: false,
                showToday: true,
              }).toLowerCase(),
              count: streakCount,
            })}
        </NumberCard.Description>
      </NumberCard>
    );
  }

  return null;
}
