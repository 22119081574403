import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import {
  EmojiFire as EmojiFireIcon,
  OtherCalendarDay as OtherCalendarDayIcon,
} from '@front/icon';
import { BaseLayoutRightPanel, useBaseRightPanel } from '@front/ui';
import { addMonths, isFuture, startOfMonth, subMonths } from 'date-fns';
import { TFunction } from 'i18next';

import Calendar from './Calendar';

const styles = {
  content: {
    display: 'grid',
    gap: 2,
  },
};

const loadSize = 4;

const getCalendarSettings = (
  t: TFunction<'club', undefined>,
  params?: GlobalPanelParams[GlobalPanelKeys.GlobalStreaks]
) => {
  if (params?.type === 'streak' || params?.type === 'longestStreak')
    return {
      title: t('Streak'),
      Icon: EmojiFireIcon,
    };

  return {
    title: t('toolbar.Calendar'),
    Icon: OtherCalendarDayIcon,
  };
};

export default function StreaksPanel() {
  const { t } = useTranslation('club');
  const [beforeSize, setBeforeSize] = useState(loadSize);
  const [afterSize, setAfterSize] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const loaded = useRef(false);

  const { getRightParams } = useBaseRightPanel<GlobalPanelParams>();

  const panelParams = getRightParams(GlobalPanelKeys.GlobalStreaks);

  const { initDate, selectedDate } = useMemo(() => {
    if (panelParams.type === 'longestStreak') {
      return {
        initDate: panelParams.longestStreakEndDate || new Date(),
        selectedDate: panelParams.longestStreakEndDate,
      };
    }
    if (panelParams.type === 'selectedStreak') {
      return {
        initDate: panelParams.selectedStreakDate || new Date(),
        selectedDate: panelParams.selectedStreakDate,
      };
    }
    return {
      initDate: new Date(),
      selectedDate: new Date(),
    };
  }, [
    panelParams.longestStreakEndDate,
    panelParams.selectedStreakDate,
    panelParams.type,
  ]);

  const dates = useMemo(() => {
    let startDate = startOfMonth(initDate);
    if (isFuture(startDate)) {
      startDate = startOfMonth(new Date());
    }

    const monthsBefore = [...new Array(beforeSize)].map((_, i) =>
      subMonths(startDate, beforeSize - i)
    );

    const monthsAfter = [...new Array(afterSize)]
      .map((_, i) => addMonths(startDate, i + 1))
      .filter((item) => !isFuture(item));

    return [...monthsBefore, startDate, ...monthsAfter];
  }, [afterSize, beforeSize, initDate]);

  useEffect(() => {
    const scrollTarget = scrollRef.current;
    const handleScroll = (ev: Event) => {
      const target = ev.target as HTMLDivElement;
      if (loaded.current && target) {
        const { scrollTop, scrollHeight, clientHeight } = target;
        if (scrollTop <= 100) {
          setBeforeSize((s) => s + loadSize);
        }

        if (scrollHeight - scrollTop - clientHeight <= 100) {
          setAfterSize((s) => s + loadSize);
        }
      }
    };

    if (scrollTarget) {
      scrollTarget.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollTarget)
        scrollTarget.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!scrollRef.current) return;

    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        loaded.current = true;
      }
    }, 10);
  }, []);

  useEffect(() => {
    // reset loaded months => to rerender months which contains the selected date
    // then scroll to the selected month (the month that contain the selected date)
    setBeforeSize(loadSize);
    setAfterSize(0);
    const months = document.getElementsByClassName('rhs-cal-selected-month');
    if (months.length > 0) {
      months[0].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [panelParams.type]);

  const { title, Icon } = getCalendarSettings(t, panelParams);
  return (
    <BaseLayoutRightPanel
      title={title}
      titleIcon={<Icon width={16} height={16} />}
    >
      <BaseLayoutRightPanel.ScrollArea scrollableNodeProps={{ ref: scrollRef }}>
        <BaseLayoutRightPanel.Content sx={styles.content}>
          {dates.map((date) => (
            <Calendar
              key={date.toString()}
              date={date}
              type={panelParams.type}
              selectedDate={selectedDate}
            />
          ))}
        </BaseLayoutRightPanel.Content>
      </BaseLayoutRightPanel.ScrollArea>
    </BaseLayoutRightPanel>
  );
}
