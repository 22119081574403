/**
 * the returning user lands on the top level of the core page of previous sessions
 *
 * core pages:
 * 1. Discover
 * 2. Marketplace
 * 3. Practice for diff clubs
 * 4. Analytics for diff clubs
 */

import { useEffect } from 'react';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useAuth } from '@lib/web/apis';
import { call } from '@lib/web/utils';

const CORE_PATHNAMES_REGEX = /\/marketplace|\/club|\/link/i;

export default function useRecordCorePage() {
  const { asPath } = useRouter();
  const { isLogged } = useAuth();

  useEffect(() => {
    if (!isLogged) return;
    if (CORE_PATHNAMES_REGEX.test(asPath) || asPath.split('?')[0] === '/') {
      call(axios.post('/api/auth/track-page', { page: asPath }));
    }
  }, [isLogged, asPath]);
}
