import React, { useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import useThreadRender from '@app/web/src/widgets/ThreadPage/hooks/useThreadRender';
import { BaseLayoutRightPanel, Scrollbar, useBaseRightPanel } from '@front/ui';
import IaRenderContextProvider from '@lib/ia/src/core/IaRender/IaRenderProvider';
import { ChannelLayoutConfig } from '@lib/ia/src/layouts/ChannelLayout/types';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { apis, useAuth } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import {
  ThreadComposerContextValue,
  ThreadComposerProvider,
} from '@lib/web/thread/contexts/threadComposerContext';
import { useChannelInformation } from '@lib/web/thread/hooks/channel/useChannelInformation';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useSendMessageToChannel } from '@lib/web/thread/hooks/messages/useSendMessageToChannel';
import ThreadComposer from '@lib/web/thread/ThreadComposer';
import { ThreadChildChannelProperties } from '@lib/web/thread/ThreadProperties';
import { callWithToast } from '@lib/web/utils';

const styles = {
  panel: {
    '& .bottom-action': {
      p: 0,
    },
  },
  container: {
    display: 'grid',
    gridTemplateRows: '1fr max-content',
    height: '100%',
  },
  messageList: {
    position: 'relative',
    '& > [data-simplebar]': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    '& .simplebar-wrapper': {
      position: 'relative',
      height: '100%',
    },
    '& .simplebar-content-wrapper': {
      height: '100%',
    },
    '& .simplebar-content': {
      minHeight: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
};

export default function ThreadCreatorFromMessagePanel() {
  const {
    getRightParams,
    openRightPanel,
    rightPanelHistory,
    rightPanelOpened,
  } = useBaseRightPanel<GlobalPanelParams>();
  const { channel, message } = getRightParams(
    GlobalPanelKeys.GlobalThreadCreatorFromMessagePanel
  );
  const { chatClient } = useThread();
  const { getChannelInformation } = useChannelInformation();
  const [text, setText] = useState('');
  const { sendMessageToChannel } = useSendMessageToChannel();

  const { isBlocking, blockerTip } = useAuth();

  const { channelMemberIds } = useMemo(
    () => getChannelInformation(channel),
    [channel, getChannelInformation]
  );

  const renders = useThreadRender();

  const config = useMemo(
    () => [
      {
        layout: 'channel-layout',
        items: [
          {
            type: 'customize',
            value: 'messageItem',
            metadata: {
              channel,
              message,
              messages: [message],
            },
          },
        ],
      } as ChannelLayoutConfig,
    ],
    [channel, message]
  );

  const handleSubmit = useCallback<
    (props: {
      contextValue: ThreadComposerContextValue;
      blocks: ComposerBlock[];
    }) => Promise<void>
  >(
    async ({ contextValue: { aiActionState }, blocks }) => {
      if (!chatClient) return;

      setText('');

      const [resp] = await callWithToast(
        apis.thread.openChildChannel({ messageId: message.id }),
        { showLoading: false }
      );

      if (!resp) return;

      const childChannelId = resp.data.channelId;
      const childChannel = chatClient.channel(channel.type, childChannelId);
      await childChannel.watch();

      await sendMessageToChannel({
        channel: childChannel,
        channelMemberIds: channelMemberIds,
        text,
        blocks,
        agentId: aiActionState.selectedAgentId,
      });

      void childChannel.markRead();

      rightPanelHistory.back();
      openRightPanel(GlobalPanelKeys.GlobalThreadChat, {
        channelCid: `${channel.type}:${childChannelId}`,
      });
    },
    [
      channel.type,
      channelMemberIds,
      chatClient,
      message.id,
      openRightPanel,
      rightPanelHistory,
      sendMessageToChannel,
      text,
    ]
  );

  return (
    <ThreadComposerProvider
      text={text}
      setText={setText}
      sendPubliclyCheckboxEnable={true}
      sendPubliclyDefault={channel.type === 'public'}
      sendPubliclyDisabled={true}
      threadMemberIds={channelMemberIds}
    >
      {(contextValue) => (
        <BaseLayoutRightPanel
          resizable
          sx={styles.panel}
          actionComponent={
            rightPanelOpened && (
              <ThreadComposer
                autoFocus
                disabled={isBlocking}
                disabledTip={blockerTip}
                onChange={setText}
                onSubmit={({ blocks }) => {
                  void handleSubmit({
                    contextValue,
                    blocks,
                  });
                }}
              />
            )
          }
        >
          <ThreadChildChannelProperties
            parentChannel={channel}
            mode="expandableBrief"
          />

          <Box sx={styles.container}>
            <Box sx={styles.messageList} className="thread-chat-message-list">
              <Scrollbar>
                <IaRenderContextProvider value={renders}>
                  <IaLayouts layouts={config} />
                </IaRenderContextProvider>
              </Scrollbar>
            </Box>
          </Box>
        </BaseLayoutRightPanel>
      )}
    </ThreadComposerProvider>
  );
}
