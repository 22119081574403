import { useTranslation } from 'react-i18next';
import { Box, Switch, Theme, Typography, useMediaQuery } from '@mui/material';
import { OtherIncognito as OtherIncognitoIcon } from '@front/icon';
import { SimpleTooltip } from '@front/ui';

import { Placement } from '../CreateQuizForm';
import useCreateQuizAction from '../hooks/useCreateQuizAction';
import useCreateQuizState from '../hooks/useCreateQuizState';
import useStartQuizBlocker from '../hooks/useStartQuizBlocker';

import { StartQuizBlockerType } from './StartQuizPaidFutureBlocker';

const styles = {
  root: (placement: Placement) => ({
    px: {
      md: placement === 'center' ? '20px' : 0,
    },
    gap: 2,
    display: 'flex',
    alignItems: 'center',
    flexDirection: {
      md: placement === 'center' ? 'row-reverse' : 'row',
    },
    justifyContent: {
      xs: 'space-between',
      md: placement === 'center' ? 'flex-end' : 'space-between',
    },
    width: {
      xs: '100%',
      md: placement === 'center' ? 'unset' : '100%',
    },
  }),
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '2.5px',
  },
  switch: {
    position: 'relative',
    display: 'flex',
  },
  blockerOverlay: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

type IncognitoModeSwitchProps = {
  placement: Placement;
};

export default function IncognitoModeSwitch({
  placement,
}: IncognitoModeSwitchProps) {
  const { t } = useTranslation('quiz');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { values } = useCreateQuizState();
  const { updateQuizValues } = useCreateQuizAction();
  const { showBlocker, isFeatureBlocked, isLoading } =
    useStartQuizBlocker(placement);

  const toggleIncognito = () => {
    updateQuizValues({
      isIncognito: !values.isIncognito,
    });
  };

  const content = (
    <Box sx={styles.root(placement)}>
      <Box sx={styles.title}>
        <OtherIncognitoIcon width={16} height={16} />
        <Typography variant="body2">
          {values.isIncognito
            ? t('Activities hidden from followers')
            : t('Incognito Mode')}
        </Typography>
      </Box>
      <Box sx={styles.switch}>
        <Switch
          checked={!!values.isIncognito}
          onClick={toggleIncognito}
          disabled={isLoading}
        />
        {isFeatureBlocked && (
          <Box
            onClick={() => showBlocker(StartQuizBlockerType.IncognitoMode)}
            sx={styles.blockerOverlay}
          />
        )}
      </Box>
    </Box>
  );

  return (
    <>
      {mdUp && !values.isIncognito ? (
        <SimpleTooltip title={t('Hide activities from followers')} followCursor>
          {content}
        </SimpleTooltip>
      ) : (
        content
      )}
    </>
  );
}
