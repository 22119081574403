import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import AuthGuard from '@app/web/src/components/AuthGuard';
import { testIdConfig } from '@front/config';
import { MainChallenge as MainChallengeIcon } from '@front/icon';
import { Button, toast } from '@front/ui';
import { useClubSlug } from '@lib/web/hooks';

import useCreateQuizState from '../../../PracticePage/CreateQuizForm/hooks/useCreateQuizState';
import useStartNewQuiz from '../../../PracticePage/CreateQuizForm/hooks/useStartNewQuiz';

const styles = {
  button: {
    width: '100%',
  },
};

const CreateChallengeButton = () => {
  const { t } = useTranslation('quiz');
  const router = useRouter();
  const slug = useClubSlug();
  const [isLoading, setIsLoading] = useState(false);
  const createChallenge = useStartNewQuiz();
  const {
    values: { challengers },
  } = useCreateQuizState();
  const disabled = challengers.length === 0;
  const { availableQuestionCount } = useCreateQuizState();

  const handleClick = async () => {
    setIsLoading(true);

    const challengeUrl = await createChallenge({ isCreateChallenge: true });

    if (challengeUrl === null) {
      setIsLoading(false);
      toast.error('Something went, please try again!', {
        anchorEl: document.querySelector(
          `[data-testid="${testIdConfig.startChallenge}"]`
        ) as Element,
      });
      return;
    }

    if (challengeUrl === '') {
      setIsLoading(false);
      return;
    }

    router.push(challengeUrl);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [router]);
  return (
    <AuthGuard
      additionalBlockerTip={
        availableQuestionCount === 0
          ? t('practice.adjust.question', { count: 0 })
          : undefined
      }
    >
      <Button
        sx={styles.button}
        data-testid={testIdConfig.startChallenge}
        disabled={!slug || disabled}
        loading={isLoading}
        prefixIcon={<MainChallengeIcon />}
        onClick={handleClick}
      >
        {t('Create Challenge')}
      </Button>
    </AuthGuard>
  );
};

export default CreateChallengeButton;
