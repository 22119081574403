import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useSort } from '@app/web/src/hooks/utils/useSort';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import {
  ActionClear as ActionClearIcon,
  OtherDelete as OtherDeleteIcon,
  OtherSort as OtherSortIcon,
  TestAdd as TestAddIcon,
} from '@front/icon';
import {
  BaseLayoutRightPanel,
  getPropertyIcon,
  TipButton,
  useBaseLayout,
} from '@front/ui';

import FilterPanelContent from './components/SortPanelContent';

const styles = {
  toolbarButton: {
    marginLeft: 'auto',
  },
  filterIcon: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    position: 'absolute',
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    bgcolor: 'error.dark',
    top: '-3px',
    right: '-3px',
  },
};

function isSortSummaryPage(page?: string) {
  return !page || page === 'sortSummary';
}

export default function SortPanel() {
  const { t } = useTranslation();
  const { removeCriteria, resetSort, hasSort } = useSort();
  const { openRightPanel, getRightParams } = useBaseLayout<GlobalPanelParams>();

  const { page, selectedCriteria } = getRightParams(GlobalPanelKeys.GlobalSort);

  const handleDeleteClick = () => {
    if (selectedCriteria && selectedCriteria.id) {
      removeCriteria(selectedCriteria.id);
    }
    openRightPanel(GlobalPanelKeys.GlobalSort, { page: 'sortSummary' });
  };

  const handleResetClick = () => {
    resetSort();
  };

  let title;
  let titleIcon;
  if (isSortSummaryPage(page)) {
    title = t('Sort');
    titleIcon = (
      <Box sx={styles.filterIcon}>
        <OtherSortIcon width={16} height={16} />
        {hasSort && <Box component="span" sx={styles.dot} />}
      </Box>
    );
  } else if (page === 'fieldList') {
    title = t('Add Sort');
    titleIcon = <TestAddIcon width={16} height={16} />;
  } else if (page === 'criteriaDetail') {
    title = selectedCriteria?.field.label;
    titleIcon = getPropertyIcon(selectedCriteria?.field.type, 18);
  }

  let toolComponent;
  if (page === 'criteriaDetail') {
    toolComponent = (
      <TipButton
        title={t('toolbar.Delete sort')}
        sx={styles.toolbarButton}
        onClick={handleDeleteClick}
      >
        <OtherDeleteIcon />
      </TipButton>
    );
  } else if (isSortSummaryPage(page) && hasSort) {
    toolComponent = (
      <TipButton
        title={t('toolbar.Clear all')}
        sx={styles.toolbarButton}
        onClick={handleResetClick}
      >
        <ActionClearIcon />
      </TipButton>
    );
  }

  return (
    <BaseLayoutRightPanel
      titleIcon={titleIcon}
      title={title}
      toolComponent={toolComponent}
    >
      <FilterPanelContent />
    </BaseLayoutRightPanel>
  );
}
