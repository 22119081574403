import { ThreadViewType } from '@lib/web/apis';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import { cloneDeep, isEmpty } from 'lodash';
import { ChannelFilters } from 'stream-chat';

import {
  THREAD_CHANNEL_FILTER_MAP,
  THREAD_CHANNEL_SORT_MAP,
  THREAD_MESSAGE_FILTER_MAP,
  THREAD_MESSAGE_SORT_MAP,
} from '../../config/thread';
import {
  ThreadChannelFilter,
  ThreadMessageFilter,
  ThreadSort,
} from '../../types/threadFilterSort';
import {
  transformThreadFilter,
  transformThreadSort,
} from '../../utils/threadFilterSort';

import { useSearchFilterSort } from './useSearchFilterSort';

const getCombinedFilter = ({
  view,
  channelFilters,
  extraFilter,
}: {
  view: GetThreadViewRes;
  channelFilters: ChannelFilters;
  extraFilter?: ThreadChannelFilter;
}): ChannelFilters => {
  if (!isEmpty(extraFilter)) {
    if (view.type === ThreadViewType.Location) {
      const filter = cloneDeep(channelFilters);
      if (filter.$and) {
        filter.$and.push({ ...extraFilter });
      }
      return filter;
    }

    return { ...channelFilters, ...extraFilter };
  }

  return { ...channelFilters };
};

type UseChannelThreadSortFilterReturn = {
  key: string;
  sort: ThreadSort;
  resetSort: () => void;
  filters: ChannelFilters;
  resetFilter: () => void;
};

type UseMessageThreadSortFilterReturn = {
  sort: ThreadSort;
  resetSort: () => void;
  filters: ThreadMessageFilter;
  resetFilter: () => void;
};

type UseThreadSortFilterReturn = {
  useChannelSortFilter: (
    view: GetThreadViewRes
  ) => UseChannelThreadSortFilterReturn;
  useMessageSortFilter: () => UseMessageThreadSortFilterReturn;
};

const useThreadSortFilter = (scope?: string): UseThreadSortFilterReturn => {
  const useChannelSortFilter = (
    view: GetThreadViewRes
  ): UseChannelThreadSortFilterReturn => {
    const {
      sort: searchSort,
      resetSort,
      filter: searchFilter,
      resetFilter,
    } = useSearchFilterSort('threadChannel', { scope });

    const { streamSort: searchStreamSort } = transformThreadSort(
      THREAD_CHANNEL_SORT_MAP,
      searchSort
    );
    const { streamFilter: searchStreamFilter } = transformThreadFilter(
      THREAD_CHANNEL_FILTER_MAP,
      searchFilter
    );

    const sort = searchStreamSort;

    const { channelFilters } = useThreadViewDetail(view);
    const filters = getCombinedFilter({
      view,
      channelFilters,
      extraFilter: searchStreamFilter as ThreadChannelFilter,
    });

    const key = JSON.stringify({
      id: view.id,
      filters,
      sort,
    });

    return { key, sort, resetSort, filters, resetFilter };
  };

  const useMessageSortFilter = (): UseMessageThreadSortFilterReturn => {
    const {
      sort: searchSort,
      resetSort,
      filter: searchFilter,
      resetFilter,
    } = useSearchFilterSort('threadMessage', { scope });

    const { streamSort: searchStreamSort } = transformThreadSort(
      THREAD_MESSAGE_SORT_MAP,
      searchSort
    );
    const { streamFilter: searchStreamFilter } = transformThreadFilter(
      THREAD_MESSAGE_FILTER_MAP,
      searchFilter
    );

    return {
      sort: searchStreamSort,
      resetSort,
      filters: searchStreamFilter as ThreadMessageFilter,
      resetFilter,
    };
  };

  return {
    useChannelSortFilter,
    useMessageSortFilter,
  };
};

export default useThreadSortFilter;
