import { useCallback, useEffect, useState } from 'react';
import { apis } from '@lib/web/apis';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import { useClubThreadViews } from '@lib/web/thread/hooks/views/useClubThreadViews';
import { call } from '@lib/web/utils';
import { debounce } from 'lodash';

const asyncViewName = debounce(
  async (payload: SetThreadViewReq, onSuccess: () => void) => {
    const [res] = await call(apis.thread.setThreadView(payload));
    if (res) {
      onSuccess();
    }
  },
  300
);

type UseThreadViewRenameProps = {
  view: GetThreadViewRes;
  clubId?: string;
};

type UseThreadViewRenameReturn = {
  viewName: string;
  handleChangeViewName: (
    ev: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
};

const useThreadViewRename = ({
  view,
  clubId,
}: UseThreadViewRenameProps): UseThreadViewRenameReturn => {
  const { viewName } = useThreadViewDetail(view);
  const [currViewName, setCurrViewName] = useState(viewName);

  const { reloadViews } = useClubThreadViews({ clubId });

  const handleChangeViewName = useCallback(
    async (ev: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      const name = ev.target.value;
      const payload: SetThreadViewReq = {
        type: view.type,
        threadViewUserId: view.threadViewUsers[0].id,
        name,
      };
      setCurrViewName(name);
      asyncViewName(payload, reloadViews);
    },
    [reloadViews, view.threadViewUsers, view.type]
  );

  useEffect(() => {
    setCurrViewName(viewName);
  }, [viewName]);

  return { viewName: currViewName, handleChangeViewName };
};

export default useThreadViewRename;
