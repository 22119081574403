import { useLeagueGroupList } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';

type Options = {
  disabled?: boolean;
};

export default function useLeagueRankingList({ disabled }: Options = {}) {
  const clubSlug = useClubSlug();

  return useLeagueGroupList({
    clubSlug: !disabled ? clubSlug : null,
    isGetFollowInfo: true,
  });
}
