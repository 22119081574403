import { useEffect } from 'react';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useBaseRightPanel } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaRenderContextProvider from '@lib/ia/src/core/IaRender/IaRenderProvider';
import { useAuth } from '@lib/web/apis';
import { useCopyToClipboard } from '@lib/web/hooks';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useSearchMessages } from '@lib/web/thread/hooks/messages/useSearchMessages';
import ThreadChat from '@lib/web/thread/ThreadChat';
import { StreamChatGenerics, ThreadUser } from '@lib/web/thread/types';
import { getMessagePlainText } from '@lib/web/thread/utils/messageUtils';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';
import { v4 } from 'uuid';

import useThreadSortFilter from '../../hooks/utils/useThreadSortFilter';
import useThreadRender from '../../widgets/ThreadPage/hooks/useThreadRender';

export type WebThreadChatProps = {
  channelCid: string;
  scope?: string;
};

export default function WebThreadChat({
  channelCid,
  scope,
}: WebThreadChatProps) {
  const { resetThreadMessageSortFilter } = useThread();
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const { blockerTip } = useAuth();

  const [, copy] = useCopyToClipboard();

  const availableActions = {
    viewUser: {
      action: ({ userId }: { userId: string }) => {
        openRightPanel(GlobalPanelKeys.GlobalProfile, {
          userId: userId,
        });
      },
    },
    viewAgent: {
      action: ({ userId }: { userId: string }) => {
        openRightPanel(GlobalPanelKeys.GlobalAgentProfile, {
          agentId: userId,
        });
      },
    },
    viewThread: {
      action: (
        _id: string,
        metadata: { childChannelCid: string | undefined }
      ) => {
        if (!metadata.childChannelCid) return;

        openRightPanel(GlobalPanelKeys.GlobalThreadChat, {
          channelCid: metadata.childChannelCid,
        });
      },
    },
    openThread: {
      action: (
        _id: string,
        metadata: {
          channel: Channel;
          message: StreamMessage;
          childChannelCid: string | undefined;
        }
      ) => {
        if (metadata.childChannelCid) {
          openRightPanel(GlobalPanelKeys.GlobalThreadChat, {
            channelCid: metadata.childChannelCid,
          });
          return;
        }

        const tmpId = v4();
        openRightPanel(GlobalPanelKeys.GlobalThreadCreatorFromMessagePanel, {
          tmpId,
          channel: metadata.channel,
          message: metadata.message,
        });
      },
    },
    openUserOrAgentProfile: {
      action: ({ user }: { user?: ThreadUser }): void => {
        if (user?.type === 'agent') {
          openRightPanel(GlobalPanelKeys.GlobalAgentProfile, {
            agentId: user.agentId ?? '',
          });
        }
        if (user?.type === 'user') {
          openRightPanel(GlobalPanelKeys.GlobalProfile, {
            userId: user.userId,
          });
        }
      },
    },
    copyMessage: {
      action: (
        _id: string,
        metadata: {
          message: StreamMessage<StreamChatGenerics>;
          handler: () => void;
        }
      ) => {
        const { message, handler } = metadata;
        void copy(getMessagePlainText(message));
        handler();
      },
    },
  };

  const renders = useThreadRender();

  const { useMessageSortFilter } = useThreadSortFilter(scope);
  const { sort, resetSort, filters, resetFilter } = useMessageSortFilter();

  const { messages } = useSearchMessages({
    channelCid: channelCid,
    messageSort: sort,
    messageFilter: filters,
  });

  const availableEventNames = ['openThread', 'copyMessage'];

  useEffect(() => {
    resetThreadMessageSortFilter.current = (): void => {
      resetSort();
      resetFilter();
    };
  }, [resetFilter, resetSort, resetThreadMessageSortFilter]);

  return (
    <IaRenderContextProvider value={renders}>
      <IaActionContextProvider availableActions={availableActions}>
        <ThreadChat
          channelCid={channelCid}
          tooltip={blockerTip}
          searchedMessages={messages}
          availableEventNames={availableEventNames}
        />
      </IaActionContextProvider>
    </IaRenderContextProvider>
  );
}
