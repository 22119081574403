import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { MainChallenge as MainChallengeIcon } from '@front/icon';
import {
  EmphasizeButton,
  EmphasizeButtonProps,
  IconButton,
  IconButtonProps,
  useBaseRightPanel,
} from '@front/ui';
import { apis } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';
import { call, getIndicators } from '@lib/web/utils';

import { ChallengerIconListLayoutItemObj } from '../../widgets/PracticePage/CreateQuizForm/contexts/type';
import useCreateQuizAction from '../../widgets/PracticePage/CreateQuizForm/hooks/useCreateQuizAction';

type ChallengeButtonProps = {
  userId?: string;
} & (
  | ({
      showIconOnly?: false;
    } & Omit<EmphasizeButtonProps, 'children'>)
  | ({
      showIconOnly: true;
    } & Omit<IconButtonProps, 'children'>)
);

export default function ChallengeButton({
  userId,
  onClick,
  ...rest
}: ChallengeButtonProps) {
  const { t } = useTranslation();
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const clubSlug = useClubSlug();
  const { updateQuizValues, setChallengersLoading } = useCreateQuizAction();

  if (!userId || !clubSlug) return null;

  const handleChallengeClick = async (e: MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);

    openRightPanel(GlobalPanelKeys.GlobalStartQuiz, {
      isChallengeMode: true,
    });
    setChallengersLoading(true);

    const [res] = await call(apis.member.getSocialProfile(userId));
    const socialData = res?.data;
    if (!socialData) return;

    const challenger: ChallengerIconListLayoutItemObj = {
      id: socialData.userId,
      title: socialData.fullName || socialData.userName,
      description: `@${socialData.userName}`,
      avatarUrl: socialData.avatarUrl || socialData.photoUrl,
      indicators: getIndicators(socialData.indicator),
      actionMap: {
        select: {
          value: 'selectChanged',
        },
        hover: {
          value: 'userHovered',
        },
      },
      metadata: {
        userId: socialData.userId,
        isFollowing: true,
        disabledUpdate: true,
      },
    };
    updateQuizValues({
      challengers: [challenger],
    });
    setChallengersLoading(false);
  };

  if (rest.showIconOnly) {
    return (
      <IconButton onClick={handleChallengeClick} {...rest}>
        <MainChallengeIcon />
      </IconButton>
    );
  }

  return (
    <EmphasizeButton
      prefixIcon={<MainChallengeIcon />}
      onClick={handleChallengeClick}
      {...rest}
    >
      {t('button.Challenge')}
    </EmphasizeButton>
  );
}
