import { alpha, Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { Icon } from '@front/ui';

import useRankingPlacement from '../hooks/useRankingPlacement';

const styles = {
  root: {
    display: 'grid',
    gap: 2,
    px: {
      xs: '20px',
      md: '12px',
    },
    py: 1,
  },
  cell: {
    display: 'flex',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
};

const getGridTemplateColumns = ({
  hideStreak = false,
  hideCta = false,
  ctaShowIconOnly = false,
}: {
  hideStreak?: boolean;
  hideCta?: boolean;
  ctaShowIconOnly?: boolean;
}) => {
  const streakCol = !hideStreak ? '32px' : '';
  const ctaCol = !hideCta ? (ctaShowIconOnly ? '28px' : '155px') : '';
  return `1fr 32px 32px ${streakCol} ${ctaCol}`;
};

type CellType = {
  icon?: string;
  content?: string;
};

function getCells({
  hideStreak = false,
  hideCta = false,
}: {
  hideStreak?: boolean;
  hideCta?: boolean;
}) {
  return [
    { content: 'Name' },
    { icon: 'TestTrophy' },
    { icon: 'TestScoreTally' },
    !hideStreak && { icon: 'EmojiFire' },
    !hideCta && {},
  ].filter((cell) => cell !== false) as CellType[];
}

type RankingListHeaderProps = {
  hideStreak?: boolean;
};

export default function RankingListHeader({
  hideStreak,
}: RankingListHeaderProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const placement = useRankingPlacement();

  const ctaShowIconOnly = !mdUp;
  const hideCta = placement === 'rhs';
  const gridTemplateColumns = getGridTemplateColumns({
    hideStreak,
    hideCta,
    ctaShowIconOnly,
  });
  const cells = getCells({ hideStreak, hideCta });

  return (
    <Box sx={[styles.root, { gridTemplateColumns }]}>
      {cells.map((cell, index) => (
        <Box key={index} sx={styles.cell}>
          {cell.content && (
            <Typography variant="caption">{cell.content}</Typography>
          )}
          {cell.icon && <Icon name={cell.icon} width={16} height={16} />}
        </Box>
      ))}
    </Box>
  );
}
