import { MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useSWRConfig } from 'swr';
import {
  OtherAddFriend as OtherAddFriendIcon,
  OtherUnfollow as OtherUnfollowIcon,
} from '@front/icon';
import {
  EmphasizeButton,
  EmphasizeButtonProps,
  IconButton,
  IconButtonProps,
} from '@front/ui';
import { apis, getUserProfileSocialKey } from '@lib/web/apis';
import { call } from '@lib/web/utils';

import useFriendRankingList from '../../widgets/CommonPanels/RankingsPanel/hooks/useFriendRankingList';
import useLeagueRankingList from '../../widgets/CommonPanels/RankingsPanel/hooks/useLeagueRankingList';

const styles = {
  button: {
    width: '100%',
    minWidth: 0,
    flex: 1,
    '& .em-button-base': {
      px: 'unset',
    },
  },
};

const CLUB_RANKING_REGEX = /^\/club\/([a-zA-Z0-9-]{1,})\/rankings/;
function useReloadAssociateData(userId?: string) {
  const { mutate } = useSWRConfig();
  const router = useRouter();
  const inRankingPage = CLUB_RANKING_REGEX.test(router.asPath);
  const { mutate: followerMutate } = useFriendRankingList({
    type: 'follower',
    disabled: !inRankingPage,
  });
  const { mutate: followingMutate } = useFriendRankingList({
    type: 'following',
    disabled: !inRankingPage,
  });
  const { mutate: leagueRankingMutate } = useLeagueRankingList({
    disabled: !inRankingPage,
  });

  return useCallback(async () => {
    // to refetch profile social by userId
    mutate(getUserProfileSocialKey(userId));
    if (inRankingPage) {
      // if in the Ranking page => should refetch lists to get newest following/follower status
      followerMutate();
      followingMutate();
      leagueRankingMutate();
    }
  }, [
    mutate,
    userId,
    inRankingPage,
    followerMutate,
    followingMutate,
    leagueRankingMutate,
  ]);
}

type FollowButtonProps = {
  userId?: string;
  isFollowing?: boolean;
  isFollower?: boolean;
  onChange?: () => void;
} & (
  | ({
      showIconOnly?: false;
    } & Omit<EmphasizeButtonProps, 'children'>)
  | ({
      showIconOnly: true;
    } & Omit<IconButtonProps, 'children'>)
);

export default function FollowButton({
  userId,
  isFollowing,
  isFollower,
  onChange,
  onClick,
  loading,
  ...rest
}: FollowButtonProps) {
  const { t } = useTranslation();
  const [toggleLoading, setToggleLoading] = useState(false);
  const reloadAssociateData = useReloadAssociateData(userId);

  if (!userId) return null;

  const toggleFollow = async (e: MouseEvent<HTMLButtonElement>) => {
    onClick?.(e);
    setToggleLoading(true);
    const api = isFollowing
      ? () => apis.member.unfollowUser(userId)
      : () => apis.member.followUser(userId);

    await call(api);
    reloadAssociateData();
    setToggleLoading(false);
    onChange?.();
  };

  const isLoading = toggleLoading || loading;

  if (rest.showIconOnly) {
    return (
      <>
        {!isFollower && (
          <IconButton
            color="default"
            variant="outlined"
            onClick={toggleFollow}
            loading={isLoading}
            {...rest}
          >
            {isFollowing ? <OtherUnfollowIcon /> : <OtherAddFriendIcon />}
          </IconButton>
        )}
        {isFollower && (
          <IconButton
            color="default"
            variant="outlined"
            onClick={toggleFollow}
            loading={isLoading}
            {...rest}
          >
            {isFollowing ? <OtherUnfollowIcon /> : <OtherAddFriendIcon />}
          </IconButton>
        )}
      </>
    );
  }

  return (
    <>
      {!isFollower && (
        <EmphasizeButton
          sx={styles.button}
          prefixIcon={
            isFollowing ? <OtherUnfollowIcon /> : <OtherAddFriendIcon />
          }
          color="default"
          variant="outlined"
          onClick={toggleFollow}
          loading={isLoading}
          {...rest}
        >
          {isFollowing ? t('button.Unfollow') : t('button.Follow')}
        </EmphasizeButton>
      )}
      {isFollower && (
        <EmphasizeButton
          sx={styles.button}
          prefixIcon={
            isFollowing ? <OtherUnfollowIcon /> : <OtherAddFriendIcon />
          }
          color="default"
          variant="outlined"
          onClick={toggleFollow}
          loading={isLoading}
          {...rest}
        >
          {isFollowing ? t('button.Unfollow') : t('button.Follow Back')}
        </EmphasizeButton>
      )}
    </>
  );
}
