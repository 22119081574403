import { useRef } from 'react';
import { Box } from '@mui/material';
import { CHANNEL_PANEL_SORT_FILTER_SCOPE } from '@app/web/src/config/thread';
import { useAppSelector } from '@app/web/src/hooks/redux';
import {
  EditClubGroupPanelKeys,
  EditClubGroupPanelParams,
} from '@app/web/src/types/panel';
import ThreadViewChannelLayout from '@app/web/src/widgets/ThreadPage/components/ThreadViewChannelLayout';
import ThreadViewTableLayout from '@app/web/src/widgets/ThreadPage/components/ThreadViewTableLayout';
import { BaseLayoutRightPanel, useBaseRightPanel } from '@front/ui';
import { useLocationThreadViews } from '@lib/web/thread/hooks/views/useLocationThreadViews';

import ThreadViewPanelToolbar from '../../ThreadPage/ThreadViewPanelToolbar';
import FilterResponsivePanel from '../FilterResponsivePanel';
import SortResponsivePanel from '../SortResponsivePanel';

const styles = {
  panel: {
    '& .bottom-action': {
      p: 0,
    },
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
};

function ThreadViewPanelContent() {
  const { view } = useLocationThreadViews();
  const layoutSetting = useAppSelector((st) => st.iaLayout);

  if (layoutSetting.type === 'channel') {
    return (
      <ThreadViewChannelLayout
        view={view}
        scope={CHANNEL_PANEL_SORT_FILTER_SCOPE}
      />
    );
  }

  if (layoutSetting.type === 'table') {
    return (
      <ThreadViewTableLayout
        view={view}
        scope={CHANNEL_PANEL_SORT_FILTER_SCOPE}
      />
    );
  }

  return (
    <ThreadViewTableLayout
      view={view}
      scope={CHANNEL_PANEL_SORT_FILTER_SCOPE}
    />
  );
}

export default function ThreadViewPanel() {
  const filterButtonRef = useRef<HTMLButtonElement>(null);
  const sortButtonRef = useRef<HTMLButtonElement>(null);

  const { getRightParams, setRightParams } =
    useBaseRightPanel<EditClubGroupPanelParams>();
  const { filterOrSortOpened } = getRightParams(
    EditClubGroupPanelKeys.EditClubGroupAddClubs
  );

  return (
    <>
      <BaseLayoutRightPanel
        resizable
        sx={styles.panel}
        toolComponent={
          <ThreadViewPanelToolbar
            filterButtonRef={filterButtonRef}
            sortButtonRef={sortButtonRef}
            scope={CHANNEL_PANEL_SORT_FILTER_SCOPE}
          />
        }
      >
        <Box sx={styles.main}>
          <ThreadViewPanelContent />
        </Box>
      </BaseLayoutRightPanel>

      <SortResponsivePanel
        scope={CHANNEL_PANEL_SORT_FILTER_SCOPE}
        anchorEl={sortButtonRef.current}
        open={filterOrSortOpened === 'sort'}
        onClose={() => setRightParams({ filterOrSortOpened: null })}
      />
      <FilterResponsivePanel
        scope={CHANNEL_PANEL_SORT_FILTER_SCOPE}
        anchorEl={filterButtonRef.current}
        open={filterOrSortOpened === 'filter'}
        onClose={() => setRightParams({ filterOrSortOpened: null })}
      />
    </>
  );
}
