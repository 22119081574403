import { ChangeEvent } from 'react';
import { Box, Theme } from '@mui/material';
import { ThreadViewType } from '@lib/web/apis';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import ThreadMemberTitle from '@lib/web/thread/ThreadMemberTitle';
import { ThreadUser } from '@lib/web/thread/types';
import ThreadEmojiPicker from '@lib/web/thread/ui/ThreadEmojiPicker';
import ThreadViewIcon from '@lib/web/thread/ui/ThreadViewIcon';
import { ResponsivePageTitle } from '@lib/web/ui';

import useThreadViewChangeEmoji from '../../hooks/useThreadViewChangeEmoji';
import useThreadViewRename from '../../hooks/useThreadViewRename';

const styles = {
  scroll: {
    '& .simplebar-content': {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },

  // same as ui-core typography
  h1: {
    p: 0,
    display: 'block',
    color: 'text.primary',
    width: '100%',
    fontWeight: 'bold',
    fontSize: { xs: 30, md: 24 },
    lineHeight: { xs: '32px', md: '39px' },
    letterSpacing: -0.15,

    fontFamily: (theme: Theme) => theme.typography.body1.fontFamily,

    '&:disabled': {
      color: 'text.primary',
    },
  },

  input: {
    fontSize: { md: 30 },
    border: 'none',
    bgcolor: 'transparent',
    cursor: 'text',
  },
};

type TitleTextProps = {
  viewNameMembers: NonNullable<ThreadUser | null>[] | null;
  viewType: ThreadViewType;
  viewName: string;
  handleChangeViewName: (ev: ChangeEvent<HTMLInputElement>) => Promise<void>;
};

const TitleText = ({
  viewNameMembers,
  viewType,
  viewName,
  handleChangeViewName,
}: TitleTextProps) => {
  const { threadTitleTextRef } = useThread();

  if (viewNameMembers?.length) {
    return (
      <ThreadMemberTitle
        members={viewNameMembers}
        typographyVariant="appH1"
        badgeSize={24}
      />
    );
  }

  if (viewType === ThreadViewType.Club) {
    return (
      <Box
        ref={threadTitleTextRef}
        sx={[styles.h1, styles.input]}
        component="input"
        value={viewName || ''}
        placeholder="Untitled"
        onChange={handleChangeViewName}
      />
    );
  }

  return viewName;
};

type ThreadTitleProps = {
  view: GetThreadViewRes;
  clubId?: string;
};

const ThreadViewTitle = ({ view, clubId }: ThreadTitleProps) => {
  const { viewIcon, viewNameMembers, viewType } = useThreadViewDetail(view);

  const { emoji, handleOpenEmojiPanel } = useThreadViewChangeEmoji({
    view,
    clubId,
  });
  const { viewName, handleChangeViewName } = useThreadViewRename({
    view,
    clubId,
  });

  return (
    <ResponsivePageTitle
      icon={
        viewIcon?.type === 'emoji' ? (
          <ThreadEmojiPicker
            emoji={emoji}
            onEmojiClick={handleOpenEmojiPanel}
          />
        ) : (
          <ThreadViewIcon view={view} size="md" />
        )
      }
      titleText={
        <TitleText
          viewNameMembers={viewNameMembers}
          viewType={viewType as ThreadViewType}
          viewName={viewName}
          handleChangeViewName={handleChangeViewName}
        />
      }
    />
  );
};

export default ThreadViewTitle;
