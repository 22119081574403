import { useCallback, useRef } from 'react';
import { Box } from '@mui/material';
import useBottomScrolled from '@app/web/src/hooks/utils/useBottomScrolled';
import { Scrollbar, UserAvatar } from '@front/ui';
import { useUserFollow } from '@lib/web/apis';
import { getIndicators } from '@lib/web/utils';

const styles = {
  container: {
    height: '100%',
    position: 'relative',
  },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  item: {
    py: 1,
    px: '12px',
    cursor: 'pointer',
    '&:hover, &:active': {
      backgroundColor: 'rgba(255,255,255, 0.1)',
    },
  },
  avatar: {
    gap: 2,
  },
};

type UserListProps = {
  variant: 'follower' | 'following';
  userId: string;
  onClick: (targetId: string) => void;
};
type UserButtonProps = {
  data: GetFollowerRes;
  onClick: (targetId: string) => void;
};

function UserButton({ data, onClick }: UserButtonProps) {
  const display = data.displayName || data.userName;
  const avatar = data.nftAvatar || data.avatar;

  return (
    <Box onClick={() => onClick(data.userId)}>
      <UserAvatar
        sx={styles.avatar}
        src={avatar}
        title={display}
        subTitle={`@${data.userName}`}
        indicators={getIndicators(data.indicator)}
      />
    </Box>
  );
}
export default function UserList({ userId, variant, onClick }: UserListProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const disableLoadMoreRef = useRef<boolean>(false);
  const { dataset, isReachingEnd, isLoadingMore, setSize } = useUserFollow({
    type: variant,
    userId,
    isGetStatus: true,
  });

  disableLoadMoreRef.current = isReachingEnd || isLoadingMore;

  const loaded = !isLoadingMore;

  const handleScrollBottom = useCallback(() => {
    if (!disableLoadMoreRef.current) {
      setSize((s) => s + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  useBottomScrolled({
    scrollRef,
    onScrolledBottom: handleScrollBottom,
  });

  return (
    <Box sx={styles.container}>
      <Scrollbar sx={styles.scroll} scrollableNodeProps={{ ref: scrollRef }}>
        <Box sx={styles.content}>
          {dataset.map((d) => (
            <Box sx={styles.item} key={d.userId}>
              <UserButton
                key={d.userId}
                data={d}
                onClick={() => onClick(d.userId)}
              />
            </Box>
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );
}
