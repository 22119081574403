import { ReactNode } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import useSettings from '@app/web/src/hooks/utils/useSettings';
import createTheme from '@app/web/src/theme';

type ExamModeButtonProps = {
  title: string;
  icon: ReactNode;
  active: boolean;
  disabled?: boolean;
  onClick: () => void;
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    height: 140,
    cursor: 'pointer',
    transitionDuration: '0.3s',
  },
  content: {
    display: 'grid',
    justifyContent: 'center',
    gap: 1,
    '& svg': {
      width: 40,
      height: 40,
      margin: 'auto',
    },
  },
  clickable: {
    '&:hover': {
      bgcolor: 'alpha.lightA10',
      border: '4px solid',
    },
  },
  disabled: {
    opacity: 0.5,
  },
  active: {
    bgcolor: 'background.darker',
  },
};

const ExamModeButton = ({
  title,
  icon,
  active,
  disabled = false,
  onClick,
}: ExamModeButtonProps) => {
  const { settings } = useSettings();
  const reversedTheme = createTheme({
    ...settings,
    mode: active
      ? settings.theme === 'dark'
        ? 'light'
        : 'dark'
      : settings.theme,
  });

  return (
    <ThemeProvider theme={reversedTheme}>
      <Card
        sx={[
          styles.container,
          !active && !disabled && styles.clickable,
          disabled && styles.disabled,
          active && styles.active,
        ]}
        onClick={disabled ? undefined : onClick}
      >
        <CardContent sx={styles.content}>
          {icon}
          <Typography variant="subtitle2">{title}</Typography>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
};

export default ExamModeButton;
