/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { isEmpty } from 'lodash';
import {
  MessageFilters,
  MessageResponse,
  SearchMessageSort,
} from 'stream-chat';

import { useThread } from '../core/useThread';

export const useSearchMessages = ({
  channelCid,
  messageSort,
  messageFilter,
}: {
  channelCid?: string;
  messageSort: SearchMessageSort;
  messageFilter: MessageFilters;
}) => {
  const { chatClient } = useThread();
  const [messages, setMessages] =
    useState<MessageResponse<StreamChatGenerics>[]>();

  const fetchMessages = useCallback(async () => {
    if (
      !chatClient ||
      !channelCid ||
      (isEmpty(messageSort) && isEmpty(messageFilter))
    ) {
      setMessages(undefined);
      return;
    }

    const defaultFilter: MessageFilters = {
      type: { $in: ['ephemeral', 'error', 'regular', 'reply'] },
    };

    try {
      const { results } = await chatClient.search(
        {
          cid: {
            $eq: channelCid,
          },
        },
        isEmpty(messageFilter) ? defaultFilter : messageFilter,
        { sort: messageSort }
      );
      setMessages(
        results.map((result) => {
          return result.message as MessageResponse<StreamChatGenerics>;
        })
      );
    } catch (err) {
      setMessages(undefined);
      console.warn(err);
    }
  }, [
    channelCid,
    chatClient,
    JSON.stringify(messageFilter),
    JSON.stringify(messageSort),
  ]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  return { messages };
};
