import useCurrentUserId from '@app/web/src/hooks/utils/useCurrentUserId';
import { useUserFollow } from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';

type Options = {
  type: 'following' | 'follower';
  keyword?: string;
  disabled?: boolean;
};

export default function useFriendRankingList({
  type,
  keyword,
  disabled,
}: Options) {
  const clubSlug = useClubSlug();
  const userId = useCurrentUserId();

  return useUserFollow({
    type,
    userId: !disabled ? userId : undefined,
    keyword,
    isGetLeagueInfo: true,
    clubSlug,
    isGetStatus: true,
  });
}
