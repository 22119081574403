import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { LeagueBadge, NumberCard, useBaseRightPanel } from '@front/ui';

const styles = {
  card: { flex: 1, maxWidth: 255 },
  leagueBadge: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default function LeagueCard({
  leagueName,
}: {
  leagueName:
    | 'Alpha'
    | 'Centurion'
    | 'Commandus'
    | 'Cosmus'
    | 'Incendium'
    | 'Legatus'
    | 'Maximus'
    | 'Nocturnus'
    | 'Prima'
    | 'Spectus'
    | 'Locked';
}) {
  const { t } = useTranslation('notification');
  const { toggleRightPanel } = useBaseRightPanel<GlobalPanelParams>();

  return (
    <NumberCard
      width="100%"
      maxWidth={256}
      minHeight={160}
      title={t('Current League')}
      titleIcon="TestTrophy"
      sx={styles.card}
      onClick={() => toggleRightPanel(GlobalPanelKeys.GlobalRankings)}
    >
      <NumberCard.Value>
        <Box sx={styles.leagueBadge}>
          <LeagueBadge name={leagueName} size={64} />
        </Box>{' '}
      </NumberCard.Value>
      <NumberCard.Description>
        {t('## League', { name: leagueName })}
      </NumberCard.Description>
    </NumberCard>
  );
}
