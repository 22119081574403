import { PropsWithChildren, ReactNode } from 'react';
import { Box } from '@mui/material';
import { Scrollbar, ScrollbarProps } from '@front/ui';

const styles = {
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 48px)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    mt: 2,
  },
  scrollWrapper: {
    position: 'relative',
    flex: 1,
  },
  scroll: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
  },
  empty: {
    width: '100%',
  },
};

function Header({ children }: PropsWithChildren) {
  return <Box sx={styles.content}>{children}</Box>;
}

type EmptyStateProps = {
  visible?: boolean;
  children: ReactNode;
};

function EmptyState({ visible, children }: EmptyStateProps) {
  if (!visible) return null;
  return <Box sx={styles.empty}>{children}</Box>;
}

type ListProps = {
  visible?: boolean;
  scrollableNodeProps?: ScrollbarProps['scrollableNodeProps'];
  children: ReactNode;
};

function ListScrollbar({ visible, scrollableNodeProps, children }: ListProps) {
  if (!visible) return null;
  return (
    <Box sx={styles.scrollWrapper}>
      <Scrollbar sx={styles.scroll} scrollableNodeProps={scrollableNodeProps}>
        {children}
      </Scrollbar>
    </Box>
  );
}

export default function RankingTabArea({ children }: PropsWithChildren) {
  return <Box sx={styles.root}>{children}</Box>;
}

RankingTabArea.Header = Header;
RankingTabArea.EmptyState = EmptyState;
RankingTabArea.Scrollbar = ListScrollbar;
