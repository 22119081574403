import { useTranslation } from 'react-i18next';
import { Box, Chip, Typography } from '@mui/material';
import { TestTestInfinity as TestTestInfinityIcon } from '@front/icon';
import { SimpleTooltip } from '@front/ui';

const styles = {
  attemptChips: {
    display: 'grid',
  },
  chipsLabel: {
    py: '5.5px',
  },
  chips: {
    display: 'flex',
    gap: 1,
    '& .MuiChip-root': {
      width: '40px',
      height: '40px',
    },
    '& .MuiChip-label': {
      px: 0,
      display: 'flex',
      alignItems: 'center',
    },
  },
};

type ChallengeMaxAttemptSliderProps = {
  value?: number;
  onChange?: (value: number) => void;
};

export default function ChallengeMaxAttemptOptions({
  value = 1,
  onChange,
}: ChallengeMaxAttemptSliderProps) {
  const { t } = useTranslation('quiz');
  const handleChange = (val: number) => {
    onChange?.(val);
  };

  return (
    <Box sx={styles.attemptChips}>
      <Typography variant="caption" sx={styles.chipsLabel}>
        {t('Challenge Attempts')}
      </Typography>
      <SimpleTooltip
        title={t(
          'Challenge Attempts Tips',
          'Set the number of times a challenge can attempt this challenge.'
        )}
      >
        <Box sx={styles.chips}>
          <Chip
            variant={value === 1 ? 'filled' : 'outlined'}
            label="1"
            clickable
            onClick={() => handleChange(1)}
          />
          <Chip
            variant={value === 2 ? 'filled' : 'outlined'}
            label="2"
            clickable
            onClick={() => handleChange(2)}
          />
          <Chip
            variant={value === 3 ? 'filled' : 'outlined'}
            label="3"
            clickable
            onClick={() => handleChange(3)}
          />
          <Chip
            variant={value === -1 ? 'filled' : 'outlined'}
            label={<TestTestInfinityIcon width={16} height={16} />}
            clickable
            onClick={() => handleChange(-1)}
          />
        </Box>
      </SimpleTooltip>
    </Box>
  );
}
