import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { Theme } from '@mui/material';
import { alpha, useMediaQuery } from '@mui/system';
import {
  EmojiRocket as EmojiRocketIcon,
  ProfileUpgrade as ProfileUpgradeIcon,
} from '@front/icon';
import { EmphasizeButton, PopupActions, PopupOverlay } from '@front/ui';

import { Placement } from '../CreateQuizForm';
import useStartQuizBlocker from '../hooks/useStartQuizBlocker';

const styles = {
  overlay: (placement: Placement) => ({
    width: '100%',
    maxWidth: 796,
    '& .popup-overlay-title': {
      fontSize: placement === 'center' ? 30 : 24,
      lineHeight: placement === 'center' ? 1.3 : 1.5,
    },
    '& .popup-overlay-emoji': {
      width: placement === 'center' ? { xs: 140, md: 180 } : 44,
      height: placement === 'center' ? { xs: 140, md: 180 } : 55,
      '& svg': {
        width: placement === 'center' ? { xs: 88, md: 110 } : 44,
        height: placement === 'center' ? { xs: 160, md: 200 } : 80,
      },
    },
    '& .popup-overlay-content': {
      px: placement === 'center' ? { xs: '16px', md: '20px' } : '12px',
      py: 0,
    },
    '& .popup-overlay-action': {
      px: placement === 'center' ? { xs: '16px', md: '20px' } : '12px',
    },
  }),
  modal: {
    position: 'absolute',
    zIndex: 1,
    top: { xs: 48, md: 48 },
    bottom: 0,
    '& .MuiBackdrop-root': {
      position: 'absolute',
      background: (theme: Theme) => alpha(theme.palette.background.darker, 0.1),
    },
  },
  actions: {
    mt: '40px',
  },
  rhsActions: {
    flexDirection: { md: 'column-reverse' },
  },
  button: (placement: Placement) => ({
    width: placement === 'rhs' ? '100%' : 225,
    '& .em-button-base': {
      px: 1,
    },
  }),
};

/**
 * the enum values `i`, `d` will be used to show in the URL like: start?block=i
 */
export enum StartQuizBlockerType {
  IncognitoMode = 'i',
  DifficultySuperLevel = 'd',
}

type BlockerSettings = {
  title?: string;
  body?: string;
  closeText?: string;
  upgradeText?: string;
};

function useBlockerSettings(type?: StartQuizBlockerType): BlockerSettings {
  const { t } = useTranslation('quiz');
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  if (type === StartQuizBlockerType.IncognitoMode) {
    return {
      title: t('incognitoMode.blocker.title'),
      body: t('incognitoMode.blocker.body'),
      closeText: t('incognitoMode.blocker.cta.close'),
      upgradeText: t('incognitoMode.blocker.cta.upgrade'),
    };
  }
  if (type === StartQuizBlockerType.DifficultySuperLevel) {
    return {
      title: t('superDifficulty.blocker.title'),
      body: t('superDifficulty.blocker.body'),
      closeText: t('superDifficulty.blocker.cta.close'),
      upgradeText: t('superDifficulty.blocker.cta.upgrade', {
        context: mdDown ? 'mobile' : undefined,
      }),
    };
  }
  return {};
}

type StartQuizPaidFutureBlockerProps = {
  placement: Placement;
};

export default function StartQuizPaidFutureBlocker({
  placement,
}: StartQuizPaidFutureBlockerProps) {
  const { closeBlocker, blockerType, isFeatureBlocked } =
    useStartQuizBlocker(placement);
  const settings = useBlockerSettings(blockerType);

  if (!blockerType || !isFeatureBlocked) return null;

  return (
    <PopupOverlay
      open
      title={settings.title}
      body={settings.body}
      emojiComponent={
        <EmojiRocketIcon
          width={placement === 'rhs' ? 44 : undefined}
          height={placement === 'rhs' ? 80 : undefined}
        />
      }
      modalProps={{
        sx: styles.modal,
        container: document.querySelector(
          placement === 'rhs'
            ? '[data-testid="base-layout-right-panel"]'
            : '[data-testid="base-layout-main"]'
        ) as Element,
      }}
      sx={styles.overlay(placement)}
      actionComponent={
        <PopupActions
          sx={[styles.actions, placement === 'rhs' && styles.rhsActions]}
        >
          <EmphasizeButton
            sx={styles.button(placement)}
            variant="outlined"
            onClick={closeBlocker}
          >
            {settings.closeText}
          </EmphasizeButton>
          <EmphasizeButton
            sx={styles.button(placement)}
            prefixIcon={<ProfileUpgradeIcon />}
            component={Link}
            href="/extend-trial"
          >
            {settings.upgradeText}
          </EmphasizeButton>
        </PopupActions>
      }
    />
  );
}
