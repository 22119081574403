import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import AuthGuard from '@app/web/src/components/AuthGuard';
import { testIdConfig } from '@front/config';
import { OtherPlay as OtherPlayIcon } from '@front/icon';
import { Button, EmphasizeButton, toast } from '@front/ui';
import { useClubSlug, useIaClubStatus } from '@lib/web/hooks';
import { trackEvent } from '@lib/web/utils';

import useCreateQuizState from '../hooks/useCreateQuizState';
import useStartNewQuiz from '../hooks/useStartNewQuiz';

const styles = {
  button: {
    width: '100%',
  },
};

const PRACTICE_PATHNAMES_REGEX = /\/club\/(.*?)\/(practice|exam)/i;

type StartQuizButtonProps = {
  emphasized?: boolean;
};

const StartQuizButton = ({ emphasized = true }: StartQuizButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('quiz');
  const [isLoading, setIsLoading] = useState(false);
  const {
    availableQuestionCount,
    availableQuestionCountLoading,
    isRandomChallengers,
    values,
  } = useCreateQuizState();
  const startQuiz = useStartNewQuiz();
  const clubSlug = useClubSlug();
  const { comingSoon, inactive } = useIaClubStatus(clubSlug);

  const router = useRouter();
  const isResultPage = router.asPath.includes('result');
  const isPracticing =
    PRACTICE_PATHNAMES_REGEX.test(router.pathname) && !isResultPage;

  const handleStartClick = async () => {
    setIsLoading(true);

    const quizUrl = await startQuiz();

    if (quizUrl === null) {
      toast.error(
        'Something went wrong, please try again',
        {
          anchorEl: buttonRef.current,
        },
        { id: 'question-alert' }
      );
      setIsLoading(false);
      return;
    }

    if (quizUrl === '') {
      setIsLoading(false);
      return;
    }

    if (isPracticing) {
      // because we're in the practice page => need to reload to start a new one
      router.replace(quizUrl).then(() => router.reload());
    } else {
      router.push(quizUrl);
    }

    trackEvent('click', {
      elementName: 'start-quiz-button',
    });
  };

  useEffect(() => {
    setIsLoading(false);
  }, [router]);

  const randomChallengersInvalid =
    isRandomChallengers && !values.randomChallengerCount;
  const disabled = comingSoon || inactive || randomChallengersInvalid;
  const Component = emphasized ? EmphasizeButton : Button;

  return (
    <AuthGuard
      additionalBlockerTip={
        availableQuestionCount === 0
          ? t('practice.adjust.question', { count: 0 })
          : undefined
      }
    >
      <Component
        sx={styles.button}
        ref={buttonRef}
        data-testid={testIdConfig.startPractice}
        disabled={!clubSlug || disabled}
        loading={isLoading || availableQuestionCountLoading}
        prefixIcon={<OtherPlayIcon />}
        onClick={handleStartClick}
      >
        {t('Start')}
      </Component>
    </AuthGuard>
  );
};

export default StartQuizButton;
