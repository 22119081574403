import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import useAhaInvitation from '@app/web/src/hooks/utils/useAhaInvitation';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { getSearchState, SearchState } from '@app/web/src/utils/search';
import {
  ActionClear as ActionClearIcon,
  MainChallenge as MainChallengeIcon,
  OtherCalendarDay as OtherCalendarDayIcon,
} from '@front/icon';
import {
  BaseLayoutRightPanel,
  DateTextField,
  LightbulbCard,
  SearchBar,
  SquareAvatar,
  TipButton,
  toast,
  useBaseLayout,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import {
  apis,
  useAuth,
  useSearchClubMembers,
  useUserFollow,
} from '@lib/web/apis';
import {
  useClubSlug,
  useInfiniteScroll,
  useSearchStatus,
} from '@lib/web/hooks';
import { call, emailRegex } from '@lib/web/utils';
import { addDays } from 'date-fns';

import StartQuizButton from '../../../PracticePage/CreateQuizForm/components/StartQuizButton';
import { ChallengerIconListLayoutItemObj } from '../../../PracticePage/CreateQuizForm/contexts/type';
import useCreateQuizAction from '../../../PracticePage/CreateQuizForm/hooks/useCreateQuizAction';
import useCreateQuizState from '../../../PracticePage/CreateQuizForm/hooks/useCreateQuizState';
import getActiveQuizButtonEl from '../../../PracticePage/CreateQuizForm/utils/getActiveQuizButtonEl';
import useOpenGlobalProfilePanel from '../../hooks/useOpenGlobalProfilePanel';
import ChallengeMaxAttemptOptions from '../components/ChallengeMaxAttemptOptions';
import CreateChallengeButton from '../components/CreateChallengeButton';
import RandomChallengersOption from '../components/RandomChallengersOption';

const styles = {
  root: {
    pt: 1,
    '& .ia-icon-list-layout': {
      py: 0,
    },
    '& .ia-icon-list-layout_title': {
      py: 0.5,
    },
  },
  title: {
    fontWeight: 700,
    px: { xs: '16px', md: '12px' },
    mt: 1,
    fontSize: { xs: '20px' },
  },
  settings: {
    px: { xs: '16px', md: '12px' },
    pb: 2,
    pt: 0.5,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  searchBar: {
    '& .search-input-wrap': {
      py: '12px',
    },
  },
  resetButton: {
    marginLeft: 'auto',
  },
  emptyChallengers: {
    px: { xs: '16px', md: '12px' },
  },
};

export default function StartQuizChallengersPanel() {
  const { t } = useTranslation('quiz');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { openProfile, defaultSearch } = useOpenGlobalProfilePanel();

  const {
    values: {
      challengers: challengersValue,
      deadline,
      maxAttempt,
      randomChallengerCount,
    },
    challengeId,
    isChallengersDirty,
    isRandomChallengers,
  } = useCreateQuizState();

  const { getRightParams } = useBaseLayout<GlobalPanelParams>();
  const { isCreateChallenge } = getRightParams(
    GlobalPanelKeys.GlobalStartQuizChallengers
  );

  const searchInputRef = useRef<HTMLInputElement>();
  const { member } = useAuth();
  const userId = member?.userId || '';

  const inChallenge = !!challengeId;
  // deadline default is 7 days later
  const deadlineValue = deadline || addDays(new Date(), 7);

  const { pendingEmails, invitedHistory, inviteToAha, newUserFormatter } =
    useAhaInvitation();

  const {
    focused,
    search,
    isSearching,
    debouncedSearch,
    setSearch,
    onChange,
    onBlur,
    onFocus,
  } = useSearchStatus(defaultSearch);

  const clubSlug = useClubSlug();

  const userFollowingData = useUserFollow({
    type: 'following',
    userId,
    keyword: debouncedSearch,
    isGetStatus: true,
  });

  const userSearchData = useSearchClubMembers({
    clubSlug,
    keyword: isSearching ? debouncedSearch : '',
    isExcludeMe: true,
    isOrderByRole: false,
  });

  const { dataset: friendDataset, totalCount: friendTotalCount } =
    userFollowingData;

  const {
    dataset: searchDataset,
    isLoading: searchLoading,
    totalCount: searchTotalCount,
  } = userSearchData;

  const listState = getSearchState({
    isSearching,
    isFocused: focused,
    hasSelected: true,
  });

  const { scrollRef } = useInfiniteScroll({
    infiniteHookResponse:
      listState === SearchState.Initial ? userFollowingData : userSearchData,
  });

  const totalCount =
    listState === SearchState.Initial ? friendTotalCount : searchTotalCount;

  const selectUser = () => {
    setSearch('');
    searchInputRef.current?.focus();
  };

  const displayDataset: ChallengerIconListLayoutItemObj[] = useMemo(() => {
    if (listState === SearchState.Initial) {
      return friendDataset.map((item) => ({
        id: item.userId,
        title: item.displayName,
        titleAction: { type: 'event', value: 'titleClick' },
        description: `@${item.userName}`,
        avatarUrl: item.nftAvatar || item.avatar,
        actionMap: {
          select: {
            value: 'selectChanged',
          },
          hover: {
            value: 'userHovered',
          },
        },
        metadata: {
          userId: item.userId,
          isFollowing: true,
        },
      }));
    }
    if (listState === SearchState.Searching) {
      return searchDataset.map((item) => ({
        id: item.userId,
        title: item.displayName,
        description: `@${item.userName}`,
        avatarUrl: item.nftAvatar || item.avatar,
        actionMap: {
          select: {
            value: 'selectChanged',
          },
          hover: {
            value: 'userHovered',
          },
        },
        metadata: {
          userId: item.userId,
          isFollowing: item.isFollowing,
        },
      }));
    }

    return [];
  }, [listState, friendDataset, searchDataset]);

  const newUser = useMemo(() => {
    const email =
      isSearching &&
      !searchDataset.length &&
      !searchLoading &&
      emailRegex.test(debouncedSearch)
        ? debouncedSearch
        : '';
    return newUserFormatter(email);
  }, [
    debouncedSearch,
    isSearching,
    newUserFormatter,
    searchDataset.length,
    searchLoading,
  ]);
  const selectedCount = challengersValue.length;

  const isSelected = useCallback(
    (id: string) => {
      return challengersValue.some((challenge) => challenge.id === id);
    },
    [challengersValue]
  );

  const config: IaLayoutConfig[] = useMemo(() => {
    if (!member) {
      return [];
    }

    const selectedItems =
      selectedCount > 0
        ? [t('## Challenger', { count: selectedCount }), ...challengersValue]
        : [];

    if (listState === SearchState.Initial) {
      if (displayDataset.length === 0) {
        return [
          {
            layout: 'icon-list-layout',
            items: selectedItems,
          },
        ];
      }

      const selectedFollowing = challengersValue.filter(
        (c) =>
          c.metadata?.isFollowing || displayDataset.some((d) => d.id === c.id)
      );
      const followingTotalCount = totalCount
        ? totalCount - selectedFollowing.length
        : 0;

      return [
        {
          layout: 'icon-list-layout',
          items: selectedItems.concat(
            [t('## Following', { count: followingTotalCount || 0 })],
            displayDataset.filter((d) => !isSelected(d.id))
          ),
        },
      ];
    }

    if (listState === SearchState.Searching) {
      if (!searchLoading && displayDataset.length === 0 && !newUser)
        return [
          {
            layout: 'icon-list-layout',
            items: [t('No result found')],
          },
        ];

      if (newUser) {
        return [
          {
            layout: 'icon-list-layout',
            items: [
              {
                id: newUser.email,
                title: newUser.email,
                avatarBlackAndWhite: true,
                actionMap: {
                  click: {
                    type: 'event',
                    value: 'inviteToAha',
                    text: t(
                      `challenger.invite.aha.label.${newUser.actionText}`
                    ),
                    actionType: 'textButton',
                    disabled:
                      newUser.actionText === 'accepted' ||
                      newUser.actionText === 'pending',
                  },
                },
              },
            ],
          },
        ];
      }

      const filteredDataset = displayDataset.filter((d) => !isSelected(d.id));
      const filteredTotalCount = totalCount
        ? totalCount - (displayDataset.length - filteredDataset.length)
        : 0;

      return [
        {
          layout: 'icon-list-layout',
          items: selectedItems.concat(
            [t('Results', { count: filteredTotalCount })],
            filteredDataset
          ),
        },
      ];
    }

    return [
      {
        layout: 'icon-list-layout',
        items: [
          ...(selectedCount > 0
            ? [t('## Challenger', { count: selectedCount })]
            : []),
          ...challengersValue,
        ],
      },
    ];
  }, [
    member,
    selectedCount,
    t,
    challengersValue,
    listState,
    displayDataset,
    totalCount,
    isSelected,
    searchLoading,
    newUser,
  ]);

  const { updateQuizValues, resetChallengers, setIsRandomChallengers } =
    useCreateQuizAction();

  const toastOptions = {
    anchorEl: getActiveQuizButtonEl(),
  };

  const addChallengerToExistChallenge = async (
    friend: ChallengerIconListLayoutItemObj
  ) => {
    await call(() =>
      apis.challenge.sentChallengeInvitation({
        challengeId: challengeId || '',
        userIds: [friend.id],
        emails: [],
      })
    );
  };

  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && displayDataset
  );

  const handleUserClick = (value: ChallengerIconListLayoutItemObj) => {
    const updatedChallengers = isSelected(value.id)
      ? challengersValue.filter((challenge) => challenge.id !== value.id)
      : [...challengersValue, value];

    if (inChallenge) {
      const lastChallenger = updatedChallengers[updatedChallengers.length - 1];
      if (lastChallenger) {
        toast.success(t('Friend Added to Challenge'), toastOptions);
        addChallengerToExistChallenge(lastChallenger);
      }
    } else {
      if (updatedChallengers.length > challengersValue.length) {
        toast.success(t('Friend Added to Challenge'), toastOptions);
      } else {
        toast.warning(t('Friend Removed from Challenge'), toastOptions);
      }
    }
    updateQuizValues({ challengers: updatedChallengers });
    selectUser();
  };

  const availableActions = {
    selectChanged: {
      action: (value: ChallengerIconListLayoutItemObj) => {
        handleUserClick(value);
      },
    },
    userHovered: {
      action: updateSuggestItem,
    },
    inviteToAha: {
      action: async (value: ChallengerIconListLayoutItemObj) => {
        inviteToAha([value.id], {
          toastOptions,
        });
      },
    },
    titleClick: {
      action: (value: IconListLayoutItemObj) => {
        openProfile(value.id, search);
      },
    },
  };

  const isDisabled = (id: string) =>
    id === userId ||
    (isSelected(id) && inChallenge) ||
    invitedHistory.some(
      (item) => item.receiver.email === id && !item.resendAble
    );

  const getItemStatus = ({ id }: ChallengerIconListLayoutItemObj) => {
    return {
      selected: id === userId || isSelected(id),
      disabled: isDisabled(id),
      loading: pendingEmails.includes(id),
      focused: mdUp && suggestItem?.id === id,
    };
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && suggestItem && !isDisabled(suggestItem.id)) {
      event.preventDefault();

      handleUserClick(suggestItem);
    }
  };

  const handleMaxAttemptChange = (val: number) => {
    updateQuizValues({ maxAttempt: val });
  };

  const handleDeadlineChange = (val: Date) => {
    updateQuizValues({ deadline: val });
  };

  const handleRandomChallengersChange = (val?: number) => {
    setIsRandomChallengers(val !== undefined);
    if (val !== undefined) {
      updateQuizValues({ randomChallengerCount: val });
    }
  };

  const clearChallenge = () => {
    resetChallengers();

    toast.success(t('Challenge has been reset successfully'), {
      anchorEl: getActiveQuizButtonEl(),
    });
  };

  const showSettings = !inChallenge;
  const showChallengers = !isRandomChallengers;

  const mainContent = (
    <>
      {showSettings && (
        <>
          <Typography variant="appH3" sx={styles.title}>
            {t('Settings')}
          </Typography>
          <Box sx={styles.settings}>
            <ChallengeMaxAttemptOptions
              value={maxAttempt}
              onChange={handleMaxAttemptChange}
            />
            <DateTextField
              placeholder="MM / DD / YY"
              format="MM/dd/yy"
              value={deadlineValue}
              onChange={(val) => handleDeadlineChange(new Date(val))}
              labelIcon={<OtherCalendarDayIcon width={12} height={12} />}
              label="Deadline"
              suffix={<OtherCalendarDayIcon width={16} height={16} />}
              minDate={addDays(new Date(), -1)}
            />
          </Box>
          <Typography variant="appH3" sx={styles.title}>
            {t('Challengers')}
          </Typography>
          <RandomChallengersOption
            enabled={isRandomChallengers}
            value={randomChallengerCount}
            onChange={handleRandomChallengersChange}
          />
        </>
      )}
      {showChallengers && (
        <BaseLayoutRightPanel.SearchInput>
          <SearchBar
            inputRef={searchInputRef}
            placeholder={t('Type to Search')}
            value={search}
            loading={searchLoading}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={handleKeyDown}
            suggestText={suggestItem?.title}
            prefixIcon={
              suggestItem && (
                <SquareAvatar src={suggestItem.avatarUrl} size={16}>
                  {suggestItem.title}
                </SquareAvatar>
              )
            }
            sx={styles.searchBar}
          />
        </BaseLayoutRightPanel.SearchInput>
      )}
    </>
  );

  const emptyTip = (
    <>
      {selectedCount === 0 && listState === SearchState.Result && (
        <Box sx={styles.emptyChallengers}>
          <LightbulbCard>
            {t(
              'challenge.settings.searchChallengers.tip',
              'Challenge friends using their username, email, or name.'
            )}
          </LightbulbCard>
        </Box>
      )}
    </>
  );

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <BaseLayoutRightPanel
          titleIcon={<MainChallengeIcon width="16" height="16" />}
          title={t('panel.Challenge')}
          toolComponent={
            inChallenge || !isChallengersDirty ? undefined : (
              <TipButton
                title={t('toolbar.Reset')}
                onClick={clearChallenge}
                sx={styles.resetButton}
              >
                <ActionClearIcon />
              </TipButton>
            )
          }
          actionComponent={
            isCreateChallenge ? (
              <CreateChallengeButton />
            ) : (
              <StartQuizButton emphasized={false} />
            )
          }
        >
          {!mdUp && (
            <BaseLayoutRightPanel.ScrollArea
              scrollableNodeProps={{ ref: scrollRef }}
              sx={styles.root}
            >
              {mainContent}
              {showChallengers && (
                <>
                  {emptyTip}
                  {config && <IaLayouts layouts={config} />}
                </>
              )}
            </BaseLayoutRightPanel.ScrollArea>
          )}
          {mdUp && (
            <BaseLayoutRightPanel.SearchWrapper>
              {mainContent}
              {showChallengers && (
                <BaseLayoutRightPanel.SearchContent>
                  <BaseLayoutRightPanel.ScrollArea
                    scrollableNodeProps={{ ref: scrollRef }}
                  >
                    {emptyTip}
                    {config && <IaLayouts layouts={config} />}
                  </BaseLayoutRightPanel.ScrollArea>
                </BaseLayoutRightPanel.SearchContent>
              )}
            </BaseLayoutRightPanel.SearchWrapper>
          )}
        </BaseLayoutRightPanel>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}
