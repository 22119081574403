import WebThreadChat from '@app/web/src/components/WebThreadChat';
import { MESSAGE_SORT_FILTER_SCOPE } from '@app/web/src/config/thread';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import WebThreadChatToolbar from '@app/web/src/widgets/ThreadPage/WebThreadChatToolbar';
import { BaseLayoutRightPanel, useBaseRightPanel } from '@front/ui';

export default function ThreadChatPanel() {
  const { rightPanelOpened, getRightParams } =
    useBaseRightPanel<GlobalPanelParams>();
  const { channelCid } = getRightParams(GlobalPanelKeys.GlobalThreadChat);

  return (
    <>
      <BaseLayoutRightPanel
        resizable
        toolComponent={
          <WebThreadChatToolbar
            channelCid={channelCid}
            scope={MESSAGE_SORT_FILTER_SCOPE}
          />
        }
      >
        {/* hide ThreadChat when rhs closed, so we can make WebThreadChat autofocus on composer by using useEffect(..., []) */}
        {rightPanelOpened && (
          <WebThreadChat
            channelCid={channelCid}
            scope={MESSAGE_SORT_FILTER_SCOPE}
          />
        )}
      </BaseLayoutRightPanel>
    </>
  );
}
