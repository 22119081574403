import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { alpha, Box, Theme } from '@mui/material';
import useMemberClubRole from '@app/web/src/hooks/utils/useMemberClubRole';
import useMemberClubRoleMethods from '@app/web/src/hooks/utils/useMemberClubRoleMethods';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { PrivacyFriends as PrivacyFriendsIcon } from '@front/icon';
import {
  BaseLayoutRightPanel,
  Button,
  SearchBar,
  toast,
  useBaseRightPanel,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import { IaAction } from '@lib/ia/src/core/types';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import { apis, ClubMemberRole, useClubMembers } from '@lib/web/apis';
import {
  useBottomScrolled,
  useClubSlug,
  useCurrentIaClub,
  useSearchStatus,
} from '@lib/web/hooks';
import { call } from '@lib/web/utils';
import { TFunction } from 'i18next';

const styles = {
  main: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  search: {
    px: { xs: 2.5, md: '12px' },
  },
  content: {
    flex: 1,
    position: 'relative',
  },
};

const getRoleLabel = (role: ClubMemberRole) => {
  if (role === ClubMemberRole.Admin) {
    return 'Admin';
  }

  if (role === ClubMemberRole.Editor) {
    return {
      text: 'Editor',
      color: 'yellow' as const,
    };
  }

  return undefined;
};

const getMoreActiveActionValue = (role: ClubMemberRole) => {
  if (role === ClubMemberRole.Admin) {
    return 'changeToAdmin';
  }

  if (role === ClubMemberRole.Editor) {
    return 'changeToEditor';
  }

  return 'changeToMember';
};

const getRowItemAction = (
  t: TFunction,
  isAdmin: boolean,
  canUpdateOrDelete: {
    delete: boolean;
    update: boolean;
  }
): (IaAction & { subActions?: IaAction[] })[] | undefined => {
  const roleActions = [
    {
      type: 'event' as const,
      value: 'changeToAdmin',
      text: t('create.table.status.admin.text'),
      hint: t('create.table.status.admin.hint'),
    },
    {
      type: 'event' as const,
      value: 'changeToEditor',
      text: t('create.table.status.editor.text'),
      hint: t('create.table.status.editor.hint'),
    },
    {
      type: 'event' as const,
      value: 'changeToMember',
      text: t('create.table.status.member.text'),
      hint: t('create.table.status.member.hint'),
    },
  ];

  const actions: (IaAction & { subActions?: IaAction[] })[] = [];
  if (canUpdateOrDelete.update) {
    actions.push({
      type: 'event',
      value: 'editRole',
      hint: 'Edit Role',
      icon: 'OtherEdit',
      subActions: roleActions,
    });
  }
  if (canUpdateOrDelete.delete) {
    actions.push({
      type: 'event',
      value: 'delete',
      hint: 'Remove Member',
      icon: 'ActionCloseSmall',
    });
  }
  return actions;
};

export default function ClubMembersPanel() {
  const { t } = useTranslation('club');
  const disableLoadMoreRef = useRef<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { search, debouncedSearch, onChange, onBlur, onFocus } =
    useSearchStatus();
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();

  const { isAdmin } = useMemberClubRole();
  const { canUpdateOrDelete } = useMemberClubRoleMethods();
  const { club, mutate: clubMutate } = useCurrentIaClub();
  const clubSlug = useClubSlug();
  const {
    dataset,
    isLoading: searchLoading,
    totalCount,
    isReachingEnd,
    isLoadingMore,
    mutate,
    setSize,
  } = useClubMembers({
    clubSlug,
    keyword: debouncedSearch || '',
    isGetRole: true,
    isJoined: true,
  });

  const updateRole = async (members: CreateClubMember[]) => {
    await call(
      apis.club.updateClubMembers({
        clubSlug,
        members: members,
      })
    );
    mutate();
    clubMutate();
  };
  const removeMember = async (userIds: string[]) => {
    await call(apis.club.deleteClubMembers({ clubSlug, userIds }));
    mutate();
    clubMutate();
  };

  const availableActions = {
    titleClick: {
      action: ({ id }: { id: string }) => {
        openRightPanel(GlobalPanelKeys.GlobalProfile, {
          userId: id,
        });
      },
    },
    delete: {
      action: ({ id }: { id: string }) => {
        openRightPanel(GlobalPanelKeys.GlobalProfile, {
          userId: id,
        });
      },
    },
    changeToAdmin: {
      action: ({ id }: { id: string }) => {
        updateRole([{ userId: id, role: ClubMemberRole.Admin }]);
      },
    },
    changeToEditor: {
      action: ({ id }: { id: string }) => {
        updateRole([{ userId: id, role: ClubMemberRole.Editor }]);
      },
    },
    changeToMember: {
      action: ({ id }: { id: string }) => {
        updateRole([{ userId: id, role: ClubMemberRole.Member }]);
      },
    },
    removeMember: {
      action: async ({ id }: { id: string }) => {
        removeMember([id]);
      },
      batchAction: async (items?: { id: string }[]) => {
        if (items) {
          removeMember(items.map((item) => item.id));
        }
      },
    },
  };

  const config: IaLayoutConfig[] = useMemo(() => {
    return [
      {
        layout: 'icon-list-layout',
        items: [
          t('club.RHS.clubMembers.##members', {
            count: totalCount ?? 0,
          }),
          ...dataset.map((item) => ({
            id: item.userId,
            title: item.displayName,
            titleSuffix: item.isMe ? 'Me' : '',
            titleSuffixSx: {
              ml: 1,
              py: '1px',
              px: 1,
              borderRadius: 1,
              bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
              fontWeight: 400,
            },
            titleAction: {
              type: 'event' as const,
              value: 'titleClick',
            },
            label: getRoleLabel(item.role),
            description: `@${item.userName}`,
            avatarUrl: item.nftAvatar || item.avatar,
            moreActionSelectedValue: getMoreActiveActionValue(item.role),
            moreActions: getRowItemAction(
              t,
              item.role === ClubMemberRole.Admin,
              canUpdateOrDelete(item)
            ),
            hoverable: true,
          })),
        ],
      },
    ];
  }, [t, totalCount, dataset, canUpdateOrDelete]);

  const handleRemoveMembers = async () => {
    const confirmed = await toast.confirm(
      t('club.RHS.clubMember.removeConfirm.content'),
      {
        anchorEl: document.querySelector(
          '[data-testid="remove-all-members-button"]'
        ) as Element,
        confirmText: t('club.RHS.clubMember.removeConfirm.cta'),
      },
      {
        id: 'remove-all-members-button',
      }
    );

    if (confirmed && club) {
      availableActions.removeMember.batchAction(
        dataset
          .filter(
            (user) => user.userId !== club.clubCreator.clubCreatorSourceId
          )
          .map((user) => ({ id: user.userId }))
      );
    }
  };

  disableLoadMoreRef.current = isReachingEnd || isLoadingMore;

  const loaded = !isLoadingMore;
  const handleScrollBottom = useCallback(() => {
    if (!disableLoadMoreRef.current) {
      setSize((s) => s + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  useBottomScrolled({
    scrollRef,
    onScrolledBottom: handleScrollBottom,
  });

  return (
    <IaActionContextProvider availableActions={availableActions}>
      <BaseLayoutRightPanel
        titleIcon={<PrivacyFriendsIcon width="16" height="16" />}
        title={t('club.RHS.clubMembers.title')}
        actionComponent={
          isAdmin &&
          dataset.length > 1 && (
            <Button
              data-testid="remove-all-members-button"
              onClick={handleRemoveMembers}
            >
              {t('club.RHS.clubMembers.cta')}
            </Button>
          )
        }
      >
        <Box sx={styles.main}>
          <Box sx={styles.search}>
            <SearchBar
              placeholder={t('Type to Search')}
              value={search}
              loading={searchLoading}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </Box>
          <Box sx={styles.content}>
            <BaseLayoutRightPanel.ScrollArea
              scrollableNodeProps={{ ref: scrollRef }}
            >
              {config && <IaLayouts layouts={config} />}
            </BaseLayoutRightPanel.ScrollArea>
          </Box>
        </Box>
      </BaseLayoutRightPanel>
    </IaActionContextProvider>
  );
}
