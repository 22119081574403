import { LeagueTier } from '@lib/web/apis';

export const LEAGUE_TIERS = [
  'Alpha',
  'Prima',
  'Centurion',
  'Spectus',
  'Legatus',
  'Nocturnus',
  'Incendium',
  'Cosmus',
  'Maximus',
  'Commandus',
] as const;

export const getLeagueName = (tier: LeagueTier) => {
  return LEAGUE_TIERS[tier - 1];
};
