import { MouseEvent } from 'react';
import Router from 'next/router';
import { Theme, useMediaQuery } from '@mui/material';
import { useAppSelector } from '@app/web/src/hooks/redux';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { popup, useBaseRightPanel } from '@front/ui';
import { useClubSlug } from '@lib/web/hooks';

function useQuizHashtagAndEmojiParams(type: 'emoji' | 'hashtags') {
  const { getRightParams } = useBaseRightPanel<GlobalPanelParams>();

  if (type === 'hashtags') {
    const { title, tagId, category } = getRightParams(
      GlobalPanelKeys.GlobalHashtagsDetail
    );
    return {
      id: tagId,
      title,
      category,
    };
  }
  const { title, markId, category } = getRightParams(
    GlobalPanelKeys.GlobalEmojiDetail
  );
  return {
    id: markId,
    title,
    category,
  };
}

export default function useQuizHashtagAndEmojiActions(
  type: 'emoji' | 'hashtags'
) {
  // !st.quiz.quiz?.id: Not on practice or exam page
  const isFinished = useAppSelector(
    (st) => !st.quiz.quiz?.id || st.quiz.isFinished
  );
  const slug = useClubSlug();
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { openRightPanel, closeRightPanel } =
    useBaseRightPanel<GlobalPanelParams>();

  const { title, category } = useQuizHashtagAndEmojiParams(type);

  const handleConfirmQuit = async (ev: MouseEvent, onLeave: () => void) => {
    const res = await popup.promise({
      content: 'Are you sure you want to leave the test?',
      anchorEl: ev.currentTarget as Element | null,
      anchorType: 'button',
      popupType: 'warning',
      confirmText: 'Leave',
    });

    if (res) {
      onLeave();
    }
  };

  const openStartPanel = (event: MouseEvent) => {
    const handleOpenStartPanel = () => {
      openRightPanel(GlobalPanelKeys.GlobalStartQuiz);
    };
    if (!isFinished) {
      handleConfirmQuit(event, handleOpenStartPanel);
    } else {
      handleOpenStartPanel();
    }
  };

  const goPlaylistPage = (event: MouseEvent) => {
    const FILTER_QUERY_MAP = {
      'total-wrong': 'mistake',
      overtime: 'overtime',
      'out-of-practice': '',
    };
    const handleGoPlaylist = () => {
      Router.push(
        `/club/${slug}/playlists/${type}/${title}?title=${title}&filter=${
          FILTER_QUERY_MAP[
            category as 'total-wrong' | 'overtime' | 'out-of-practice'
          ]
        }`
      );
    };

    if (!isFinished) {
      handleConfirmQuit(event, handleGoPlaylist);
    } else {
      if (mdDown) {
        closeRightPanel();
      }
      handleGoPlaylist();
    }
  };

  return { goPlaylistPage, openStartPanel };
}
