import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useCommonToolbarItems } from '@app/web/src/hooks/utils/useCommonToolbarItems';
import {
  useIaAgentAccessControl,
  useIaAgentActions,
} from '@app/web/src/ia/agent';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { useAgentProfileLayoutActions } from '@app/web/src/widgets/CommonPanels/AgentProfilePanel/hooks/useAgentProfileLayoutActions';
import { useAgentProfilePanelLayoutConfig } from '@app/web/src/widgets/CommonPanels/AgentProfilePanel/hooks/useAgentProfilePanelLayoutConfig';
import { MainProfileSolid as MainProfileSolidIcon } from '@front/icon';
import { BaseLayoutRightPanel, useBaseRightPanel } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import {
  AgentViewSlug,
  buildInfiniteHookMutate,
  useAgents,
} from '@lib/web/apis';
import { ResponsiveToolbar } from '@lib/web/ui';

const styles = {
  scroll: { px: { xs: 2.5, md: 1.5 } },
  toolbarRoot: {
    marginLeft: 'auto',
  },
};

function Toolbar({ agent }: { agent?: GetIaAgentProfileRhsAgentDefaultRes }) {
  const commonToolbarItems = useCommonToolbarItems({
    enableManageAgents: false,
    enableGetHelp: false,
  });
  const iaAgentAccessControl = useIaAgentAccessControl();
  const iaAgentActions = useIaAgentActions();

  return (
    <Box sx={styles.toolbarRoot}>
      <ResponsiveToolbar
        items={[
          !!agent &&
            iaAgentAccessControl.isEditable(agent) && {
              type: 'Edit' as const,
              onClick: () => {
                if (!agent) return;
                iaAgentActions.openEditAgentPage({
                  id: agent.agentId,
                  clubSlug: agent.agentClub.clubSlug,
                });
              },
            },
          !!agent &&
            iaAgentAccessControl.isDuplicatable(agent) && {
              type: 'Duplicate' as const,
              onClick: () => {
                if (!agent) return;
                void iaAgentActions.duplicateAgent({
                  id: agent.agentId,
                  clubSlug: agent.agentClub.clubSlug,
                });
              },
            },
          ...commonToolbarItems,
        ]}
      />
    </Box>
  );
}

export default function AgentProfilePanel() {
  const { t } = useTranslation('agent');
  const { getRightParams } = useBaseRightPanel<GlobalPanelParams>();

  const { agentId } = getRightParams(GlobalPanelKeys.GlobalAgentProfile);
  const { dataset, mutate } = useAgents({
    viewSlug: AgentViewSlug.ProfileRhsAgentDefault,
    search: `agentId:${agentId}`,
    searchFields: 'agentId:eq',
  });
  const agent: GetIaAgentProfileRhsAgentDefaultRes | undefined = useMemo(
    () => dataset[0],
    [dataset]
  );
  const reloadAgents = useMemo(() => buildInfiniteHookMutate(mutate), [mutate]);
  const layouts = useAgentProfilePanelLayoutConfig({ agent });
  const availableActions = useAgentProfileLayoutActions({
    agentId,
    reloadAgents,
  });

  return (
    <IaActionContextProvider availableActions={availableActions}>
      <BaseLayoutRightPanel
        titleIcon={<MainProfileSolidIcon width={16} height={16} />}
        title={t('Profile')}
        toolComponent={<Toolbar agent={agent} />}
      >
        <BaseLayoutRightPanel.ScrollArea sx={styles.scroll}>
          <IaLayouts layouts={layouts} />
        </BaseLayoutRightPanel.ScrollArea>
      </BaseLayoutRightPanel>
    </IaActionContextProvider>
  );
}
