import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Switch, Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { CardProps } from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { ChatQuestionList as ChatQuestionListIcon } from '@front/icon';
import { MobileTooltip, Slider, toast } from '@front/ui';
import { useClubSetting } from '@lib/web/apis';
import { useClubSlug, useCurrentExam } from '@lib/web/hooks';
import { trackEvent } from '@lib/web/utils';

import { Placement } from '../CreateQuizForm';
import useCreateQuizAction from '../hooks/useCreateQuizAction';
import useCreateQuizState from '../hooks/useCreateQuizState';

const styles = {
  slider: {
    px: '10px',
    mt: 2,
  },
  questionCount: {
    fontWeight: 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    b: {
      typography: 'h3',
    },
    '& span': {
      pl: 1,
      typography: 'body2',
    },
  },
  skeletonGroup: {
    height: 184,
    display: 'grid',
    gap: 2,
  },
  officialQuestion: (placement: Placement) => ({
    mt: 2,
    display: 'flex',
    alignItems: 'center',
    flexDirection: {
      md: placement === 'center' ? 'row-reverse' : 'row',
    },
    justifyContent: {
      xs: 'space-between',
      md: placement === 'center' ? 'flex-end' : 'space-between',
    },
    gap: 2,
  }),
  officialQuestionTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
  switch: {
    position: 'relative',
    display: 'flex',
  },
  switchTip: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};

function LoadingSkeleton() {
  return (
    <Box sx={styles.skeletonGroup}>
      <Skeleton height={72} sx={{ maxWidth: '290px' }} />
      <Skeleton height={58} />
      <Skeleton height={24} sx={{ maxWidth: '296px' }} />
    </Box>
  );
}

type NumberOfQuestionsProps = {
  showHint?: boolean;
  officialDisabled?: boolean;
  sx?: CardProps['sx'];
  placement?: Placement;
};

function NumberOfQuestions({
  section,
  showHint = false,
  officialDisabled = false,
  timeMinutes,
  placement = 'center',
  sx,
}: NumberOfQuestionsProps & {
  section: GetExamSectionRes;
  title: string;
  timeMinutes: number | null;
}) {
  const { t } = useTranslation('quiz');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const {
    defaultValues,
    minQuestionCount,
    maxQuestionCount,
    officialQuestionCount,
    isOfficial,
    displayQuestionCount,
    availableQuestionCount,
    totalQuestionDisabled,
  } = useCreateQuizState();

  const { updateQuizValues, setIsOfficial } = useCreateQuizAction();

  useEffect(() => {
    if (availableQuestionCount === undefined) return;

    if (availableQuestionCount === 0) {
      updateQuizValues({
        questionCount: 0,
      });

      setIsOfficial(false);
    } else if (availableQuestionCount > 0 && displayQuestionCount === 0) {
      updateQuizValues({
        questionCount: defaultValues.questionCount,
      });

      setIsOfficial(false);
    }
  }, [
    availableQuestionCount,
    defaultValues.questionCount,
    displayQuestionCount,
    setIsOfficial,
    updateQuizValues,
  ]);

  if (typeof maxQuestionCount !== 'number') return null;

  const minDisplayCount = Math.min(displayQuestionCount, minQuestionCount);
  const displayOptions = [
    { value: minDisplayCount, label: minDisplayCount },
    { value: maxQuestionCount, label: maxQuestionCount },
  ];

  const handleChange = (v: number) => {
    const inRange = minQuestionCount <= v && v <= maxQuestionCount;

    if (inRange) {
      updateQuizValues({
        questionCount: v,
      });
    }

    if (isOfficial && v !== officialQuestionCount) {
      setIsOfficial(false);
    }
  };

  const toggleOfficialQuestion = () => {
    setIsOfficial(!isOfficial);
  };

  const handleOfficialClick = () => {
    if (mdUp)
      toast.info(t('question.notEnough'), undefined, {
        id: 'question-notEnough',
      });
  };

  const questionTpq = timeMinutes
    ? timeMinutes / officialQuestionCount
    : section.tpqSec / 60;
  const totalMinutes = questionTpq * displayQuestionCount;
  const minutes = Math.floor(totalMinutes);
  const seconds = Math.round((totalMinutes % 1) * 60);

  const notEnoughOfficialQuestion = maxQuestionCount < officialQuestionCount;
  return (
    <Box sx={sx}>
      {showHint && (
        <Typography
          sx={[styles.questionCount, totalQuestionDisabled && { opacity: 0.5 }]}
        >
          <Trans
            i18nKey="quiz::practice.totalQuestion.hint.question"
            components={{ b: <b />, span: <span /> }}
            values={{
              count: displayQuestionCount,
              context:
                minutes === 0
                  ? 'onlySeconds'
                  : seconds === 0
                  ? 'onlyMinutes'
                  : '',
              minutes: t('quiz::practice.totalQuestion.hint.minute', {
                count: minutes,
              }),
              seconds: t('quiz::practice.totalQuestion.hint.second', {
                count: seconds,
              }),
            }}
          />
        </Typography>
      )}
      <Box sx={styles.slider}>
        <Slider
          marks={displayOptions}
          min={minDisplayCount}
          max={maxQuestionCount}
          defaultValue={defaultValues.questionCount}
          value={displayQuestionCount}
          onChange={(ev, newValue) => handleChange(newValue as number)}
          onChangeCommitted={() =>
            trackEvent('form', {
              elementName: 'question-slider',
              action: 'change',
            })
          }
          valueLabelDisplay="on"
          disabled={totalQuestionDisabled || availableQuestionCount === 0}
        />
      </Box>
      <Box sx={styles.officialQuestion(placement)}>
        <Box
          sx={[
            styles.officialQuestionTitle,
            totalQuestionDisabled && { opacity: 0.5 },
          ]}
        >
          <ChatQuestionListIcon width={16} height={16} />

          <Typography variant="body2">
            {t('Same as official exam ##', { count: officialQuestionCount })}
          </Typography>
        </Box>

        <Box sx={styles.switch}>
          <Switch
            disabled={officialDisabled || totalQuestionDisabled}
            onClick={() => toggleOfficialQuestion()}
            checked={isOfficial}
          />
          {notEnoughOfficialQuestion && (
            <MobileTooltip
              title={t('tag.beta.text', 'Beta')}
              content={t(
                'question.notEnough',
                `Not enough questions for a full mock exam yet. We're working on it!`
              )}
            >
              <Box sx={styles.switchTip} onClick={handleOfficialClick} />
            </MobileTooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default function NumberOfQuestionsContainer(
  props: NumberOfQuestionsProps
) {
  const clubSlug = useClubSlug();
  const { section, exam } = useCurrentExam();
  const { data: clubSetting } = useClubSetting(clubSlug);

  if (!section || !exam || !clubSetting) return <LoadingSkeleton />;

  return (
    <NumberOfQuestions
      section={section}
      title={exam?.title}
      timeMinutes={clubSetting.data.timeMinutes}
      {...props}
    />
  );
}
