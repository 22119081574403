import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import CopyLinkButton from '@app/web/src/components/CopyLinkButton';
import useAhaInvitation from '@app/web/src/hooks/utils/useAhaInvitation';
import useMemberClubRole from '@app/web/src/hooks/utils/useMemberClubRole';
import { getSearchState } from '@app/web/src/utils/search';
import useOpenGlobalProfilePanel from '@app/web/src/widgets/CommonPanels/hooks/useOpenGlobalProfilePanel';
import { OtherAddFriend as OtherAddFriendIcon } from '@front/icon';
import {
  BaseLayoutRightPanel,
  Button,
  LightbulbCard,
  SearchBar,
  SquareAvatar,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaItemStatusProvider from '@lib/ia/src/core/IaItemStatus/IaItemStatusProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { useIaSuggest } from '@lib/ia/src/layouts/IconListLayout';
import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import {
  apis,
  ClubMemberRole,
  useClubInvitationLink,
  useNonJoinedFriends,
  useSearchClubMembers,
} from '@lib/web/apis';
import { useClubSlug, useSearchStatus } from '@lib/web/hooks';
import { callWithToast, emailRegex } from '@lib/web/utils';

import useEditClubMembersListConfig from '../../../hooks/useEditClubMembersListConfig';
import getEditClubInvitationMembersDisplayDataset from '../../../utils/getEditClubInvitationMembersDisplayDataset';

const styles = {
  main: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  search: {
    px: { xs: 2.5, md: '12px' },
  },
  content: {
    flex: 1,
    position: 'relative',
  },
  action: {
    display: 'flex',
    gap: 2,
    '& button': {
      px: 0,
      flex: 1,
      minWidth: 'unset',
      whiteSpace: 'nowrap',
    },
  },
};

export default function EditClubInviteMembersPanel({
  inactive = false,
}: {
  inactive?: boolean;
}) {
  const { t } = useTranslation('club');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { openProfile, defaultSearch } = useOpenGlobalProfilePanel();
  const { hasEditAuth } = useMemberClubRole();
  const { pendingEmails, inviteToAha, newUserFormatter } = useAhaInvitation();

  const searchInputRef = useRef<HTMLInputElement>();
  const {
    focused,
    search,
    isSearching,
    debouncedSearch,
    setSearch,
    onChange,
    onBlur,
    onFocus,
  } = useSearchStatus(defaultSearch);

  const clubSlug = useClubSlug();
  const { data: invitationLinkRes } = useClubInvitationLink(clubSlug);
  const [selected, setSelected] = useState<IconListLayoutItemObj[]>([]);
  const [sending, setSending] = useState(false);

  const listState = getSearchState({
    isSearching,
    isFocused: focused,
    hasSelected: selected.length > 0,
  });

  const { data: nonJoinedFriendsData } = useNonJoinedFriends(clubSlug);

  // search results
  const { dataset: searchDataset, isLoading: searchLoading } =
    useSearchClubMembers(
      {
        clubSlug,
        keyword: isSearching ? debouncedSearch : '',
      },
      50
    );

  const showSkeleton = !nonJoinedFriendsData || searchLoading;

  const newUser = useMemo(() => {
    const email =
      isSearching &&
      !searchDataset.length &&
      !searchLoading &&
      emailRegex.test(debouncedSearch)
        ? debouncedSearch
        : '';

    return newUserFormatter(email);
  }, [
    debouncedSearch,
    isSearching,
    newUserFormatter,
    searchDataset.length,
    searchLoading,
  ]);

  const selectedDataset = useMemo(() => {
    return selected.map((result) => ({
      id: result.id,
      title: result.title,
      titleAction: { type: 'event' as const, value: 'titleClick' },
      description: result.description,
      avatarUrl: result.avatarUrl,
      indicators: result.indicators,
      actionMap: {
        select: {
          value: 'userSelected',
        },
      },
    }));
  }, [selected]);

  const displayDataset = useMemo(() => {
    return getEditClubInvitationMembersDisplayDataset({
      t,
      state: listState,
      initialResult: nonJoinedFriendsData?.data || [],
      searchResult: searchDataset,
      newUser,
      showSkeleton,
    });
  }, [
    t,
    nonJoinedFriendsData,
    listState,
    searchDataset,
    newUser,
    showSkeleton,
  ]);

  const config = useEditClubMembersListConfig(
    listState,
    displayDataset,
    selectedDataset
  );

  const { suggestItem, updateSuggestItem } = useIaSuggest(
    focused && displayDataset
  );

  const selectUser = () => {
    setSearch('');
    searchInputRef.current?.focus();
  };

  const availableActions = {
    userSelected: {
      action: (value: IconListLayoutItemObj) => {
        setSelected((st) =>
          st.some((item) => item.id === value.id)
            ? st.filter((item) => item.id !== value.id)
            : [...st, value]
        );

        selectUser();
      },
    },
    userHovered: {
      action: updateSuggestItem,
    },
    inviteToAha: {
      action: async (value: IconListLayoutItemObj) => {
        inviteToAha([value.id], {
          toastOptions: {
            successMsg: t('create.RHS.members.invite.aha.success'),

            anchorEl: document.querySelector(
              '[data-testid="invite-to-club-button"]'
            ) as Element,
          },
        });
      },
    },
    titleClick: {
      action: (value: IconListLayoutItemObj) => {
        openProfile(value.id, search);
      },
    },
  };

  const sentInvitation = async () => {
    setSending(true);
    const [res] = await callWithToast(
      () =>
        !hasEditAuth
          ? apis.club.sendClubInvitation({
              clubSlug,
              userIds: selected.map((item) => item.id),
              emails: [],
            })
          : apis.club.addClubMembers({
              clubSlug,
              members: selected.map((item) => ({
                userId: item.id,
                role: ClubMemberRole.Member,
              })),
            }),
      {
        successMsg: t('Invitation sent'),
        anchorEl: document.querySelector(
          '[data-testid="invite-to-club-button"]'
        ) as Element,
      }
    );

    if (res) {
      setSelected([]);
    }
    setSending(false);
  };

  const invitationLink = invitationLinkRes?.data.url || '';

  const getItemStatus = ({ id }: IconListLayoutItemObj) => {
    return {
      selected: selected.some((item) => item.id === id),
      loading: pendingEmails.includes(id),
      focused: mdUp && id === suggestItem?.id,
    };
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (
      event.key === 'Enter' &&
      suggestItem &&
      !!suggestItem.actionMap?.select
    ) {
      event.preventDefault();

      const status = getItemStatus(suggestItem);
      if (status.selected) return;

      setSelected((st) => [...st, suggestItem]);
      selectUser();
    }
  };

  return (
    <IaItemStatusProvider getItemStatus={getItemStatus}>
      <IaActionContextProvider availableActions={availableActions}>
        <BaseLayoutRightPanel
          titleIcon={<OtherAddFriendIcon width="16" height="16" />}
          title={t('create.RHS.members.title')}
          inactive={inactive}
          actionComponent={
            <Box sx={styles.action}>
              <CopyLinkButton
                data-testid="copy-link-button"
                link={invitationLink}
              >
                {t('create.RHS.members.invite.link.label')}
              </CopyLinkButton>
              <Button
                data-testid="invite-to-club-button"
                disabled={!selected.length}
                loading={sending}
                onClick={sentInvitation}
              >
                {t('create.RHS.members.invite.sent.label', {
                  count: selected.length,
                })}
              </Button>
            </Box>
          }
        >
          <Box sx={styles.main}>
            <Box sx={styles.search}>
              <SearchBar
                inputRef={searchInputRef}
                placeholder={t('Type to Search')}
                value={search}
                loading={searchLoading}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={handleKeyDown}
                suggestText={suggestItem?.title}
                prefixIcon={
                  suggestItem && (
                    <SquareAvatar src={suggestItem.avatarUrl} size={16}>
                      {suggestItem.title}
                    </SquareAvatar>
                  )
                }
              />
              {!focused && !isSearching && (
                <LightbulbCard>
                  {t('create.RHS.members.search.hint')}
                </LightbulbCard>
              )}
            </Box>
            <Box sx={styles.content}>
              <BaseLayoutRightPanel.ScrollArea>
                {config && <IaLayouts layouts={config} />}
              </BaseLayoutRightPanel.ScrollArea>
            </Box>
          </Box>
        </BaseLayoutRightPanel>
      </IaActionContextProvider>
    </IaItemStatusProvider>
  );
}
