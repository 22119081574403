import React, { MouseEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import GoalRing from '@app/web/src/components/GoalRing';
import PanelContent from '@app/web/src/components/PanelContent';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { roundUpWithoutDecimal } from '@app/web/src/utils/number';
import { useDateFormat } from '@front/helper';
import { OtherCalendarDay as OtherCalendarDayIcon } from '@front/icon';
import { BaseLayoutRightPanel, NumberCard, useBaseRightPanel } from '@front/ui';

const styles = {
  goalRing: {
    textAlign: 'center',
    py: 1,
  },
  cards: {
    py: 1,
    display: 'grid',
    rowGap: 2,
    columnGap: 2.5,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  fill: {
    gridColumn: '1 / span 2',
  },
  valueSuffix: {
    typography: 'caption',
  },
  activeCard: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
};

export default function GoalRingPanel() {
  const { t } = useTranslation('analytics');
  const { getRightParams } = useBaseRightPanel<GlobalPanelParams>();

  const rightPanelParams = getRightParams(GlobalPanelKeys.GlobalGoalRing);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const scrollRef = useRef<HTMLDivElement>(null);
  const cardsRef = useRef<HTMLDivElement[]>([]);

  const [activeIndex, setActiveIndex] = useState(-1);
  const iconProps = { width: 16, height: 16 };
  const { displayDateFormat } = useDateFormat();
  const title = displayDateFormat(rightPanelParams.date, { showToday: true });

  const handleGoalRingHover = (ev: MouseEvent<SVGElement>, index: number) => {
    setActiveIndex(index);
    const currentCard = cardsRef.current[index];
    if (currentCard && scrollRef.current) {
      scrollRef.current.scrollTo({
        top: currentCard.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const goalRingRender = (
    <Box sx={styles.goalRing}>
      <GoalRing
        size={296}
        thickness={24}
        gap={14}
        dataset={[
          rightPanelParams.questionCount.ratio,
          rightPanelParams.practiceTimeMinute.ratio,
          rightPanelParams.mockExamRoundCount.ratio,
        ]}
        enableDataHover
        onDataHover={handleGoalRingHover}
      />
    </Box>
  );
  return (
    <BaseLayoutRightPanel
      titleIcon={<OtherCalendarDayIcon {...iconProps} />}
      title={title}
    >
      <PanelContent
        scrollableNodeProps={{ ref: scrollRef }}
        topComponent={mdUp && goalRingRender}
      >
        {!mdUp && goalRingRender}
        <Box sx={styles.cards}>
          <Box
            sx={styles.fill}
            ref={(el: HTMLDivElement) => {
              cardsRef.current[0] = el;
            }}
          >
            <NumberCard
              sx={[activeIndex === 0 && styles.activeCard]}
              title={t('goal.Questions Practiced')}
              titleIcon="ChatQuestionList"
              borderLeft="primary.dark"
            >
              <NumberCard.Tooltip
                titleIcon="ChatQuestionList"
                title={t('tooltip.goal.Questions Practiced')}
                content={t('tooltip.goal.Questions Practiced_body')}
              />
              <NumberCard.Value
                suffix={` / ${rightPanelParams.questionCount.target}`}
              >
                {rightPanelParams.questionCount.current}
              </NumberCard.Value>
              <NumberCard.Description>
                {t('goal.Completed')}
              </NumberCard.Description>
            </NumberCard>
          </Box>

          <Box
            sx={styles.fill}
            ref={(el: HTMLDivElement) => {
              cardsRef.current[1] = el;
            }}
          >
            <NumberCard
              sx={[activeIndex === 1 && styles.activeCard]}
              title={t('goal.Time Practiced')}
              titleIcon="TestClock"
              borderLeft="primary.light"
            >
              <NumberCard.Tooltip
                titleIcon="TestClock"
                title={t('tooltip.goal.Time Practiced')}
                content={t('tooltip.goal.Time Practiced_body')}
              />
              <NumberCard.Value
                suffix={` / ${rightPanelParams.practiceTimeMinute.target}`}
              >
                {roundUpWithoutDecimal(
                  rightPanelParams.practiceTimeMinute.current
                )}
              </NumberCard.Value>
              <NumberCard.Description>
                {t('goal.Completed')}
              </NumberCard.Description>
            </NumberCard>
          </Box>

          <Box
            sx={styles.fill}
            ref={(el: HTMLDivElement) => {
              cardsRef.current[1] = el;
            }}
          >
            <NumberCard
              sx={[activeIndex === 2 && styles.activeCard]}
              title={t('goal.Mock Exams')}
              titleIcon="OtherCheckedCircleSolid"
              borderLeft="text.primary"
            >
              <NumberCard.Tooltip
                titleIcon="OtherCheckedCircleSolid"
                title={t('tooltip.goal.Mock Exams Completed')}
                content={t('tooltip.goal.Mock Exams Completed_body')}
              />
              <NumberCard.Value
                suffix={` / ${rightPanelParams.mockExamRoundCount.target}`}
              >
                {rightPanelParams.mockExamRoundCount.current}
              </NumberCard.Value>
              <NumberCard.Description>
                {t('goal.Completed')}
              </NumberCard.Description>
            </NumberCard>
          </Box>
        </Box>
      </PanelContent>
    </BaseLayoutRightPanel>
  );
}
