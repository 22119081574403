import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import { popup } from '@front/ui';
import { apis } from '@lib/web/apis';
import { callWithToast } from '@lib/web/utils';

const getAgentUrl = ({ clubSlug, id }: { clubSlug?: string; id: string }) => {
  if (!clubSlug) console.warn(`Cannot find agent id club : ${id}`);
  return clubSlug ? `/club/${clubSlug}/agent/form/${id}/settings` : '/';
};

export const useIaAgentActions = () => {
  const { t } = useTranslation('agent');

  return useMemo(
    () => ({
      openEditAgentPage: ({
        id,
        clubSlug,
      }: {
        id: string;
        clubSlug: string;
      }) => {
        void Router.push(getAgentUrl({ clubSlug, id }));
      },

      duplicateAgent: async ({
        id,
        clubSlug,
      }: {
        id: string;
        clubSlug: string;
        toastAnchorEl?: HTMLElement | null;
      }) => {
        void Router.push(
          `/club/${clubSlug}/agent/form/settings?&duplicateFrom=${id}`
        );
      },

      addMyAgent: async ({
        id,
        toastAnchorEl,
      }: {
        id: string;
        toastAnchorEl?: HTMLElement | null;
      }) => {
        return callWithToast(() => apis.agent.addMyAgent({ agentId: id }), {
          errorMsg: 'Add Failed',
          anchorEl: toastAnchorEl || undefined,
        });
      },

      removeMyAgent: async ({
        id,
        toastAnchorEl,
      }: {
        id: string;
        toastAnchorEl?: HTMLElement | null;
      }) => {
        const confirmed = await popup.promise({
          content: t(
            'club.bot.remove.tooltip',
            "By removing this bot, you won't be able to use the bot again until you add it back."
          ),
          confirmText: 'Remove',
          cancelIcon: 'close',
          anchorEl: toastAnchorEl || undefined,
        });
        if (confirmed) {
          return callWithToast(
            () => apis.agent.removeMyAgent({ agentId: id }),
            {
              errorMsg: 'Unadd Failed',
              anchorEl: toastAnchorEl || undefined,
            }
          );
        }
      },
    }),
    [t]
  );
};
