import { useMemo } from 'react';
import { useMemberNftAvatar } from '@app/web/src/hooks/utils/useMemberNftAvatar';
import { useDateFormat } from '@front/helper';
import { IaLayoutConfig } from '@lib/ia/src/layouts/IaLayouts/types';
import {
  ProfileLayoutConfig,
  ProfileLayoutLoadingConfig,
} from '@lib/ia/src/layouts/ProfileLayout/types';
import { AgentIsAdd } from '@lib/web/apis';
import { getAgentIconPath, getClubIconPath } from '@lib/web/utils';

export const useAgentProfilePanelLayoutConfig = ({
  agent,
}: {
  agent?: GetIaAgentProfileRhsAgentDefaultRes;
}): IaLayoutConfig[] => {
  const creator = useMemberNftAvatar({ userId: agent?.agentCreator });
  const { displayDateFormat } = useDateFormat();

  return useMemo(() => {
    if (!agent) {
      return [
        {
          layout: 'profile-layout-skeleton',
        } as ProfileLayoutLoadingConfig,
      ];
    }

    return [
      {
        layout: 'profile-layout',
        areas: [
          {
            areaType: 'avatar',
            key: 'avatar',
            avatar: {
              url: agent.agentPhoto || getAgentIconPath(agent.agentName),
              title: agent.agentName,
              subtitle: agent.agentUserName,
            },
            properties: [
              {
                name: 'Users',
                value: agent.userCount,
              },
              {
                name: 'Threads',
                value: agent.threadCount,
              },
            ],
            buttons: [
              agent.isAdd === AgentIsAdd.IsAdded
                ? {
                    key: 'removeAgentButton',
                    type: 'event',
                    value: 'removeMyAgent',
                    buttonVariant: 'outlined',
                    buttonColor: 'success',
                    icon: 'EditorAI',
                    text: 'Added',
                    fullWidth: true,
                  }
                : {
                    key: 'addAgentButton',
                    type: 'event',
                    value: 'addMyAgent',
                    buttonVariant: 'filled',
                    buttonColor: 'default',
                    icon: 'EditorAI',
                    text: 'Add Bot',
                    fullWidth: true,
                  },
              // This is future features (Thread)
              // {
              //   key: v4(),
              //   type: 'event',
              //   value: 'messageAgent',
              //   buttonVariant: 'filled',
              //   buttonColor: 'default',
              //   icon: 'ThreadsDirectMessages',
              //   text: 'Message',
              // },
            ],
          },
          {
            areaType: 'detail',
            key: 'detail',
            propertyTypography: 'body1',
            properties: [
              {
                key: 'club',
                iconType: 'image',
                icon:
                  agent.agentClub.clubLogo ||
                  getClubIconPath(agent.agentClub.clubName),
                text: agent.agentClub.clubName,
              },
              {
                key: 'intro',
                iconType: 'emoji',
                icon: '👋',
                text: agent.shortDesc,
              },
              {
                key: 'createdAt',
                iconType: 'emoji',
                icon: '🗓',
                text: `Created ${displayDateFormat(agent.createdAt)}`,
              },
              {
                key: 'createdBy',
                iconType: 'emoji',
                icon: '🤓',
                text: [
                  {
                    type: 'text',
                    value: 'Created by',
                  },
                  {
                    type: 'user',
                    id: agent.agentCreator,
                    name: creator.nftDisplay,
                    src: creator.nftAvatar,
                    action: {
                      type: 'event',
                      value: 'openUserProfile',
                    },
                  },
                ],
              },
              {
                key: 'longDesc',
                text: agent.longDesc,
              },
            ],
          },
          {
            areaType: 'detail',
            key: 'shortcuts',
            title: 'Shortcuts',
            titleTypography: 'body1',
            propertyTypography: 'body2',
            properties:
              agent.agentPrompts?.map((prompt) => ({
                key: prompt.promptId,
                iconType: 'emoji',
                icon: prompt.promptIcon,
                text: prompt.promptName,
              })) || [],
          },
        ],
      } as ProfileLayoutConfig,
    ];
  }, [agent, creator.nftAvatar, creator.nftDisplay, displayDateFormat]);
};
