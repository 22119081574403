import { useTranslation } from 'react-i18next';
import {
  OtherFollowers as OtherFollowersIcon,
  OtherFollowing as OtherFollowingIcon,
  TestTrophy as TestTrophyIcon,
} from '@front/icon';
import { Tab, Tabs } from '@front/ui';

import FriendRankingTabContent from './components/FriendRankingTabContent';
import LeagueRankingTabContent from './components/LeagueRankingTabContent';

const styles = {
  tabs: {
    '& .MuiTabs-flexContainer': {
      px: {
        md: '12px',
        xs: '20px',
      },
    },
  },
};

type RankingsContentProps = {
  tab: number;
  changeTab: (tab: number) => void;
};

export default function RankingsContent({
  tab,
  changeTab,
}: RankingsContentProps) {
  const { t } = useTranslation('club');

  return (
    <>
      <Tabs
        scrollButtons={false}
        value={tab}
        textColor="inherit"
        variant="scrollable"
        sx={styles.tabs}
        onChange={(_, index) => changeTab(index)}
        className="ranking-tabs"
      >
        <Tab
          prefixIcon={<TestTrophyIcon width={16} height={16} />}
          label={t('League')}
        />
        <Tab
          prefixIcon={<OtherFollowingIcon width={16} height={16} />}
          label={t('Following')}
        />
        <Tab
          prefixIcon={<OtherFollowersIcon width={16} height={16} />}
          label={t('Followers')}
        />
      </Tabs>
      {tab === 0 && <LeagueRankingTabContent />}
      {tab === 1 && <FriendRankingTabContent variant="following" />}
      {tab === 2 && <FriendRankingTabContent variant="follower" />}
    </>
  );
}
