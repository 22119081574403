import { MouseEvent } from 'react';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { Mention, useBaseRightPanel } from '@front/ui';
import { useThread } from '@lib/web/thread/hooks/core/useThread';

export default function ThreadMentionUser({
  memberId,
  userId,
  agentId,
}: {
  memberId: string;
  userId?: string;
  agentId?: string;
}) {
  const { getThreadUser } = useThread();
  const mentionUser = getThreadUser(memberId);
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();

  const handleOpenProfile = (ev: MouseEvent) => {
    ev.stopPropagation();
    if (userId) {
      openRightPanel(GlobalPanelKeys.GlobalProfile, { userId });
    }
    if (agentId) {
      openRightPanel(GlobalPanelKeys.GlobalAgentProfile, { agentId });
    }
  };
  return (
    <Mention
      src={mentionUser?.image}
      display={mentionUser?.distinctName}
      indicators={
        mentionUser && 'indicators' in mentionUser
          ? mentionUser.indicators
          : undefined
      }
      prefix="@"
      onClick={handleOpenProfile}
    />
  );
}
