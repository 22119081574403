import { MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import {
  alpha,
  Box,
  ButtonBase,
  Skeleton,
  Theme,
  Typography,
} from '@mui/material';
import {
  ChallengeButton,
  FollowButton,
} from '@app/web/src/components/FriendButtons';
import { GlobalPanelKeys } from '@app/web/src/types/panel';
import { TestScratch as TestScratchIcon } from '@front/icon';
import {
  BaseLayoutRightPanel,
  EmphasizeButton,
  useBaseLayout,
  UserAvatar,
} from '@front/ui';
import { useAuth, useMemberInfo, useUserProfileSocial } from '@lib/web/apis';
import { getIndicators } from '@lib/web/utils';
import format from 'date-fns/format';

interface ProfileProps {
  userId?: string;
  memberId?: string;
}

const styles = {
  avatar: {
    gap: 2,
    alignItems: 'flex-end',
    '& .MuiTypography-body1': {
      fontSize: 20,
      fontWeight: 700,
    },
    '& .MuiTypography-body2': {
      fontSize: 14,
      mb: 0,
    },
  },
  userData: {
    display: 'flex',
    gap: 0,
    mt: 2,
  },
  userDataButton: {
    px: '4px',
    py: '2px',
    borderRadius: 1,
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
    display: 'flex',
    gap: '5px',
    fontSize: 16,
    lineHeight: 1.5,
    '& .infoItemValue': {
      color: (theme: Theme) => theme.palette.text.primary,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.5,
    },
    '&:hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    },
  },
  actions: {
    mt: 3,
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    '& .em-button-base': {
      px: 'unset',
    },
  },
  button: {
    width: '100%',
    minWidth: 0,
    flex: 1,
  },
  bioContainer: {
    mt: 5,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    typography: 'body2',
  },
};

interface UserDataProps {
  value: number;
  label: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

function UserData({ value, label, onClick }: UserDataProps) {
  return (
    <ButtonBase sx={styles.userDataButton} onClick={onClick}>
      <Typography variant="body1" className="infoItemValue">
        {value}
      </Typography>
      <Typography variant="body1">{label}</Typography>
    </ButtonBase>
  );
}

export default function Profile({
  userId: profileUserId,
  memberId,
}: ProfileProps) {
  const { data: userData, mutate: userMutate } = useMemberInfo(
    profileUserId,
    memberId
  );
  const { data: socialData, mutate: socialMutate } = useUserProfileSocial(
    profileUserId || userData?.data.userId
  );
  const { member } = useAuth();
  const { mutate: currentUserSocialMutate } = useUserProfileSocial(
    member?.userId
  );

  const { t } = useTranslation('profile');
  const { openRightPanel } = useBaseLayout();

  useEffect(() => {
    userMutate();
  }, [userMutate]);

  if (!userData || !socialData) {
    return (
      <BaseLayoutRightPanel.Content>
        <UserAvatar sx={styles.avatar} title="" size="xl" />
        <Box sx={styles.userData}>
          <Skeleton width="100%" />
        </Box>
      </BaseLayoutRightPanel.Content>
    );
  }

  const { userId: fetchedUserId } = userData.data;
  const { isMe, isFollowing, isFollower } = socialData.data;

  const userId = profileUserId || fetchedUserId;

  const handleClick = () => {
    Router.push(`/profile/account/edit`, undefined, { shallow: true });
  };

  return (
    <BaseLayoutRightPanel.ScrollArea>
      <BaseLayoutRightPanel.Content pt={1}>
        <UserAvatar
          sx={styles.avatar}
          src={userData.data.nftAvatar || userData.data.avatar}
          title={userData.data.displayName || userData.data.distinctName}
          subTitle={`@${userData.data.distinctName}`}
          size="xl"
          indicators={getIndicators(userData.data.indicator)}
        />
        <Box sx={styles.userData}>
          <UserData
            onClick={() =>
              openRightPanel(GlobalPanelKeys.GlobalClubs, {
                userId,
              })
            }
            value={socialData.data.numJoinedClubs}
            label={t('Clubs')}
          />
          <UserData
            onClick={() =>
              openRightPanel(GlobalPanelKeys.GlobalFollowers, {
                userId,
              })
            }
            value={socialData?.data.numFollowers}
            label={t('Followers')}
          />
          <UserData
            onClick={() =>
              openRightPanel(GlobalPanelKeys.GlobalFollowing, {
                userId,
              })
            }
            value={socialData?.data.numFollowings}
            label={t('Following')}
          />
        </Box>
        {!isMe ? (
          <Box sx={styles.actions}>
            <ChallengeButton
              sx={styles.button}
              userId={socialData.data.userId}
            />
            <FollowButton
              userId={socialData.data.userId}
              isFollower={isFollower}
              isFollowing={isFollowing}
              onChange={async () => {
                await socialMutate();
                currentUserSocialMutate();
              }}
            />
          </Box>
        ) : (
          <Box sx={styles.actions}>
            <EmphasizeButton
              sx={styles.button}
              prefixIcon={<TestScratchIcon />}
              color={'default'}
              onClick={handleClick}
            >
              {t('Edit Profile')}
            </EmphasizeButton>
          </Box>
        )}
        <Box sx={styles.bioContainer}>
          {!socialData && (
            <Box>
              <Skeleton width="100%" />
              <Skeleton width="100%" />
            </Box>
          )}

          {!!socialData && (
            <>
              {Boolean(socialData.data.city && socialData.data.country) && (
                <Box>
                  📍 {socialData.data.city}, {socialData.data.country}
                </Box>
              )}

              {Boolean(socialData.data.highSchoolGraduationYear) && (
                <Box>
                  🎓 Graduating in {socialData.data.highSchoolGraduationYear}
                </Box>
              )}

              {Boolean(socialData.data.joinedAt) && (
                <Box>
                  🗓 Member since{' '}
                  {format(new Date(socialData.data.joinedAt), 'MMMM yyyy')}
                </Box>
              )}

              {Boolean(socialData.data.dreamSchoolName) && (
                <Box>🤞 {socialData.data.dreamSchoolName}</Box>
              )}

              {Boolean(socialData.data.bio) && <Box>{socialData.data.bio}</Box>}
            </>
          )}
        </Box>
      </BaseLayoutRightPanel.Content>
    </BaseLayoutRightPanel.ScrollArea>
  );
}
