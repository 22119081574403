import { useTranslation } from 'react-i18next';
import { Box, Chip, Theme, useMediaQuery } from '@mui/material';
import {
  EditorDifficultyEasy as EditorDifficultyEasyIcon,
  EditorDifficultyHard as EditorDifficultyHardIcon,
  EditorDifficultyMedium as EditorDifficultyMediumIcon,
  EditorDifficultySuper as EditorDifficultySuperIcon,
} from '@front/icon';
import { SimpleTooltip } from '@front/ui';
import { Difficulty } from '@lib/web/apis';

import { Placement } from '../CreateQuizForm';
import useCreateQuizAction from '../hooks/useCreateQuizAction';
import useCreateQuizState from '../hooks/useCreateQuizState';
import useStartQuizBlocker from '../hooks/useStartQuizBlocker';

import { StartQuizBlockerType } from './StartQuizPaidFutureBlocker';

const styles = {
  root: {
    display: 'flex',
    gap: 1,
    width: '100%',
    flexWrap: 'wrap',
  },
  chip: {
    '& svg': {
      width: '16px',
      height: '16px',
    },
    '& .MuiChip-label': {
      pl: '8px',
    },
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      maxWidth: '216px',
    },
  },
};

const OPTIONS = [
  {
    value: Difficulty.Easy,
    icon: <EditorDifficultyEasyIcon />,
    tooltipKey: 'tooltip.difficulty.1',
  },
  {
    value: Difficulty.Medium,
    icon: <EditorDifficultyMediumIcon />,
    tooltipKey: 'tooltip.difficulty.2',
  },
  {
    value: Difficulty.Hard,
    icon: <EditorDifficultyHardIcon />,
    tooltipKey: 'tooltip.difficulty.3',
  },
  {
    value: Difficulty.Super,
    icon: <EditorDifficultySuperIcon />,
    tooltipKey: 'tooltip.difficulty.4',
    freeUserTooltipKey: 'tooltip.freeUser.difficulty.4',
  },
];

function ChipOption({
  isLoading,
  isFeatureBlocked,
  item,
  selected,
  disabled,
  onSelect,
}: {
  isFeatureBlocked: boolean;
  isLoading: boolean;
  selected: boolean;
  item: (typeof OPTIONS)[number];
  disabled?: boolean;
  onSelect: (option: Difficulty) => void;
}) {
  const { t } = useTranslation('quiz');
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const content = (
    <Chip
      label={t(`difficulty.${item.value}`)}
      icon={item.icon}
      variant={selected ? 'filled' : 'outlined'}
      clickable
      onClick={() => onSelect(item.value)}
      sx={styles.chip}
      disabled={isLoading || disabled}
    />
  );

  if (mdUp) {
    return (
      <SimpleTooltip
        title={
          isFeatureBlocked
            ? t(item.freeUserTooltipKey || item.tooltipKey)
            : t(item.tooltipKey)
        }
        slotProps={{
          popper: {
            sx: styles.tooltip,
          },
        }}
      >
        {content}
      </SimpleTooltip>
    );
  }
  return content;
}

export default function DifficultyOptions({
  disabled,
  placement,
}: {
  disabled?: boolean;
  placement: Placement;
}) {
  const { values } = useCreateQuizState();
  const { updateQuizValues } = useCreateQuizAction();
  const difficulties = values.difficultyAry || [Difficulty.Easy];

  const { showBlocker, isFeatureBlocked, isLoading } =
    useStartQuizBlocker(placement);

  const handleSelect = (option: Difficulty) => {
    if (isFeatureBlocked && option === Difficulty.Super) {
      showBlocker(StartQuizBlockerType.DifficultySuperLevel);
      return;
    }
    if (difficulties.includes(option)) {
      updateQuizValues({
        difficultyAry: difficulties.filter((item) => item !== option),
      });
      return;
    }
    updateQuizValues({
      difficultyAry: [...difficulties, option],
    });
  };

  return (
    <Box sx={styles.root}>
      {OPTIONS.map((item) => (
        <ChipOption
          key={item.value}
          item={item}
          isLoading={isLoading}
          isFeatureBlocked={isFeatureBlocked}
          selected={!disabled && difficulties.includes(item.value)}
          onSelect={handleSelect}
          disabled={disabled}
        />
      ))}
    </Box>
  );
}
