import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import GoalRing from '@app/web/src/components/GoalRing';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { NumberCard, useBaseRightPanel } from '@front/ui';

const styles = {
  card: { flex: 1, maxWidth: 255 },
  goalRing: {
    position: 'absolute',
    right: -30,
    bottom: -30,
  },
};

type CountValue = { current: number; ratio: number; target: number };

type GoalRingCardProps = {
  type: NotificationGoalRingTypes;
  mockExamRoundCount: CountValue;
  practiceTimeMinute: CountValue;
  questionCount: CountValue;
  scheduledAt: string;
};

export default function GoalRingCard({
  type,
  mockExamRoundCount,
  practiceTimeMinute,
  questionCount,
  scheduledAt,
}: GoalRingCardProps) {
  const { t } = useTranslation('notification');
  const { openRightPanel, closeRightPanel, getRightParams, rightPanelOpened } =
    useBaseRightPanel<GlobalPanelParams>();

  const params = getRightParams(GlobalPanelKeys.GlobalGoalRing);
  const selected = params.date === scheduledAt && rightPanelOpened;

  const goalRingRender = (
    <Box sx={styles.goalRing}>
      <GoalRing
        themeMode={selected ? 'light' : 'dark'}
        dataset={[
          questionCount.ratio,
          practiceTimeMinute.ratio,
          mockExamRoundCount.ratio,
        ]}
        disableDefault
      />
    </Box>
  );

  const cardProps = {
    sx: styles.card,
    title: t('Goal Ring'),
    titleIcon: 'MainSetGoal',
    selected,
    disableTouchRipple: true,
    onClick: () =>
      selected
        ? closeRightPanel()
        : openRightPanel(GlobalPanelKeys.GlobalGoalRing, {
            date: scheduledAt,
            mockExamRoundCount,
            practiceTimeMinute,
            questionCount,
          }),
  };

  if (type === 'cta.goal.ring.complete.one') {
    let count = 0;
    if (mockExamRoundCount.ratio >= 1) count++;
    if (practiceTimeMinute.ratio >= 1) count++;
    if (questionCount.ratio >= 1) count++;
    return (
      <NumberCard {...cardProps}>
        <NumberCard.Tooltip content={t('tooltip.goal-ring.completed-rings')} />
        <NumberCard.Value suffix=" / 3">{count}</NumberCard.Value>
        <NumberCard.Description>{t('Completed')}</NumberCard.Description>
        {goalRingRender}
      </NumberCard>
    );
  }

  if (type === 'cta.goal.ring.complete.all')
    return (
      <NumberCard {...cardProps}>
        <NumberCard.Value suffix=" / 3">3</NumberCard.Value>
        <NumberCard.Description>{t('Completed')}</NumberCard.Description>
        {goalRingRender}
      </NumberCard>
    );

  if (type === 'cta.goal.ring.progress.question')
    return (
      <NumberCard {...cardProps} title={t('Questions Practiced')}>
        <NumberCard.Value suffix=" %">
          {(questionCount.ratio * 100).toFixed(0)}
        </NumberCard.Value>
        <NumberCard.Description>{t('Completed')}</NumberCard.Description>
        {goalRingRender}
      </NumberCard>
    );

  if (type === 'cta.goal.ring.progress.mock')
    return (
      <NumberCard {...cardProps} title={t('Mock Exam')}>
        <NumberCard.Value suffix=" %">
          {(mockExamRoundCount.ratio * 100).toFixed(0)}
        </NumberCard.Value>
        <NumberCard.Description>{t('Completed')}</NumberCard.Description>
        {goalRingRender}
      </NumberCard>
    );

  if (type === 'cta.goal.ring.progress.time')
    return (
      <NumberCard {...cardProps} title={t('Time Practiced')}>
        <NumberCard.Value suffix=" %">
          {(practiceTimeMinute.ratio * 100).toFixed(0)}
        </NumberCard.Value>
        <NumberCard.Description>{t('Completed')}</NumberCard.Description>
        {goalRingRender}
      </NumberCard>
    );
  return null;
}
