import { useCallback, useContext, useEffect, useState } from 'react';
import useThreadSortFilter from '@app/web/src/hooks/utils/useThreadSortFilter';
import { ElementPresenceProvider } from '@front/helper';
import {
  BaseLayoutAction,
  BaseLayoutContainer,
  BaseLayoutScrollArea,
} from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaRenderContextProvider from '@lib/ia/src/core/IaRender/IaRenderProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { ThreadViewType, useAuth } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import { useReversedInfiniteScroll } from '@lib/web/hooks';
import {
  ThreadComposerContextValue,
  ThreadCurrentLocationContext,
} from '@lib/web/thread';
import { ThreadComposerProvider } from '@lib/web/thread/contexts/threadComposerContext';
import { useCreateNewChannelWithMessage } from '@lib/web/thread/hooks/channel/useCreateNewChannelWithMessage';
import { useChannelsHierarchyDisplay } from '@lib/web/thread/hooks/channels/useChannelsHierarchyDisplay';
import { useFilteredChannels } from '@lib/web/thread/hooks/channels/useFilteredChannels';
import { useMaybeMarkChannelsRead } from '@lib/web/thread/hooks/channels/useMaybeMarkChannelsRead';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { useMaybeRedirectUserToNewDmView } from '@lib/web/thread/hooks/view/useMaybeRedirectUserToNewDmView';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';
import ThreadComposer from '@lib/web/thread/ThreadComposer';
import ThreadComposerRenderer from '@lib/web/thread/ThreadTextComposer/ThreadComposerRenderer';

import useSortActions from '../../../CommonPanels/SortResponsivePanel/hooks/useSortActions';
import useSetFilterSortConditions from '../../hooks/useSetFilterSortConditions';
import useThreadRender from '../../hooks/useThreadRender';
import ThreadMentionUser from '../ThreadMentionUser';
import ThreadViewTitle from '../ThreadViewTitle';

import { useThreadViewChannelAvailableAction } from './hooks/useThreadViewChannelAvailableAction';
import { useThreadViewChannelLayoutConfig } from './hooks/useThreadViewChannelLayoutConfig';

const styles = {
  pageTitle: {
    px: 2.5,
    '.base-layout-right-panel &': {
      px: 1.5,
    },
    mb: 3,
  },
  scroll: {
    '& .simplebar-content': {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
};

export type ThreadViewChannelLayoutProps = {
  view: GetThreadViewRes;
  clubId?: string;
  scope?: string;
};

export default function ThreadViewChannelLayout({
  view,
  clubId,
  scope,
}: ThreadViewChannelLayoutProps) {
  const { resetThreadChannelSortFilter } = useThread();
  const { channelData, viewMemberIds } = useThreadViewDetail(view);

  const { useChannelSortFilter } = useThreadSortFilter(scope);
  const { key, sort, resetSort, filters, resetFilter } =
    useChannelSortFilter(view);

  const { reset: resetSortAction } = useSortActions();

  const { setDefaultSortCriteria, defaultFilterSortReady } =
    useSetFilterSortConditions({
      type: 'channel',
      view,
      viewMemberIds,
      scope,
    });

  const initStatus = Object.keys(sort).length === 1 && sort.created_at === 1; // Channel Layout init sort condition
  const channelsData = useFilteredChannels({
    scope: 'centre-channel-view',
    key,
    filters,
    sort: initStatus ? { created_at: -1 } : sort, // init should get data from 'desc' order, because UI will render from the chatroom bottom
    enable: defaultFilterSortReady,
  });
  const {
    data,
    dataset: rawChannels,
    isLoadingInitialData,
    isLoadingMore,
  } = channelsData;

  const { firstLevelChannels: channels } = useChannelsHierarchyDisplay({
    channels: rawChannels,
  });

  const { scrollRef } = useReversedInfiniteScroll({
    infiniteHookResponse: channelsData,
  });

  const { isBlocking, blockerTip } = useAuth();
  const { createNewChannelWithMessage } = useCreateNewChannelWithMessage();
  const { maybeRedirectUserToNewDmView } = useMaybeRedirectUserToNewDmView();

  const [text, setText] = useState('');
  const [loaded, setLoaded] = useState(false);
  const { defaultSendPublicly } = useContext(ThreadCurrentLocationContext);
  const renders = useThreadRender();
  const config = useThreadViewChannelLayoutConfig({
    channels,
    isLoadingInitialData,
    sort,
  });
  const availableActions = useThreadViewChannelAvailableAction();

  useMaybeMarkChannelsRead({ channels, refToCheckVisibility: scrollRef });

  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
      });
    }
  }, [scrollRef]);

  const handleSubmit = useCallback<
    (props: {
      contextValue: ThreadComposerContextValue;
      blocks: ComposerBlock[];
    }) => Promise<void>
  >(
    async ({ contextValue: { sendPublicly, aiActionState }, blocks }) => {
      setText('');

      const newChannelResult = await createNewChannelWithMessage({
        message: text,
        blocks,
        sendPublicly,
        channelData,
        agentId: aiActionState.selectedAgentId,
      });

      if (!newChannelResult) {
        return;
      }

      resetThreadChannelSortFilter.current();

      if (newChannelResult.viewAfterId) {
        await maybeRedirectUserToNewDmView({
          channel: newChannelResult.newChannel,
          newViewId: newChannelResult.viewAfterId,
        });
      }

      setTimeout(() => {
        // wait for message appear
        scrollToBottom();
      });
    },
    [
      channelData,
      createNewChannelWithMessage,
      maybeRedirectUserToNewDmView,
      resetThreadChannelSortFilter,
      scrollToBottom,
      text,
    ]
  );

  useEffect(() => {
    if (data && !isLoadingInitialData && !isLoadingMore && !loaded) {
      setLoaded(true);
      scrollToBottom();
    }
  }, [data, isLoadingInitialData, isLoadingMore, loaded, scrollToBottom]);

  useEffect(() => {
    resetThreadChannelSortFilter.current = (): void => {
      resetSort();
      resetSortAction();
      resetFilter();

      setDefaultSortCriteria(); // sort criteria will be clear, so set default sort criteria here
    };
  }, [
    resetFilter,
    resetSort,
    resetSortAction,
    resetThreadChannelSortFilter,
    setDefaultSortCriteria,
  ]);

  return (
    <IaRenderContextProvider value={renders}>
      <IaActionContextProvider availableActions={availableActions}>
        <BaseLayoutScrollArea sx={styles.scroll} scrollRef={scrollRef}>
          <ElementPresenceProvider elementRef={scrollRef}>
            <ThreadComposerRenderer
              styleProvider
              mentionRender={ThreadMentionUser}
            >
              <IaLayouts layouts={config} />
            </ThreadComposerRenderer>
          </ElementPresenceProvider>
          <BaseLayoutContainer fullWidth sx={styles.pageTitle}>
            <ThreadViewTitle view={view} clubId={clubId} />
          </BaseLayoutContainer>
        </BaseLayoutScrollArea>
        <BaseLayoutAction sx={{ p: 0 }}>
          <ThreadComposerProvider
            text={text}
            setText={setText}
            sendPubliclyCheckboxEnable={
              view.type === ThreadViewType.ClubAllThread ||
              view.type === ThreadViewType.Club ||
              view.type === ThreadViewType.DmEveryone ||
              view.type === ThreadViewType.Location
            }
            sendPubliclyDefault={
              view.type === ThreadViewType.DmEveryone || defaultSendPublicly
            }
            sendPubliclyDisabled={view.type === ThreadViewType.DmEveryone}
            threadMemberIds={viewMemberIds}
          >
            {(contextValue) => (
              <ThreadComposer
                autoFocus
                onChange={setText}
                onSubmit={({ blocks }) =>
                  handleSubmit({ contextValue, blocks })
                }
                disabled={isBlocking}
                disabledTip={blockerTip}
              />
            )}
          </ThreadComposerProvider>
        </BaseLayoutAction>
      </IaActionContextProvider>
    </IaRenderContextProvider>
  );
}
