import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import useCreateQuizAction from '@app/web/src/widgets/PracticePage/CreateQuizForm/hooks/useCreateQuizAction';
import {
  ActionClear as ActionClearIcon,
  OtherAddFriend as OtherAddFriendIcon,
  OtherPlay as OtherPlayIcon,
} from '@front/icon';
import {
  BaseLayoutContainer,
  BaseLayoutRightPanel,
  BaseLayoutScrollArea,
  EmphasizeButton,
  TipButton,
  toast,
  useBaseLayout,
  useBaseRightPanel,
} from '@front/ui';

import CreateQuizForm from '../../../PracticePage/CreateQuizForm';
import StartQuizButton from '../../../PracticePage/CreateQuizForm/components/StartQuizButton';
import useCreateQuizFormReset from '../../../PracticePage/CreateQuizForm/hooks/useCreateQuizFormReset';
import useCreateQuizState from '../../../PracticePage/CreateQuizForm/hooks/useCreateQuizState';
import getActiveQuizButtonEl from '../../../PracticePage/CreateQuizForm/utils/getActiveQuizButtonEl';

const styles = {
  resetButton: {
    marginLeft: 'auto',
  },
  scroll: {
    height: '100%',
  },
  container: {
    px: '12px',
    pt: 1,
  },
};

function AddChallengersButton({ loading }: { loading?: boolean }) {
  const { toggleRightPanel } = useBaseLayout<GlobalPanelParams>();

  return (
    <EmphasizeButton
      prefixIcon={<OtherAddFriendIcon />}
      onClick={() =>
        toggleRightPanel(GlobalPanelKeys.GlobalStartQuizChallengers)
      }
      loading={loading}
    >
      Add Challengers
    </EmphasizeButton>
  );
}

export default function StartQuizPanel() {
  const { t } = useTranslation('quiz');
  const router = useRouter();
  const { challengeId, isDirty, isChallengersLoading } = useCreateQuizState();
  const { resetAllSettings } = useCreateQuizAction();
  const { isTargetPanelOpened, getRightParams } =
    useBaseRightPanel<GlobalPanelParams>();
  const isStartQuizPanelOpened = isTargetPanelOpened(
    GlobalPanelKeys.GlobalStartQuiz
  );
  const { isChallengeMode } = getRightParams(GlobalPanelKeys.GlobalStartQuiz);

  const resetForm = useCreateQuizFormReset();
  const challengersDisabled = !!challengeId;
  useEffect(() => {
    toast.dismiss();
  }, [router]);

  const clearAll = () => {
    resetForm();
    toast.success(t('Customization has been reset successfully'), {
      anchorEl: getActiveQuizButtonEl(),
    });
  };

  useEffect(() => {
    if (!isStartQuizPanelOpened) resetAllSettings();
  }, [isStartQuizPanelOpened, resetAllSettings]);

  return (
    <BaseLayoutRightPanel
      titleIcon={<OtherPlayIcon width="15" height="15" />}
      title={t('panel.Start')}
      toolComponent={
        challengersDisabled || !isDirty ? undefined : (
          <TipButton
            title={t('toolbar.Reset')}
            onClick={clearAll}
            sx={styles.resetButton}
          >
            <ActionClearIcon />
          </TipButton>
        )
      }
      actionComponent={
        isChallengeMode ? (
          <AddChallengersButton loading={isChallengersLoading} />
        ) : (
          <StartQuizButton />
        )
      }
    >
      <BaseLayoutScrollArea sx={styles.scroll}>
        <BaseLayoutContainer sx={styles.container} fullWidth>
          <CreateQuizForm hideChallenge={isChallengeMode} />
        </BaseLayoutContainer>
      </BaseLayoutScrollArea>
    </BaseLayoutRightPanel>
  );
}
