import useCreateQuizAction from './useCreateQuizAction';
import useCreateQuizState from './useCreateQuizState';

export default function useCreateQuizFormReset() {
  const {
    values,
    defaultValues,
    challengeId,
    timerDisabled,
    difficultyDisabled,
    hashtagDisabled,
    totalQuestionDisabled,
    incognitoDisabled,
  } = useCreateQuizState();

  const { updateQuizValues, setIsOfficial, setIsRandomChallengers } =
    useCreateQuizAction();

  const challengersDisabled = !!challengeId;

  return () => {
    updateQuizValues({
      questionCount: totalQuestionDisabled
        ? values.questionCount
        : defaultValues.questionCount,
      tags: hashtagDisabled ? values.tags : defaultValues.tags,
      marks: hashtagDisabled ? values.marks : defaultValues.marks,
      challengers: challengersDisabled
        ? values.challengers
        : values.challengers.filter(
            (challenger) => !!challenger.metadata?.disabledUpdate
          ),
      mode: defaultValues.mode,
      isOpenTimer: timerDisabled
        ? values.isOpenTimer
        : defaultValues.isOpenTimer,
      difficultyAry: difficultyDisabled
        ? values.difficultyAry
        : defaultValues.difficultyAry,
      isIncognito: incognitoDisabled
        ? values.isIncognito
        : defaultValues.isIncognito,
      randomChallengerCount: challengersDisabled
        ? values.randomChallengerCount
        : defaultValues.randomChallengerCount,
    });
    setIsOfficial(false);
    setIsRandomChallengers(false);
  };
}
