import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import { nonNullable } from '@front/helper';
import { SearchBar, useBaseRightPanel } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import {
  IconListLayoutConfig,
  IconListLayoutItemObj,
} from '@lib/ia/src/layouts/IconListLayout/types';
import { useAuth } from '@lib/web/apis';
import { useUndoableAction } from '@lib/web/hooks';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { ThreadUser } from '@lib/web/thread/types';
import { sortBy } from 'lodash';

const REMOVE_MEMBER_DELAY_MS = 3000;

const styles = {
  main: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  searchBar: {
    px: 1.5,
  },
  content: {
    flex: 1,
  },
};

const matchSearchText = (user: ThreadUser, search: string) => {
  if (!search) return true;

  const searchText = search.toLowerCase();
  const nameMatch =
    user?.name && user.name.toLowerCase().indexOf(searchText) !== -1;
  const distinctNameMatch =
    user?.distinctName &&
    user.distinctName.toLowerCase().indexOf(searchText) !== -1;

  return nameMatch || distinctNameMatch;
};

export type ThreadMemberProps = {
  memberIds: string[];
  onRemoveMember?: (memberId: string) => Promise<void>;
  enableRemoveMember?: boolean;
};

export default function ThreadMembersList({
  memberIds,
  onRemoveMember,
  enableRemoveMember,
}: ThreadMemberProps) {
  const { t } = useTranslation('thread');
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const { getThreadUser } = useThread();
  const { member } = useAuth();

  const searchInputRef = useRef<HTMLInputElement>();
  const [search, setSearch] = useState('');

  const myMemberId = member?.memberId;

  const sortedFilteredMembers = useMemo(() => {
    const otherMemberIds = memberIds.filter((id) => id !== myMemberId);
    const myUser = getThreadUser(myMemberId);
    const otherUsers = otherMemberIds.map(getThreadUser);

    return [myUser, ...sortBy(otherUsers, (u) => (u?.name || '').toLowerCase())]
      .filter(nonNullable)
      .filter((u) => matchSearchText(u, search));
  }, [getThreadUser, memberIds, myMemberId, search]);

  const {
    undoableAction,
    undoAction,
    isUndoableActionInProgress,
    isAnyActionInProgress,
  } = useUndoableAction({
    undoDurationMs: REMOVE_MEMBER_DELAY_MS,
  });

  const config = useMemo(() => {
    return [
      {
        layout: 'icon-list-layout',
        items: sortedFilteredMembers.map((user) => {
          const isMe = user?.id === myMemberId;

          return {
            id: user?.type === 'user' ? user.userId : user?.agentId,
            metadata: {
              threadMemberId: user.id,
            },
            title: user?.name,
            titleAction: {
              type: 'event' as const,
              value:
                user?.type === 'user' ? 'userTitleClick' : 'agentTitleClick',
            },
            titleSuffixIcon:
              user?.type === 'agent' ? 'ProfileClubAgent' : undefined,
            titleSuffixTooltipTitle: 'Bot',
            label: isMe
              ? {
                  text: 'Me',
                  color: 'default',
                  sx: {
                    color: (theme: Theme) =>
                      alpha(theme.palette.text.primary, 0.5),
                  },
                }
              : undefined,
            description: user?.distinctName ? `@${user.distinctName}` : '',
            avatarUrl: user?.image,
            actionMap: {
              click: isUndoableActionInProgress(user.id)
                ? {
                    type: 'event' as const,
                    value: 'undoRemoveMember',
                    text: t('properties.members.undo.button'),
                    actionType: 'textButton',
                  }
                : !isMe && enableRemoveMember
                ? {
                    type: 'event' as const,
                    value: 'removeMember',
                    text: t('properties.members.remove.button'),
                    actionType: 'textButton',
                    disabled: isAnyActionInProgress,
                  }
                : undefined,
            },
          };
        }),
      } as IconListLayoutConfig,
    ];
  }, [
    enableRemoveMember,
    isAnyActionInProgress,
    isUndoableActionInProgress,
    myMemberId,
    sortedFilteredMembers,
    t,
  ]);

  const availableActions = {
    userTitleClick: {
      action: (value: IconListLayoutItemObj) => {
        openRightPanel(GlobalPanelKeys.GlobalProfile, {
          userId: value.id,
        });
        setSearch('');
      },
    },
    agentTitleClick: {
      action: (value: IconListLayoutItemObj) => {
        openRightPanel(GlobalPanelKeys.GlobalAgentProfile, {
          agentId: value.id,
        });
        setSearch('');
      },
    },
    removeMember: {
      action: async (value: IconListLayoutItemObj) => {
        const removingMemberId = value.metadata?.threadMemberId;

        if (!removingMemberId || !onRemoveMember) return;

        undoableAction(removingMemberId, async () => {
          await onRemoveMember(removingMemberId);
        });
      },
    },
    undoRemoveMember: {
      action: async (value: IconListLayoutItemObj) => {
        undoAction(value.metadata?.threadMemberId);
      },
    },
  };

  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSearch(ev.target.value);
  };

  return (
    <IaActionContextProvider availableActions={availableActions}>
      <Box sx={styles.main}>
        <Box sx={styles.searchBar}>
          <SearchBar
            inputRef={searchInputRef}
            placeholder={t('properties.members.Searchbar.placeholder')}
            value={search}
            onChange={onChange}
          />
        </Box>
        <Box sx={styles.content}>
          {config && <IaLayouts layouts={config} />}
        </Box>
      </Box>
    </IaActionContextProvider>
  );
}
