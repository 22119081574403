import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@front/ui';
import {
  apis,
  Difficulty,
  ExamMode,
  useQuizResultSummary,
} from '@lib/web/apis';
import { useClubSlug } from '@lib/web/hooks';
import { call, callWithToast, CallWithToastParams } from '@lib/web/utils';
import { format } from 'date-fns';

import getActiveQuizButtonEl from '../utils/getActiveQuizButtonEl';

import useCreateQuizState from './useCreateQuizState';

function getDifficulties(difficulties?: Difficulty[]) {
  // the user select none
  if (difficulties && difficulties.length === 0) {
    return undefined;
  }
  // return selected value or default value in case of do not touch
  return difficulties || [Difficulty.Easy];
}

type StartQuizOptions = {
  mode?: ExamMode;
  isCreateChallenge?: boolean;
};

const useValidatorCreatorQuestionQuizVersion = (quizId?: string) => {
  const [confirmHasOpened, setConfirmHasOpened] = useState(false);

  const validateCreatorQuestionQuizVersion = async () => {
    if (!quizId) return null;
    const [res, err] = await call(() => apis.quiz.practice(quizId));

    if (res) {
      // the quiz has correct response
      return quizId;
    }

    if (err.statusCode !== 412) {
      // unknown error we haven't handle
      console.error(err);
      return null;
    }

    const latestVersionId = err.data.latestVersionId;

    if (!latestVersionId) {
      // the situation means all the question has been deleted
      toast.warning('This quiz has been archived.');
      return null;
    }

    let params: CallWithToastParams = {
      showLoading: false,
    };

    if (confirmHasOpened) {
      toast.dismiss();
    } else {
      setConfirmHasOpened(true);
      params = {
        ...params,
        confirmText: 'Ok',
        confirmMsg: 'This playlist and its questions have been updated.',
        confirmOptions: {
          type: 'warning',
          desktopSx: { maxWidth: 335 },
          onClose: () => {
            setConfirmHasOpened(false);
          },
          anchorEl: getActiveQuizButtonEl(),
          toastOptions: {
            duration: 300000,
          },
        },
      };
    }

    const [response] = await callWithToast(
      () => apis.quiz.archiveQuizData({ quizId }),
      params
    );

    if (!response) {
      // user click cancel
      return null;
    }

    return latestVersionId;
  };
  return {
    validateCreatorQuestionQuizVersion,
  };
};

const inviteUsersToChallenge = async ({
  challengeId,
  ids,
  emails,
  existChallengerIds,
}: {
  challengeId: string;
  ids: string[];
  emails: string[];
  existChallengerIds?: string[];
}) => {
  const validIds = existChallengerIds
    ? ids.filter((id) => !existChallengerIds.some((existId) => existId === id))
    : ids;

  if (validIds.length || emails.length)
    await call(() =>
      apis.challenge.sentChallengeInvitation({
        challengeId,
        userIds: validIds,
        emails: emails,
      })
    );
};

// return null means something went wrong
// return empty string means nothing happened
export default function useStartNewQuiz() {
  const { t } = useTranslation('quiz');

  const {
    values,
    sectionId,
    markId,
    tagId,
    quizId,
    displayQuestionCount: questionCount,
    isRandomChallengers,
  } = useCreateQuizState();
  const clubSlug = useClubSlug();

  const { data: summaryData } = useQuizResultSummary({
    quizId,
  });

  const { validateCreatorQuestionQuizVersion } =
    useValidatorCreatorQuestionQuizVersion(quizId);

  const startChallenge = async (
    forceMode?: ExamMode,
    ignoreNonFriendConfirmation?: boolean
  ) => {
    const latestMode = forceMode ?? values.mode;
    const target = latestMode === ExamMode.MockExam ? 'exam' : 'practice';

    let challengers = values.challengers;
    let randomChallengerCount = undefined;
    if (isRandomChallengers) {
      challengers = [];
      randomChallengerCount = values.randomChallengerCount;
    }

    const nonFriends = challengers.filter(
      (challenger) => !challenger.metadata?.isFollowing
    );

    if (nonFriends.length && !ignoreNonFriendConfirmation) {
      const checked = await toast.confirm(
        t('Challenging users whom you do not follow'),
        {
          confirmText: t('Send Challenge'),
          desktopSx: { maxWidth: 300 },
          anchorEl: getActiveQuizButtonEl(),
        },
        { id: 'check-send-friend-request', duration: Infinity }
      );

      if (!checked) {
        return '';
      }
    }

    const challengerIds = challengers
      .filter((challenger) => !!challenger.metadata?.userId)
      .map((challenger) => challenger.metadata?.userId as string);

    // start an existing challenge
    if (summaryData?.data.challenge) {
      const [existChallengers] = await call(
        apis.challenge.getRank(summaryData.data.challenge.challengeId)
      );

      const latestVersionQuizId = await validateCreatorQuestionQuizVersion();
      if (latestVersionQuizId !== null) {
        inviteUsersToChallenge({
          challengeId: summaryData.data.challenge.challengeId,
          ids: challengerIds,
          emails: [],
          existChallengerIds: existChallengers?.data.items.map(
            (challenger) => challenger.userId
          ),
        });
        const newQuizId =
          latestVersionQuizId === quizId
            ? summaryData.data.quiz.shortId
            : latestVersionQuizId;
        const newRound =
          latestVersionQuizId === quizId
            ? summaryData.data.quiz.latestRoundNo + 1
            : 1;

        return `/club/${clubSlug}/${target}/${newQuizId}/${newRound}#1`;
      }

      return null;
    }

    const [response] = await call(
      apis.challenge.challengeByTag({
        sectionId: sectionId,
        tagCodeAry: values.tags.map((tag) => tag.code),
        questionCount,
        challengerEmails: [],
        challengerIds: challengerIds,
        mode: latestMode,
        isOpenTimer: values.isOpenTimer,
        maxAttemptNum: values.maxAttempt,
        expireAt: values.deadline
          ? format(values.deadline, 'yyyy-MM-dd')
          : undefined,
        difficultyAry: getDifficulties(values.difficultyAry),
        randomChallengerCount,
      })
    );

    if (response) {
      const { quizShortId: newQuizShortId } = response.data;

      toast.success(t('Challenge sent'), {
        anchorEl: getActiveQuizButtonEl(),
      });

      return `/club/${clubSlug}/${target}/${newQuizShortId}/1#1`;
    }

    return null;
  };

  const createChallenge = async () => {
    const nonFriends = values.challengers.filter(
      (challenger) => !challenger.metadata?.isFollowing
    );

    if (nonFriends.length) {
      const checked = await toast.confirm(
        t('Challenging users whom you do not follow'),
        {
          confirmText: t('Send Challenge'),
          desktopSx: { maxWidth: 300 },
          anchorEl: getActiveQuizButtonEl(),
        },
        { id: 'check-send-friend-request', duration: Infinity }
      );

      if (!checked) {
        return '';
      }
    }

    const challengerIds = values.challengers
      .filter((challenger) => !!challenger.metadata?.userId)
      .map((challenger) => challenger.metadata?.userId as string);

    const [response] = await call(
      apis.challenge.challengeByQuiz({
        quizId: quizId || '',
        challengerIds: challengerIds,
        isOpenTimer: values.isOpenTimer,
        maxAttemptNum: values.maxAttempt,
        expireAt: values.deadline
          ? format(values.deadline, 'yyyy-MM-dd')
          : undefined,
      })
    );

    if (response) {
      const { quizShortId: newQuizShortId } = response.data;
      return `/club/${clubSlug}/challenge/${newQuizShortId}?status=2`;
    }

    return null;
  };

  const startPractice = async (forceMode?: ExamMode) => {
    const latestMode = forceMode ?? values.mode;
    const target = latestMode === ExamMode.MockExam ? 'exam' : 'practice';

    // start an existing playlist
    if (quizId && summaryData && !markId && !tagId) {
      const latestVersionQuizId = await validateCreatorQuestionQuizVersion();

      if (latestVersionQuizId !== null) {
        const newQuizId =
          latestVersionQuizId === quizId
            ? summaryData.data.quiz.shortId
            : latestVersionQuizId;

        const newRound =
          latestVersionQuizId === quizId
            ? summaryData.data.quiz.latestRoundNo + 1
            : 1;
        return `/club/${clubSlug}/${target}/${newQuizId}/${newRound}#1`;
      }
    }

    let api;

    if (markId) {
      api = () =>
        apis.quiz.quizByMark({
          sectionId,
          markId,
          questionCount,
          isOpenTimer: values.isOpenTimer,
        });
    } else if (tagId) {
      api = () =>
        apis.quiz.quizByHashtag({
          sectionId,
          tagId,
          questionCount,
          isOpenTimer: values.isOpenTimer,
        });
    } else {
      api = () =>
        apis.quiz.quizByTag({
          sectionId,
          tagCodeAry: values.tags.map((tag) => tag.code),
          markCodeAry: values.marks.map((mark) => mark.code),
          questionCount,
          isOpenTimer: values.isOpenTimer,
          difficultyAry: getDifficulties(values.difficultyAry),
        });
    }

    const [response] = await call(api);

    if (response) {
      const { quizShortId: newQuizShortId, roundNo: newRoundNo } =
        response.data;

      return `/club/${clubSlug}/${target}/${newQuizShortId}/${newRoundNo}#1`;
    }

    return null;
  };

  const startQuiz = ({ mode, isCreateChallenge }: StartQuizOptions = {}) => {
    if (isCreateChallenge) {
      return createChallenge();
    } else if (
      values.challengers.length > 0 ||
      isRandomChallengers ||
      summaryData?.data.challenge
    ) {
      // in a challenge (already started) the requests are already sent before
      // => don't need to show the confirmation
      const ignoreNonFriendConfirmation = !!summaryData?.data.challenge;
      return startChallenge(mode, ignoreNonFriendConfirmation);
    } else {
      return startPractice(mode);
    }
  };

  return startQuiz;
}
