import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { GlobalPanelKeys, GlobalPanelParams } from '@app/web/src/types/panel';
import {
  OtherPlay as OtherPlayIcon,
  ProfilePlaylist as ProfilePlaylistIcon,
} from '@front/icon';
import {
  BaseLayoutAction,
  BaseLayoutRightPanel,
  BaseLayoutScrollContainer,
  EmphasizeButton,
  NumberCard,
  useBaseRightPanel,
} from '@front/ui';
import { useEmojiDetail } from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';
import { numberFormat } from '@lib/web/utils';

import useCreateQuizAction from '../../PracticePage/CreateQuizForm/hooks/useCreateQuizAction';
import useCreateQuizState from '../../PracticePage/CreateQuizForm/hooks/useCreateQuizState';
import useQuizHashtagAndEmojiActions from '../hooks/useQuizHashtagAndEmojiActions';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  main: {
    px: { xs: 2.5, md: '12px' },
    position: 'relative',
  },
  title: { mb: 2, alignItems: 'flex-start' },
  cardTitle: {
    fontWeight: 500,
  },
  infoCards: { display: 'grid', gap: 2 },
  questionTitle: { mt: 1 },
  layoutAction: {
    px: { xs: 2.5, md: '12px' },
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 1,
    '& .MuiButtonBase-root': {
      minWidth: 'unset',
      whiteSpace: 'nowrap',
      '& .em-button-base': {
        px: 0,
      },
    },
  },
  card: {
    minHeight: 'unset',
  },
};

export default function EmojiDetailPanel() {
  const { t } = useTranslation('analytics');
  const { getRightParams } = useBaseRightPanel<GlobalPanelParams>();
  const { title, markId } = getRightParams(GlobalPanelKeys.GlobalEmojiDetail);
  const { sectionId } = useCurrentIaClub();

  const { data, isLoading } = useEmojiDetail({
    markId: markId as string,
    sectionId,
  });
  const { goPlaylistPage, openStartPanel } =
    useQuizHashtagAndEmojiActions('emoji');

  const {
    maxQuestionCount,
    minQuestionCount,
    isLoading: settingLoading,
  } = useCreateQuizState();
  const { updateQuizValues, updateQuizInfo, setCustomizeDisabled } =
    useCreateQuizAction();

  const practiceSettingsLoaded = !settingLoading && maxQuestionCount > 0;

  useEffect(() => {
    if (!data?.data || !practiceSettingsLoaded) return;

    updateQuizValues({
      questionCount: Math.max(
        Math.min(data.data.questionCount, maxQuestionCount),
        minQuestionCount
      ),
      tags: [],
      marks: [
        {
          id: data.data.markId,
          code: data.data.markCode,
        },
      ],
    });

    updateQuizInfo({
      markId: markId as string,
    });
    setCustomizeDisabled(['customize-hashtags', 'difficulty']);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    markId,
    maxQuestionCount,
    minQuestionCount,
    practiceSettingsLoaded,
  ]);

  let mistakeRatio = 0;
  let overtimeRatio = 0;
  if (data?.data.questionCount) {
    mistakeRatio = numberFormat(
      (data.data.mistakeCount / data.data.questionCount) * 100
    );
    overtimeRatio = numberFormat(
      (data.data.overtimeCount / data.data.questionCount) * 100
    );
  }

  return (
    <BaseLayoutRightPanel title={title}>
      <Box sx={styles.wrapper}>
        <BaseLayoutScrollContainer sx={styles.main}>
          <Box sx={styles.infoCards}>
            <NumberCard
              sx={styles.card}
              title={t('hashtag.RHS.question.title', 'Total Questions Taken')}
              titleIcon="ChatQuestionList"
              loading={isLoading}
            >
              <NumberCard.Value>{data?.data.questionCount}</NumberCard.Value>
              <NumberCard.Description>
                {t('hashtag.RHS.question.description', 'Questions')}
              </NumberCard.Description>
            </NumberCard>
            <NumberCard
              title={t('hashtag.RHS.mistake.title', 'Mistakes')}
              titleIcon="OtherWarning"
              loading={isLoading}
            >
              <NumberCard.Value suffix=" %">{mistakeRatio}</NumberCard.Value>
              <NumberCard.Description>
                {t('hashtag.RHS.mistake.description', 'Since Joining')}
              </NumberCard.Description>
            </NumberCard>
            <NumberCard
              title={t('hashtag.RHS.overtime.title', 'Overtime')}
              titleIcon="TestClock"
              loading={isLoading}
            >
              <NumberCard.Value suffix=" %">{overtimeRatio}</NumberCard.Value>
              <NumberCard.Description>
                {t('hashtag.RHS.overtime.description', 'Since Joining')}
              </NumberCard.Description>
            </NumberCard>
          </Box>
        </BaseLayoutScrollContainer>
        <BaseLayoutAction sx={styles.layoutAction}>
          <EmphasizeButton
            variant="outlined"
            onClick={goPlaylistPage}
            prefixIcon={<ProfilePlaylistIcon />}
          >
            {t('hashtag.RHS.btn.view', 'View Playlist')}
          </EmphasizeButton>
          <EmphasizeButton
            onClick={openStartPanel}
            prefixIcon={<OtherPlayIcon />}
          >
            {t('hashtag.RHS.btn.start', 'Start')}
          </EmphasizeButton>
        </BaseLayoutAction>
      </Box>
    </BaseLayoutRightPanel>
  );
}
